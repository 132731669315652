import { MerchantSettingsResponse } from "@kushki/js/lib/types/merchant_settings_response";
import { CheckOutResume } from "../../types/checkout_resume";
import { PaymentTypeEnum } from "../shared/infrastructure/PaymentTypeEnum";
import { DynamicFormBody } from "../components/Payment/DynamicForm/DynamicForm";
import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { SmartLink } from "../../types/smart_link_V2";
import { IAmount } from "../shared/infrastructure/IAmount";
import { Customization } from "../../types/merchant_fetch";
import { SiftScienceDetails } from "../../types/sift_science_details";
import { MerchantInfo } from "../../types/merchant_info";

export interface ISelectItem {
  value: string;
  text: string;
}

export interface IDeferredOptionsFetch {
  [k: string]: {
    months: ISelectItem[];
    monthsOfGrace: ISelectItem[];
  };
}
export interface IAppState {
  amount?: IAmount;
  checkoutPaymentType?: PaymentTypeEnum;
  draftMode: boolean;
  loading: boolean;
  merchantId?: string;
  paymentMethods: string[];
  merchantInfo?: MerchantInfo;
  smartlink?: SmartLink;
  smartlinkId?: string;
  socialReason?: string;
  structure: string;
  termsAndConditions: string;
  settings?: MerchantSettingsResponse;
  terms: string;
  merchantCustomizationInfo: Customization;
  siftScienceDetails?: SiftScienceDetails;
  dynamicDetails?: DynamicFormBody;
  firebaseInfo?: CheckOutResume;
  visaAnimation?: boolean;
  disablePayButton?: boolean;
  masterCardAnimation?: boolean;
}

export const INITIAL_STATE: IAppState = {
  disablePayButton: false,
  draftMode: false,
  loading: true,
  masterCardAnimation: false,
  merchantCustomizationInfo: {
    colors: {
      primary: "",
      secondary: "",
    },
    contact: {
      email: "",
      phoneNumber: "",
    },
    logos: [],
    termsAndConditions: "",
  },
  paymentMethods: [],
  siftScienceDetails: {
    address: "",
    city: "",
    country: "",
    countryCode: "",
    documentNumber: "",
    documentType: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    region: "",
    secondaryAddress: "",
    zipCode: "",
  },
  smartlinkId: "",
  socialReason: "",
  structure: "",
  terms: "",
  termsAndConditions: `INFORMACIÓN FACILITADA POR EL USUARIO
   
El Sitio Web ofrece al Usuario un foro de obtención de empleo e información profesional. Al facilitar o introducir la información en el Sitio Web (“Información del Usuario”), el Usuario otorga a RH licencia y derecho permanente, no exclusivo, irrevocable, libre de royalties, durante el tiempo máximo permitido por la legislación aplicable, pero no le impone obligación de, utilizar, copiar, modificar, mostrar, distribuir, descargar, almacenar, reproducir, transmitir, publicar, vender, revender, adaptar ni crear productos derivados  en todo o en parte a partir de, la Información del Usuario, en ningún modo o manera. El Usuario reconoce y acepta que RH es solamente un foro pasivo a través del cual los usuarios pueden conseguir empleo o informarse a nivel profesional. RH no comprueba ni controla la Información del Usuario. En consecuencia, RH no asume garantía alguna en cuanto a la fiabilidad, precisión, integridad, validez o veracidad de la Información remitida por los usuarios. 

RH se reserva el derecho de, a su libre JUICIO, eliminar, retirar, negarse a reflejar o bloquear toda Información del Usuario que RH considere como inaceptable. En caso de que RH reciba alguna notificación sobre la inaceptabilidad de determinada información facilitada por los usuarios, RH podrá, con total discrecionalidad, investigar dicha información.

El Usuario asume y acepta que RH podrá conservar copia de la Información del Usuario, y revelar dicha información a terceros si lo considera necesario para:

(i) proteger la integridad del Sitio Web;
(ii) proteger los derechos de RH;
(iii) cumplir una orden judicial;
(iv) cumplir cualquier trámite legal;
(v) hacer valer los derechos y acciones que asisten a RH a tenor de este Contrato; y
(vi) satisfacer cualquier petición relativa a la infracción de derechos de terceros.`,
  visaAnimation: false,
};

export const useReducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading!,
      };
    case ActionTypes.SET_SMARTLINK:
      return {
        ...state,
        smartlink: action.smartlink!,
      };
    case ActionTypes.SET_SOCIAL_REASON:
      return {
        ...state,
        socialReason: action.socialReason!,
      };
    case ActionTypes.SET_DRAFT_MODE:
      return {
        ...state,
        draftMode: !!action.draftMode,
      };
    case ActionTypes.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods!,
      };
    case ActionTypes.SET_AMOUNT:
      return {
        ...state,
        amount: action.amount!,
      };
    case ActionTypes.SET_SMARTLINK_STRUCTURE:
      return {
        ...state,
        structure: action.structure!,
      };
    case ActionTypes.SET_MERCHANT_ID:
      return {
        ...state,
        merchantId: action.merchantId!,
      };
    case ActionTypes.SET_SMARTLINK_ID:
      return {
        ...state,
        smartlinkId: action.smartlinkId!,
      };
    case ActionTypes.SET_CHECKOUT_PAYMENT_TYPE:
      return {
        ...state,
        checkoutPaymentType: action.checkoutPaymentType!,
      };
    case ActionTypes.SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditions: action.termsAndConditions!,
      };
    case ActionTypes.SET_MERCHANT_CUSTOMIZATION_INFO:
      return {
        ...state,
        merchantCustomizationInfo: action.merchantCustomizationInfo!,
      };
    case ActionTypes.SET_SIFTSCIENCE_DETAILS:
      return {
        ...state,
        siftScienceDetails: action.siftScienceDetails,
      };
    case ActionTypes.SET_DYNAMIC_DETAILS:
      return {
        ...state,
        dynamicDetails: action.dynamicDetails,
      };
    case ActionTypes.SET_INFO_FIREBASE:
      return {
        ...state,
        firebaseInfo: action.firebaseInfo,
      };
    case ActionTypes.SET_VISA_ANIMATION:
      return {
        ...state,
        visaAnimation: action.visaAnimation,
      };
    case ActionTypes.SET_MASTERCARD_ANIMATION:
      return {
        ...state,
        masterCardAnimation: action.masterCardAnimation,
      };
    case ActionTypes.SET_DISABLE_PAY_BUTTON:
      return {
        ...state,
        disablePayButton: action.disablePayButton,
      };
    default:
      return state;
  }
};
