import { ErrorRouteEnum } from "./RouteEnum";
import expiredNotAvailable from "../../assets/expiredNotAvailable/expiredNotAvailable.svg";
import notFound from "../../assets/notFound/notFound.svg";
import outStock from "../../assets/outStock/outStock.svg";
import transactionVerificationFailed from "../../assets/transactionVerificationFailed/transactionVerificationFailed.svg";

export const ErrorIconEnum: Record<ErrorRouteEnum, string> = {
  [ErrorRouteEnum.EXPIRED_LINK]: expiredNotAvailable,
  [ErrorRouteEnum.NOT_AVAIL]: expiredNotAvailable,
  [ErrorRouteEnum.NOT_FOUND]: notFound,
  [ErrorRouteEnum.OUTSTOCK]: outStock,
  [ErrorRouteEnum.TRANSACTION_FAILED]: transactionVerificationFailed,
  [ErrorRouteEnum.VERIFICATION_FAILED]: transactionVerificationFailed,
};
