import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";
import { Grid } from "@material-ui/core";

const useStyle = makeStyles(() => ({
  imageStyle: {
    borderRadius: "10px",
    maxHeight: "90px",
    maxWidth: "100%",
    objectFit: "cover",
    paddingBottom: "3px",
    paddingTop: "3px",
    width: "auto",
  },
}));

const ProductMobile: React.FC = () => {
  const styles = useStyle();
  const productImage = useSelector(
    (state: IAppState) => state.smartlink!.generalConfig.productImage
  );

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item>
        <img src={productImage} className={styles.imageStyle} alt="" />
      </Grid>
    </Grid>
  );
};

export default ProductMobile;
