import { SiftScienceDetails } from "../../../../types/sift_science_details";
import {
  DynamicFormBody,
  transformDynamicFormValues,
} from "../../Payment/DynamicForm/DynamicForm";
import { useStepperPaymentContext } from "../../Payment/StepperPayment/context/useStepperPaymentContext";
import { StepperEnum } from "../../../shared/infrastructure/StepperEnum";
import { useDispatch, useSelector } from "react-redux";
import {
  setDynamicDetails,
  setSiftScienceDetails,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";
import { useForm, UseFormMethods } from "react-hook-form";
import { ISiftForm } from "../../Payment/SiftScienceForm/SiftForm";
import { useEffect, useRef, useState } from "react";

export interface IUseFormContainerState {
  handleContinueSubmit: () => void;
  siftScienceEnabled: boolean;
  siftForm: UseFormMethods<ISiftForm>;
  dynamicForm: UseFormMethods<DynamicFormBody>;
  dynamicFormEnabled: boolean;
}

export const useFormContainerState = (): IUseFormContainerState => {
  const { setStep } = useStepperPaymentContext();
  const smartlink = useSelector((state: IAppState) => state.smartlink!);
  const { formConfig = [] } = smartlink;
  const dispatch = useDispatch();
  const siftForm = useForm<ISiftForm>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const dynamicForm = useForm<DynamicFormBody>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const [validDynamicForm, setValidDynamicForm] = useState<boolean>(false);
  const validForm = useRef(true);

  useEffect(() => {}, [
    siftForm.formState.isValid,
    dynamicForm.formState.isValid,
    validDynamicForm,
  ]);

  const onSiftFormSubmit = (formData: SiftScienceDetails) => {
    dispatch(setSiftScienceDetails(formData));
  };

  const onDynamicFormSubmit = (formData: DynamicFormBody) => {
    const [transformedData, isValid] = transformDynamicFormValues(
      formData,
      formConfig
    );

    if (isValid) {
      dispatch(setDynamicDetails(transformedData));
    }
    setValidDynamicForm(isValid);
    validForm.current = isValid;
  };

  const handleContinueSubmit = async () => {
    if (smartlink.siftScienceEnabled) {
      await siftForm.handleSubmit(onSiftFormSubmit)();
    }

    if (formConfig.length > 0) {
      await dynamicForm.handleSubmit(onDynamicFormSubmit)();
    }

    if (
      siftForm.formState.isValid &&
      dynamicForm.formState.isValid &&
      validForm.current
    ) {
      setStep(StepperEnum.PAY);
    }
  };

  return {
    dynamicForm,
    dynamicFormEnabled: get(smartlink, "formConfig", []).length > 0,
    handleContinueSubmit,
    siftForm,
    siftScienceEnabled: get(smartlink, "siftScienceEnabled", false),
  };
};
