import { useMediaQuery } from "@material-ui/core";
import theme from "../theme";

export interface AppState {
  isMobile: boolean;
}

export const useStateApp = (): AppState => {
  return {
    isMobile: useMediaQuery(theme.breakpoints.down("xs")),
  };
};
