import React from "react";

import { Box, Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logoKpay from "../../../assets/logos/logo_kpay.svg";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { Translate as T } from "react-localize-redux";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: "#EEF6FF",
    borderRadius: "4px",
    color: "#023365",
    fontSize: "14px",
    fontWeight: 500,
    margin: "0px auto",
    padding: "14px 20px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "5px 50px 5px 50px",
    },
  },
  productLogoImage: {
    height: 20,
    marginLeft: 3,
    marginTop: "8px",
    objectFit: "contain",
    width: 40,
  },
}));

const ResumeKpay: React.FC = () => {
  const classes = useStyles();
  const smartlinkData = useSelector((state: IAppState) => state.smartlink);

  return (
    <Grid container className={classes.container} justify="center">
      <Grid item xs={12} md={12} lg={12}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexGrow={1}
        >
          <T id="kpayResume.info" />
          <img
            src={logoKpay}
            className={classes.productLogoImage}
            alt={smartlinkData?.generalConfig.productName}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResumeKpay;
