export enum RouteEnum {
  INDEX = "/:merchantName/:smartlinkId",
  INDEX_V1 = "/:smartlinkId",
  ERROR_TEMPLATE = "/error/:errorType",
  CALLBACK = "/:merchantName/:smartlinkId/callback",
  COVER = "/:merchantName/:smartlinkId/cover",
  CHECKOUT = "/:merchantName/:smartlinkId/checkout",
  PREVIEW = "/:merchantName/:smartlinkId/preview",
  NOT_FOUND = "/error/wrong-link",
  OUTSTOCK = "/error/out-stock",
  EXPIRED_LINK = "/error/expired",
  NOT_AVAIL = "/error/not-avail",
  VERIFICATION_FAILED = "/error/verification",
  TRANSACTION_FAILED = "/error/transaction",
  TRANSACTION_RESUME = "/success",
}

export enum ErrorRouteEnum {
  NOT_FOUND = "wrong-link",
  OUTSTOCK = "out-stock",
  EXPIRED_LINK = "expired",
  NOT_AVAIL = "not-avail",
  VERIFICATION_FAILED = "verification",
  TRANSACTION_FAILED = "transaction",
}

export const RouteError: Record<ErrorRouteEnum, string> = {
  [ErrorRouteEnum.NOT_FOUND]: "notFound",
  [ErrorRouteEnum.OUTSTOCK]: "outStock",
  [ErrorRouteEnum.EXPIRED_LINK]: "expiredLink",
  [ErrorRouteEnum.NOT_AVAIL]: "notAvail",
  [ErrorRouteEnum.VERIFICATION_FAILED]: "verificationFailed",
  [ErrorRouteEnum.TRANSACTION_FAILED]: "transactionFailed",
};
