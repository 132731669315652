import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CheckOutResume } from "../../../../types/checkout_resume";
import { CallbackStatusEnum } from "../../../shared/infrastructure/CallbackStatusEnum";
import * as moment from "moment-mini";
import { RouteEnum } from "../../../shared/infrastructure/RouteEnum";
import {
  getInfoFirebase,
  setMerchantCustomizationInfo,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";

const getQueryParams = (search: string): CallbackParams => {
  const urlParams = new URLSearchParams(search);
  const queryParams: CallbackParams = {};

  urlParams.forEach((value, key) => {
    const decodedValue: string = decodeURI(value);
    const isObject: boolean =
      decodedValue.includes("{") && decodedValue.includes("}");

    queryParams[key] = isObject ? JSON.parse(decodedValue) : decodedValue;
  });

  return queryParams;
};

const isSuccessResponse = ({
  transfer_status,
  status,
}: CheckOutResume): boolean => {
  return (
    transfer_status === CallbackStatusEnum.APPROVED_TRANSACTION ||
    status === CallbackStatusEnum.APPROVED_TRANSACTION
  );
};

interface CallbackParams {
  bank?: string;
  bankName?: string;
  brandLogo?: string;
  currency?: string;
  documentNumber?: string;
  documentType?: string;
  email?: string;
  kpayDataSaved?: string;
  merchantName?: string;
  paymentMethod?: string;
  productName?: string;
  token?: string;
  totalValue?: string;
  subscriptionId?: string;
  [key: string]: any;
}

const useResumeCallback = () => {
  const [params, setParams] = useState<CallbackParams>({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const firebaseInfo = useSelector((state: IAppState) => state.firebaseInfo);

  const redirectToResume = (_params: CallbackParams): void => {
    dispatch(setMerchantCustomizationInfo(_params.customizationInfo));
    const state = {
      paymentMethod: _params.paymentMethod,
      resumeData: {
        currency: _params.currency,
        cus: firebaseInfo?.cus,
        date: firebaseInfo?.timestamp
          ? moment
              .unix(firebaseInfo!.timestamp / 1000)
              .format("YYYY-MM-DD hh:mm a")
          : "",
        email: _params.email,
        kpay: _params.kpayDataSaved === "true",
        name: _params.name,
        nit: _params.documentNumber,
        product: _params.productName,
        status: firebaseInfo?.transfer_status || firebaseInfo?.status,
        ticketNumber: firebaseInfo?.ticketNumber,
        totalAmount: _params.totalValue,
        ..._params,
      },
    };

    history.push({
      pathname: RouteEnum.TRANSACTION_RESUME,
      state,
    });
  };

  useEffect(() => {
    setParams(getQueryParams(location.search));
  }, []);

  useEffect(() => {
    if (params.subscriptionId) {
      const resumeData: string = sessionStorage.getItem("resumeData")!;
      const parsedData: object = JSON.parse(resumeData) as object;

      sessionStorage.removeItem("resumeData");
      redirectToResume({
        ...parsedData,
        subscriptionId: params.subscriptionId,
      });
    }
    if (params.code === "E3") history.push(RouteEnum.TRANSACTION_FAILED);
    if (!params.token) return;

    const { token, bankName, paymentMethod = "" } = params;
    const isTransfer: boolean =
      (!!bankName || paymentMethod.includes("transfer")) && !!token;

    dispatch(getInfoFirebase(token, isTransfer ? "transfer" : "cardAsync"));
  }, [params]);

  useEffect(() => {
    if (!!firebaseInfo) {
      if (isSuccessResponse(firebaseInfo)) {
        redirectToResume(params);
      } else history.push(RouteEnum.TRANSACTION_FAILED);
    }
  }, [firebaseInfo]);
};

export { useResumeCallback, getQueryParams };
