import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import {
  LocalizeContextProps,
  Translate as T,
  withLocalize,
} from "react-localize-redux";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import esFlag from "../../../../assets/logos/emojione_flag-for-spain.svg";
import enFlag from "../../../../assets/logos/emojione_flag-for-united-states.svg";
import brFlag from "../../../../assets/logos/emojione_flag-for-brazil.svg";

const Input = withStyles((theme: Theme) =>
  createStyles({
    input: {
      "& .MuiSelect-outlined": {
        paddingRight: "0px",
      },
      backgroundColor: theme.palette.background.paper,
      border: "1px solid white",
      borderRadius: 4,
      fontSize: 14,
      padding: "8px 0px 8px 10px",
      position: "relative",
    },
  })
)(InputBase);

const useStyles = makeStyles((theme) => ({
  boxIcon: {
    alignItems: "center",
    borderLeft: "1px solid Navy",
    display: "flex",
    height: "24px",
    pointerEvents: "none",
    position: "absolute",
    right: "0",
    width: "24px",
  },
  formControl: {
    "& .MuiInputBase-root": {
      height: 36,
    },
    borderRadius: 6,
    fontSize: "14px",
    height: 36,
    outlineColor: "#FFFFFF",
    paddingRight: "0px",
  },
  img: {
    marginRight: theme.spacing(2),
    verticalAlign: "middle",
  },
  label: {
    color: "#023365",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "140%",
    paddingRight: 0,
  },
  language: {
    paddingRight: "5px",
  },
  select: {
    "& .MuiSelect-outlined": {
      paddingRight: "26px",
    },
    borderRadius: 5,
    color: "#023365",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "140%",
    paddingRight: 0,
  },
}));

export type TSelectLanguageProps = LocalizeContextProps;
export const SelectLanguageMobile = (props: TSelectLanguageProps) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Box>
        <Select
          id="languages"
          value={props.activeLanguage?.code || "es"}
          className={classes.select}
          input={<Input />}
          style={{ backgroundColor: "white" }}
          IconComponent={() => (
            <Box className={classes.boxIcon}>
              <ArrowDropUpIcon />
            </Box>
          )}
        >
          <MenuItem
            id="es"
            value="es"
            onClick={() => props.setActiveLanguage("es")}
          >
            <Box display={"flex"}>
              <Typography className={classes.label}>
                <T id="languagesShort.es" />
              </Typography>
              <Box display={"flex"} ml={1}>
                <img src={esFlag} className={classes.language} alt={esFlag} />
              </Box>
            </Box>
          </MenuItem>
          <MenuItem
            id="en"
            value="en"
            onClick={() => props.setActiveLanguage("en")}
          >
            <Box display={"flex"}>
              <Typography className={classes.label}>
                <T id="languagesShort.en" />
              </Typography>
              <Box display={"flex"} ml={1}>
                <img src={enFlag} className={classes.language} alt={enFlag} />
              </Box>
            </Box>
          </MenuItem>
          <MenuItem
            id="br"
            value="br"
            onClick={() => props.setActiveLanguage("br")}
          >
            <Box display={"flex"}>
              <Typography className={classes.label}>
                <T id="languagesShort.br" />
              </Typography>
              <Box display={"flex"} ml={1}>
                <img src={brFlag} className={classes.language} alt={brFlag} />
              </Box>
            </Box>
          </MenuItem>
        </Select>
      </Box>
    </FormControl>
  );
};
export default withLocalize<LocalizeContextProps>(SelectLanguageMobile);
