import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useStateApp } from "../../../state/useStateApp";
import { HelpBox } from "../Footer/HelpBox/HelpBox";
import SelectLanguageMobile from "../Language/SelectLanguageMobile/SelectLanguageMobile";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      marginBottom: 8,
      paddingLeft: 16,
    },
    formFooter: {
      bottom: 0,
      left: 0,
      position: "fixed",
      right: 0,
      width: "100%",
      zIndex: 100,
    },
    messageFooter: {
      bottom: 0,
      left: 0,
      position: "fixed",
      right: 0,
      width: "100%",
    },
  })
);

export const Footer: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useStateApp();
  const location = useLocation();

  return (
    <footer
      className={
        location.pathname.includes("checkout") && isMobile
          ? classes.formFooter
          : classes.messageFooter
      }
    >
      <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
        <Box width={"100%"}>
          {isMobile && (
            <Box
              height={"100%"}
              display={"flex"}
              alignItems={"flex-end"}
              width={"30%"}
              position={"sticky"}
            >
              <Box className={classes.box}>
                <SelectLanguageMobile />
              </Box>
            </Box>
          )}
        </Box>
        <Box maxWidth={"70%"}>
          <HelpBox />
        </Box>
      </Box>
    </footer>
  );
};
export default Footer;
