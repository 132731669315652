import DOMPurify from "dompurify";
import React from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import theme from "../../../theme";
import TimerCover from "../TimerCover/TimerCover";
import { useHistory, useParams } from "react-router-dom";
import { IIndexPathParameter } from "../../../shared/infrastructure/IIndexPathParameter";
import { StructureEnum } from "../../../shared/infrastructure/StructureEnum";
import TopHeader from "../../commons/TopHeader/TopHeader";

const useStyles = makeStyles(() => ({
  buttonPadding: {
    paddingTop: "25px !important",
  },
  buyButtonStyle: (props: { primaryColor: string | undefined }) => ({
    backgroundColor: props.primaryColor
      ? props.primaryColor
      : theme.palette.info.dark,
    borderRadius: "4px",
    color: theme.palette.background.paper,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    lineHeight: "140%",
  }),
  cardPadding: {
    paddingTop: "12px",
  },
  descriptionTextStyle: {
    color: theme.palette.grey.A400,
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "140%",
  },
  paymentResumeCard: {
    borderRadius: "8px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    margin: "auto",
    marginLeft: "16px",
    marginRight: "16px",
    padding: "24px",
  },
  productImage: {
    borderRadius: "10px",
    height: "100%",
    maxHeight: "260px",
    maxWidth: "100%",
    objectFit: "cover",
    width: "100%",
  },
  productNameTextStyle: {
    color: theme.palette.grey.A400,
    textTransform: "uppercase",
  },

  promotionalTextPadding: {
    paddingTop: "25px",
  },
  promotionalTextStyle: ({
    primaryColor,
  }: {
    primaryColor: string | undefined;
  }) => ({
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
  }),
  titlesPadding: {
    paddingTop: "4px !important",
  },
  topHeader: {
    position: "absolute",
    top: 0,
    width: "80%",
  },
}));

const CenterCover: React.FC = () => {
  const smartlinkData = useSelector((state: IAppState) => state.smartlink!);
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo.colors! || {}
  );
  const { primary: primaryColor } = colors;
  const params = useParams<IIndexPathParameter>();
  const { merchantName, smartlinkId } = params;
  const history = useHistory();
  const routeChange = () => {
    history.push(
      `/${merchantName || "smartlink"}/${smartlinkId}/${StructureEnum.CHECKOUT}`
    );
  };

  const sanitizedHtml = DOMPurify.sanitize(
    smartlinkData.generalConfig.description
  );

  const classes = useStyles({ primaryColor });

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.topHeader}
      >
        <TopHeader />
      </Box>
      <Container maxWidth="sm">
        <Card
          className={classes.paymentResumeCard}
          id="centerCoverCardContainer"
        >
          <Grid container>
            <Grid item xs={12} className={classes.cardPadding}>
              <img
                src={smartlinkData.generalConfig.productImage}
                className={classes.productImage}
                alt={""}
              />
            </Grid>
            {smartlinkData.generalConfig.expirationDate &&
              smartlinkData.generalConfig.showTimer && (
                <Grid item xs={12}>
                  <Box mt="25px">
                    <TimerCover />
                  </Box>
                </Grid>
              )}
            <Grid item xs={12} className={classes.promotionalTextPadding}>
              <Typography className={classes.promotionalTextStyle}>
                {smartlinkData.generalConfig.promotionalText}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.titlesPadding}>
              <Typography
                variant={"h5"}
                className={classes.productNameTextStyle}
              >
                {smartlinkData.generalConfig.productName}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.cardPadding}>
              <Typography className={classes.descriptionTextStyle}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHtml,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.buttonPadding}>
              <Button
                variant="contained"
                fullWidth
                className={classes.buyButtonStyle}
                onClick={routeChange}
              >
                {smartlinkData.generalConfig.buyButtonText}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
};

export default CenterCover;
