import { makeStyles } from "@material-ui/core";

const useStylesResumeCard = makeStyles((theme) => ({
  checkSubText: {
    fontSize: "16px",
  },
  checkText: {
    fontSize: "32px",
  },
  contentCard: {
    marginBottom: "9px",
    marginTop: "5px",
    paddingTop: "10px",
  },
  descriptionText: {
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "140%",
  },
  detailCard: {
    borderRadius: "10px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    height: "645px",
    margin: "auto",
    marginLeft: "16px",
    marginRight: "16px",
    padding: "0px 16px",
    // width: "640px",
  },
  kpay: {
    margin: "0px auto",
    marginTop: "25px",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
  },
  productImage: {
    borderRadius: "10px",
    height: "80px",
    maxHeight: "80px",
    maxWidth: "80px",
    objectFit: "cover",
    width: "100%",
  },
  productNameText: {
    textTransform: "uppercase",
  },
  promotionalText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 100,
    lineHeight: "140%",
  },
  scrollDownSm: {
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  summarizeDescription: {
    color: "#5c5c5c",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "initial",
    lineHeight: "136%",
    margin: "8px 0px",
    textAlign: "left",
  },
  summarizeInfo: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
    margin: "8px 0px",
    textAlign: "left",
    width: "50%",
  },
  summarizePersonalInfo: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
    margin: "4px 0px",
    textAlign: "left",
    width: "100%",
  },
  summarizeTitle: {
    fontSize: "20px",
  },
}));

export default useStylesResumeCard;
