import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormLabel, makeStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomForm } from "../../../../../types/smart_link_V2";

const useStyles = makeStyles({
  label: {
    color: "#000000",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "120%",
  },
});

const DatePicker: React.FC<CustomForm> = (props: CustomForm) => {
  const { name, label, required, disabled } = props;
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      defaultValue={new Date()}
      render={(props) => (
        <FormControl disabled={disabled} fullWidth>
          <FormLabel className={classes.label}>{label}</FormLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              {...props}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              fullWidth
              disabled={disabled}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      )}
    />
  );
};

export { DatePicker };
