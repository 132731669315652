import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import kushkilogo from "../../assets/logos/kushki_logo.svg";
import { useLoadingDots } from "./state/useLoadingDots";
import { useResumeCallback } from "./state/useResumeCallback";
import { Translate as T } from "react-localize-redux";

const useStyles = makeStyles({
  container: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
  },
  loadingLabel: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "150%",
  },
});

const ResumeCallback: React.FC = () => {
  const classes = useStyles();
  const { dotLabel } = useLoadingDots(3);

  useResumeCallback();

  return (
    <Box className={classes.container}>
      <Box>
        <Box p={0.35}>
          <img src={kushkilogo} alt={kushkilogo} width={80} height={80} />
        </Box>
        <Typography className={classes.loadingLabel}>
          <T id={"loadingLabel"} /> {dotLabel}
        </Typography>
      </Box>
    </Box>
  );
};

export default ResumeCallback;
