import { Box, Collapse, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Translate as T } from "react-localize-redux";
import help from "../../../../assets/logos/help.svg";
import mail from "../../../../assets/logos/mail.svg";
import phone from "../../../../assets/logos/phone.svg";
import { useFooterState } from "../Toggle/state/useFooter";

const useStyles = makeStyles({
  contactLabel: {
    fontWeight: 600,
  },
  container: {
    background: "#CBD5E0",
    borderRadius: "16px 0px 0px 0px",
    maxWidth: 252,
    padding: "6px 8px 6px 12px",
    width: "252px",
  },
  helpLabel: {
    color: "#023365",
    fontFamily: "Roboto",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "150%",
    paddingLeft: 2,
  },
});

const HelpBox: React.FC = () => {
  const classes = useStyles();

  const { displayHelpModal, handler, smartlinkCustomizationInfo } =
    useFooterState();

  return !!smartlinkCustomizationInfo.contact?.email ? (
    <Box className={classes.container} onClick={handler.handleOpenHelpModal}>
      <Box pl={1.3} pr={1.3} pt={1.3} pb={0.5}>
        <Box display={"flex"} alignItems={"center"}>
          <img src={help} alt={help} height={20} width={20} />
          <Typography className={classes.helpLabel}>
            <T id="footer.help" />{" "}
            <span className={classes.contactLabel}>
              <T id="footer.contacts" />
            </span>
          </Typography>
        </Box>
        <Collapse in={displayHelpModal}>
          <Box pl={3} pt={1}>
            <Box display={"flex"} alignItems={"center"} pb={0.5}>
              <img src={phone} alt={phone} height={20} width={20} />
              <Typography className={classes.helpLabel}>
                {smartlinkCustomizationInfo.contact?.phoneNumber}
              </Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <img src={mail} alt={mail} height={20} width={20} />
              <Typography className={classes.helpLabel}>
                {smartlinkCustomizationInfo.contact?.email}
              </Typography>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  ) : null;
};

export { HelpBox };
