import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";

const useStyles = makeStyles(() => ({
  productImage: {
    height: "100vh",
    objectFit: "cover",
    width: "100%",
  },
}));

export const ProductCover: React.FC = () => {
  const classes = useStyles();
  const productImage = useSelector(
    (state: IAppState) => state.smartlink!.generalConfig!.productImage
  );

  return (
    <Grid style={{ height: "100vh", overflow: "hidden" }}>
      <Grid item>
        <img src={`${productImage}`} className={classes.productImage} />
      </Grid>
    </Grid>
  );
};
