import { orderBy } from "lodash";

export interface ICountry {
  value: string;
  name: string;
}

export const COUNTRIES: ICountry[] = orderBy(
  [
    {
      name: "Ecuador",
      value: "Ecuador",
    },
    {
      name: "Colombia",
      value: "Colombia",
    },
    {
      name: "Perú",
      value: "Peru",
    },
    {
      name: "Chile",
      value: "Chile",
    },
    {
      name: "México",
      value: "Mexico",
    },
    {
      name: "Brasil",
      value: "Brasil",
    },
    {
      name: "El Salvador",
      value: "ElSalvador",
    },
    {
      name: "Panamá",
      value: "Panama",
    },
    {
      name: "Costa Rica",
      value: "CostaRica",
    },
    {
      name: "Nicaragua",
      value: "Nicaragua",
    },
    {
      name: "Honduras",
      value: "Honduras",
    },
    {
      name: "Guatemala",
      value: "Guatemala",
    },
  ],
  ["name"],
  ["asc"]
);
