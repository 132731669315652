import React from "react";
import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#6D7781",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "120%",
    paddingBottom: "10px",
    paddingTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "150%",
      padding: "14px",
    },
  },
  saveText: {
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: 500,
    marginLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      fontWeight: 400,
      marginLeft: "-5px",
      marginTop: "-24px",
    },
  },
}));

const PreviewBanner: React.FC = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexGrow={1}
    >
      <T id="previewBanner" />
      <Typography className={classes.saveText}>
        <T id="saveText" />
      </Typography>
    </Box>
  );
};

export default PreviewBanner;
