import { makeStyles } from "@material-ui/core";

const useStepperPaymentContextStyle = makeStyles(() => ({
  boxMasterCardAnimation: () => ({
    backdropFilter: "blur(5px)",
    backgroundColor: "white",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    zIndex: 15,
  }),
  boxMasterCardAnimationMobile: () => ({
    backgroundColor: "white",
    boxSizing: "border-box",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 200,
  }),
  boxVisaAnimation: () => ({
    alignItems: "center",
    backdropFilter: "blur(5px)",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    zIndex: 15,
  }),
  containerVisaAnimation: {
    height: "100%",
    position: "relative",
    width: "100%",
  },
  visaAnimationMobile: {
    height: "100%",
    width: "100%",
  },
}));

export default useStepperPaymentContextStyle;
