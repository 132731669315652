import React from "react";
import { Grid } from "@material-ui/core";
import { CardCover } from "./components/CardCover/CardCover";
import { ProductCover } from "./components/ProductCover/ProductCover";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { CoverEnum } from "../../../shared/infrastructure/CoverEnum";

const LeftRightCover: React.FC = () => {
  const cover = useSelector(
    (state: IAppState) => state.smartlink!.styleAndStructure.coverModel
  );

  return (
    <>
      {cover === CoverEnum.LEFT ? (
        <Grid container direction="row" id="containerLeft">
          <Grid item xs={12} sm={5}>
            <ProductCover />
          </Grid>
          <Grid item xs={12} sm={7}>
            <CardCover />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" id="containerRight">
          <Grid item xs={12} sm={7}>
            <CardCover />
          </Grid>
          <Grid item xs={12} sm={5}>
            <ProductCover />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default LeftRightCover;
