import { PAYMENT_IMGS, PaymentMethodsImgEnum } from "./PaymentMethodImgEnum";
import { FC, SVGProps } from "react";

export enum PaymentMethodsEnum {
  CARD = "credit-card",
  SUBSCRIPTION = "subscription",
  TRANSFER = "transfer",
  TRANSFER_PSE = "transfer-pse",
  TRANSFER_SAFETYPAY = "transfer-safetypay",
  TRANSFER_ETPAY = "transfer-etpay",
  TRANSFER_SPEI = "transfer-spei",
  TRANSFER_SUBSCRIPTION = "transfer-subscription",
  CASH = "cash",
  CARD_ASYNC = "card-async",
  CARD_DYNAMIC = "card-dynamic",
  CARD_SUBSCRIPTION_ASYNC = "card-async-subscription",
  CARD_SUBSCRIPTION_DYNAMIC = "card-dynamic-subscription",
  CARD_BANK_ACCENDO = "card-bank-accendo",
  CARD_BANK_BOGOTA = "card-bank-bogota",
}

export const ACTIVATION_METHODS: Record<PaymentMethodsEnum, string> = {
  [PaymentMethodsEnum.CARD]: "singlePayment",
  [PaymentMethodsEnum.SUBSCRIPTION]: "recurringPayment",
  [PaymentMethodsEnum.TRANSFER]: "transferPayment",
  [PaymentMethodsEnum.TRANSFER_PSE]: "transferPayment",
  [PaymentMethodsEnum.TRANSFER_SAFETYPAY]: "transferPayment",
  [PaymentMethodsEnum.TRANSFER_ETPAY]: "transferPayment",
  [PaymentMethodsEnum.TRANSFER_SPEI]: "transferPayment",
  [PaymentMethodsEnum.CARD_ASYNC]: "cardAsyncPayment",
  [PaymentMethodsEnum.CARD_DYNAMIC]: "cardDynamicPayment",
  [PaymentMethodsEnum.CASH]: "cashPayment",
  [PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC]: "cardSubscriptionAsyncPayment",
  [PaymentMethodsEnum.CARD_SUBSCRIPTION_DYNAMIC]:
    "cardSubscriptionDynamicPayment",
  [PaymentMethodsEnum.TRANSFER_SUBSCRIPTION]: "transferSubscriptionPayment",
  [PaymentMethodsEnum.CARD_BANK_ACCENDO]: "transferSubscriptionPayment",
  [PaymentMethodsEnum.CARD_BANK_BOGOTA]: "transferSubscriptionPayment",
};

export const PAYMENT_METHOD_CONFIG: Record<
  string,
  {
    title: string;
    logo: FC<SVGProps<SVGSVGElement>>;
    description: string;
  }
> = {
  [PaymentMethodsEnum.CARD]: {
    description: "itemDescriptions.makePaymentsCard",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.CARD],
    title: "itemTitles.creditCard",
  },
  [PaymentMethodsEnum.CASH]: {
    description: "itemDescriptions.makePaymentsCash",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.CASH],
    title: "itemTitles.cash",
  },
  [PaymentMethodsEnum.TRANSFER]: {
    description: "itemDescriptions.makePaymentsTransfer",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.TRANSFER],
    title: "itemTitles.transfer",
  },
  [PaymentMethodsEnum.TRANSFER_SUBSCRIPTION]: {
    description: "itemDescriptions.makePaymentsTransfer",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.TRANSFER],
    title: "itemTitles.transfer",
  },
  [PaymentMethodsEnum.SUBSCRIPTION]: {
    description: "itemDescriptions.makePaymentsCardSubscription",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.CARD],
    title: "itemTitles.cardSubscription",
  },
  [PaymentMethodsEnum.CARD_ASYNC]: {
    description: "itemDescriptions.makePaymentsCardAsync",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.CARD],
    title: "itemTitles.cardAsync",
  },
  [PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC]: {
    description: "itemDescriptions.makePaymentsCardSubscriptionAsync",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.CARD],
    title: "itemTitles.cardSubscriptionAsync",
  },
  [PaymentMethodsEnum.CARD_DYNAMIC]: {
    description: "itemDescriptions.makePaymentsCardDynamic",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.CARD],
    title: "itemTitles.cardDynamic",
  },
  [PaymentMethodsEnum.CARD_SUBSCRIPTION_DYNAMIC]: {
    description: "itemDescriptions.makePaymentsCardSubscriptionDynamic",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.CARD],
    title: "itemTitles.cardSubscriptionDynamic",
  },
  [PaymentMethodsEnum.CARD_BANK_ACCENDO]: {
    description: "itemDescriptions.makePaymentsCardSubscriptionDynamic",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.CARD],
    title: "itemTitles.cardSubscriptionDynamic",
  },
  [PaymentMethodsEnum.CARD_BANK_ACCENDO]: {
    description: "itemDescriptions.makePaymentsCardSubscriptionDynamic",
    logo: PAYMENT_IMGS[PaymentMethodsImgEnum.CARD],
    title: "itemTitles.cardSubscriptionDynamic",
  },
};
