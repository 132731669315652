import { useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";
import { Customization } from "../../../../../../types/merchant_fetch";

export interface IFooterState {
  displayHelpModal: boolean;
  handler: {
    handleOpenHelpModal: () => void;
  };
  smartlinkCustomizationInfo: Customization;
}

export const useFooterState = (): IFooterState => {
  const [displayHelpModal, setDisplayModal] = useState<boolean>(false);
  const smartlinkCustomizationInfo = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo
  );

  const handleOpenHelpModal = () => {
    setDisplayModal(!displayHelpModal);
  };

  return {
    displayHelpModal,
    handler: {
      handleOpenHelpModal,
    },
    smartlinkCustomizationInfo,
  };
};
