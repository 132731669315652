import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";
import { Grid, Theme, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme: Theme) => ({
  positionContainerStyle: {
    paddingLeft: theme.spacing(2),
  },
  subtitleStyle: ({ primaryColor }: { primaryColor: string | undefined }) => ({
    ...theme.typography.subtitle1,
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "140%",
  }),
  titleStyle: {
    ...theme.typography.subtitle1,
    color: "#293036",
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "140%",
    textTransform: "uppercase",
  },
}));

const DetailProductMobile: React.FC = () => {
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo!.colors || {}
  );
  const { primary: primaryColor } = colors;
  const styles = useStyle({ primaryColor });
  const { productName, promotionalText } = useSelector(
    (state: IAppState) => state.smartlink!.generalConfig
  );

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid xs={12} item>
        <Typography className={styles.subtitleStyle}>
          {promotionalText}
        </Typography>
      </Grid>
      <Grid xs={6} item>
        <Typography variant={"h5"} className={styles.titleStyle}>
          {productName}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DetailProductMobile;
