import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import React from "react";
import { CustomForm } from "../../../../../types/smart_link_V2";
import { Controller, useFormContext } from "react-hook-form";
import { getLabel } from "../DynamicForm";
import { Translate as T } from "react-localize-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      backgroundColor: "white",
      paddingRight: 2,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const SELECT_EMPTY_VALUE: string = "emptyValue";

const DynamicSelect: React.FC<CustomForm> = (props: CustomForm) => {
  const { name, required, disabled, options, label } = props;
  const classes = useStyles();
  const { control, errors } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={required ? options![0] : SELECT_EMPTY_VALUE}
      rules={{ required: required }}
      render={(renderProps) => (
        <FormControl variant="outlined" fullWidth error={errors[name]}>
          <InputLabel className={classes.inputLabel}>
            {getLabel(label, required)}
          </InputLabel>
          <Select
            {...renderProps}
            disabled={disabled}
            variant={"outlined"}
            defaultValue={required ? options![0] : SELECT_EMPTY_VALUE}
          >
            {!required && (
              <MenuItem value={SELECT_EMPTY_VALUE}>
                <T id={"form.selectEmptyOption"} />
              </MenuItem>
            )}
            {options!.map((option: string, index: number) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {!!errors[name] && (
            <FormHelperText>
              <T id={"form.fieldRequired"} />
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export { DynamicSelect, SELECT_EMPTY_VALUE };
