import { ReactComponent as CardImg } from "../../assets/logos/card.svg";
import { ReactComponent as KPayImg } from "../../assets/images/paymentMethods/kpay.svg";
import { ReactComponent as CashImg } from "../../assets/images/paymentMethods/cash.svg";
import { ReactComponent as TransferImg } from "../../assets/images/paymentMethods/transfer.svg";
import { FC } from "react";

export enum PaymentMethodsImgEnum {
  CARD = "card",
  KPAY = "kpay",
  CASH = "cash",
  TRANSFER = "transfer",
}

export const PAYMENT_IMGS: Record<PaymentMethodsImgEnum, FC> = {
  [PaymentMethodsImgEnum.CARD]: CardImg,
  [PaymentMethodsImgEnum.KPAY]: KPayImg,
  [PaymentMethodsImgEnum.CASH]: CashImg,
  [PaymentMethodsImgEnum.TRANSFER]: TransferImg,
};
