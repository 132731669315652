import React from "react";
import {
  StatusEnum,
  StatusLabels,
} from "../../../shared/infrastructure/StatusLabels";
import { Translate as T } from "react-localize-redux";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { ILocationState } from "../../../containers/Payment/Payment";
import { useLocation } from "react-router-dom";
import ResumeKpay from "../../commons/ResumeKpay/ResumeKpay";
import logoKushki from "../../../assets/logos/kushki_logo.svg";
import { ResumeProps } from "../../../containers/Resume/Resume";
import useStylesResumeCard from "./ResumeCard.styles";

const ResumeCard: (props: ResumeProps) => JSX.Element = (
  props: ResumeProps
) => {
  const classes = useStylesResumeCard();
  const location = useLocation<ILocationState>();
  const smartlinkData = location.state.smartlink;

  const transactionStatus: string =
    StatusLabels[
      location.state.resumeData.details.transactionStatus as StatusEnum
    ];

  const isKpay = location.state.resumeData.kpay;

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={7} lg={7} justify="center">
        <Card className={classes.detailCard}>
          <CardContent className={classes.contentCard}>
            <Grid container xs={12} md={12} lg={12}>
              <Grid item justify="center" className={classes.logo}>
                <img
                  src={logoKushki}
                  className={classes.productImage}
                  alt={smartlinkData?.generalConfig.productName}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Box mt={2}>
                  <Typography
                    className={classes.checkText}
                    color={"initial"}
                    align="center"
                  >
                    <T id="summarizeLabels.successTitle" />
                  </Typography>
                  <Typography
                    className={classes.promotionalText}
                    color={"initial"}
                    align="center"
                  >
                    <T id="summarizeLabels.successSubTitle" />
                  </Typography>
                </Box>
              </Grid>
              {isKpay && (
                <Grid item xs={12} md={9} lg={9} className={classes.kpay}>
                  <ResumeKpay />
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={12}>
                <Box alignContent="center" mb="26px" mt="26px">
                  <Typography className={classes.summarizeTitle} align="center">
                    <T id="summarizeLabels.summarizeTitle" />
                  </Typography>
                </Box>
                <Box>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabels.summarizeProductLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          {smartlinkData.generalConfig.productName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabels.summarizeTicketNumberLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          {location.state.resumeData.ticketNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabels.summarizeAmountLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          {location.state.resumeData.totalAmount}{" "}
                          {location.state.resumeData.currency}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabels.summarizeStateLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          <T id={transactionStatus} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    {props.dynamicFormValues.length > 0 && (
                      <Grid container xs={12} md={5} lg={5}>
                        <Grid item xs={4} md={12} lg={12}>
                          <Typography className={classes.summarizeDescription}>
                            <T id="summarizeLabels.summarizePersonalInfoLabel" />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          md={12}
                          lg={12}
                          className={classes.scrollDownSm}
                        >
                          {props.dynamicFormValues.map((info: string) => (
                            <Typography
                              className={classes.summarizeInfo}
                              key={info}
                            >
                              {info}
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResumeCard;
