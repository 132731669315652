import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  ErrorRouteEnum,
  RouteError,
} from "../../../shared/infrastructure/RouteEnum";
import notFoundLogo from "../../../assets/notFound/notFound.svg";
import { ErrorIconEnum } from "../../../shared/infrastructure/ErrorIconEnum";
import { getSessionStorageInfo } from "../../../shared/utils/utils";

export interface IUserErrorState {
  labels: {
    title: string;
    description: string;
    icon: string;
  };
  handler: () => void;
  descriptionSecondary: string;
  errorKey: string;
}

export const useErrorState = (): IUserErrorState => {
  const params = useParams<{ errorType: ErrorRouteEnum }>();
  const history = useHistory();
  const [labels, setLabels] = useState({
    description: "notFound.description",
    icon: notFoundLogo,
    title: "notFound.title",
  });
  const [errorKey, setErrorKey] = useState(RouteError["wrong-link"]);
  const [descriptionSecondary, setDescriptionSecondary] = useState("");

  const handleTryAgain = (): void => {
    const { merchantName = "smartlink", smartlinkId } = getSessionStorageInfo();

    history.push(`/${merchantName}/${smartlinkId}`);
  };

  useEffect(() => {
    const errorKey: string = RouteError[params.errorType];
    const title: string = `${errorKey}.title`;
    const description: string = `${errorKey}.description`;
    const descriptionSecondary: string = `${errorKey}.descriptionSecondary`;
    const icon: string = ErrorIconEnum[params.errorType];

    setLabels({
      description,
      icon,
      title,
    });
    setErrorKey(errorKey);
    setDescriptionSecondary(descriptionSecondary);
  }, []);

  return {
    descriptionSecondary,
    errorKey,
    handler: handleTryAgain,
    labels,
  };
};
