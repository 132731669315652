import { useEffect, useState } from "react";

interface IUseResumeCallback {
  dotLabel: string;
}

const useLoadingDots = (limit: number): IUseResumeCallback => {
  const [count, setCount] = useState<number>(1);

  const getText = (dots: number) => {
    return ".".repeat(dots) + "".repeat(limit - dots);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => (count === limit ? 0 : count + 1));
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return {
    dotLabel: getText(count),
  };
};

export { useLoadingDots };
