import React, { FC, PropsWithChildren } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import { Translate as T } from "react-localize-redux";
import { useStateApp } from "../../../state/useStateApp";
import { useStepperPaymentContext } from "./context/useStepperPaymentContext";
import { StepperEnum } from "../../../shared/infrastructure/StepperEnum";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import clsx from "clsx";
import { Check } from "@material-ui/icons";
import theme from "../../../theme";

const useStyles = makeStyles(() => ({
  connectorStepperDesktop: {
    marginLeft: "45%",
    width: "80%",
  },
  connectorStepperMobile: {
    marginLeft: "20%",
  },
  leftArrow: {
    marginLeft: 0,
    paddingLeft: 0,
    paddingRight: 32,
    zIndex: 1,
  },
  stepIconDesktop: {
    alignItems: "flex-end",
    paddingRight: 10,
  },
  stepIconMobile: {
    alignItems: "center",
    paddingRight: 0,
  },
  stepLabelDesktop: {
    marginRight: -5,
    textAlign: "end",
  },
  stepLabelMobile: {
    marginRight: 0,
    textAlign: "center",
  },
  stepperDesktop: {
    alignItems: "flex-end",
    backgroundColor: "#F7FAFC",
    paddingRight: 1.2,
  },
  stepperMobile: {
    backgroundColor: "#F7FAFC",
    padding: "10px 0px 10px 0px",
    zIndex: 0,
  },
}));

const useCustomStepIconStyle = makeStyles({
  circle: {
    alignItems: "center",
    borderRadius: "50%",
    display: "flex",
    fontSize: 12,
    height: "24px",
    justifyContent: "center",
    width: "24px",
  },
  completed: {
    borderRadius: "50%",
    color: "#ffffff",
    height: "24px",
    width: "24px",
    zIndex: 1,
  },
  root: {
    alignItems: "center",
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    justifyContent: "center",
  },
});

const CustomStepIcon = (props: any) => {
  const classes = useCustomStepIconStyle();
  const { active, completed } = props;

  return (
    <div className={clsx(classes.root)}>
      {completed && (
        <Check
          className={classes.completed}
          fontSize={"small"}
          style={{ background: props.color }}
        />
      )}
      {active && !completed && (
        <div className={classes.circle} style={{ background: props.color }}>
          {props.step}
        </div>
      )}
      {!active && !completed && (
        <div
          className={classes.circle}
          style={{
            background: "rgba(46, 61, 74, 0.36)",
          }}
        >
          {props.step}
        </div>
      )}
    </div>
  );
};

const Connector = withStyles({
  line: {
    width: "60%",
  },
})(StepConnector);

const LeftArrow: React.FC<{ color?: string }> = ({
  color,
}: PropsWithChildren<{ color?: string }>): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
        fill={`${color ? color : "#023365"}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L6.41421 12L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289Z"
        fill={`${color ? color : "#023365"}`}
      />
    </svg>
  );
};

export const StepperPayment: FC = () => {
  const { isMobile } = useStateApp();
  const { setStep, step, steps } = useStepperPaymentContext();
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo.colors! || {}
  );
  const { primary: primaryColor } = colors;
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item alignItems="center" xs={1} sm={6}>
        <Box pt={isMobile ? 2 : 4}>
          <Button
            onClick={() => {
              setStep(StepperEnum.FORM);
            }}
            disabled={step === 0}
            className={!isMobile ? "" : classes.leftArrow}
          >
            {step !== 0 && (
              <LeftArrow
                color={primaryColor ? primaryColor : theme.palette.primary.dark}
              />
            )}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={10} sm={6}>
        <Stepper
          activeStep={step}
          alternativeLabel
          className={isMobile ? classes.stepperMobile : classes.stepperDesktop}
          connector={
            <Connector
              className={
                isMobile
                  ? classes.connectorStepperMobile
                  : classes.connectorStepperDesktop
              }
            />
          }
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                className={
                  isMobile ? classes.stepIconMobile : classes.stepIconDesktop
                }
                StepIconComponent={(props) => (
                  <CustomStepIcon
                    {...props}
                    color={
                      primaryColor ? primaryColor : theme.palette.primary.dark
                    }
                    step={index + 1}
                  />
                )}
              >
                <Grid
                  className={
                    isMobile
                      ? classes.stepLabelMobile
                      : classes.stepLabelDesktop
                  }
                  item
                >
                  <T id={"stepperLabels.".concat(label)} />
                </Grid>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={1} sm={6} />
    </Grid>
  );
};

export default StepperPayment;
