import React from "react";
import { CoverEnum } from "../../shared/infrastructure/CoverEnum";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import LeftRightCover from "../../components/Cover/LeftRightCover/LeftRightCover";
import CenterCover from "../../components/Cover/CenterCover/CenterCover";
import CenterCoverMobile from "../../components/Cover/CenterCoverMobile/CenterCoverMobile";
import { useStateApp } from "../../state/useStateApp";
import { makeStyles } from "@material-ui/core/styles";
import LeftRightCoverMobile from "../../components/Cover/LeftRightCoverMobile/LeftRightCoverMobile";
import { useHistory } from "react-router-dom";
import { Box, Theme } from "@material-ui/core";
import PreviewBanner from "../../components/commons/PreviewBanner/PreviewBanner";

const useStyles = makeStyles((theme: Theme) => ({
  containerVertical: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
  },
  previewBannerContainer: {
    bottom: "50px",
    position: "sticky",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      bottom: "50px",
    },
  },
}));

const renderMobileCover = (cover: String): JSX.Element => {
  switch (cover as CoverEnum) {
    case CoverEnum.CENTER:
      return <CenterCoverMobile />;
    case CoverEnum.LEFT:
    case CoverEnum.RIGHT:
      return <LeftRightCoverMobile />;
  }
};

const renderCover = (cover: String): JSX.Element => {
  switch (cover as CoverEnum) {
    case CoverEnum.CENTER:
      return <CenterCover />;
    case CoverEnum.LEFT:
    case CoverEnum.RIGHT:
      return <LeftRightCover />;
  }
};

const Cover: React.FC = () => {
  const { smartlink, draftMode } = useSelector((state: IAppState) => state!);
  const cover = smartlink?.styleAndStructure
    ? smartlink.styleAndStructure.coverModel
    : "";
  const { isMobile } = useStateApp();
  const classes = useStyles();
  const history = useHistory();

  if (!cover) history.push("./");

  return (
    <>
      <div className={classes.containerVertical}>
        {isMobile ? renderMobileCover(cover) : renderCover(cover)}
      </div>
      {draftMode && (
        <Box className={classes.previewBannerContainer}>
          <PreviewBanner />
        </Box>
      )}
    </>
  );
};

export default Cover;
