export enum CardsEnum {
  visa = "visa",
  mastercard = "mastercard",
  diners = "diners",
  discover = "discover",
  amex = "amex",
  transfer = "transfer",
  cash = "cash",
}

export enum CardType {
  DEBIT = "debit",
  DEBIT_CARD = "debitCard",
}
