import React from "react";
import { Translate as T } from "react-localize-redux";
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ILocationState } from "../../../containers/Payment/Payment";
import { useLocation } from "react-router-dom";
import logoKushki from "../../../assets/logos/kushki_logo.svg";
import { useStateApp } from "../../../state/useStateApp";
import { ResumeProps } from "../../../containers/Resume/Resume";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    minHeight: "40px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "30px",
    },
  },
  button: {
    height: "48px",
    [theme.breakpoints.down("xs")]: {
      height: "32px",
    },
  },
  buttonContainer: {
    marginTop: "48px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
    },
  },
  checkSubText: {
    fontSize: "16px",
  },
  checkText: {
    fontSize: "32px",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      paddingTop: "8px",
    },
  },
  contentCard: {
    padding: "0px",
  },
  contentCardHeader: {
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "0px 9px 0px 9px",
    },
  },
  descriptionText: {
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "140%",
  },
  detailCard: {
    borderRadius: "10px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    margin: "auto",
    marginLeft: "16px",
    marginRight: "16px",
    padding: "32px 44px 32px 44px",
    width: "606px",
    [theme.breakpoints.down("xs")]: {
      boxShadow:
        "0px 10px 10px rgba(60, 66, 87, 0.12), 0px 0px 15px rgba(0, 0, 0, 0.12)",
      margin: 0,
      padding: "32px 0px 32px 0px",
      width: "330px",
    },
  },
  detailsContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  detailsItem: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
    },
  },
  headerContainer: {
    [theme.breakpoints.down("xs")]: { padding: "0px 8px 0px 8px" },
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
  },
  productImage: {
    borderRadius: "10px",
    height: "80px",
    maxHeight: "80px",
    maxWidth: "80px",
    objectFit: "cover",
    width: "100%",
  },
  promotionalText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    marginBottom: "18px",
    marginTop: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      marginBottom: "20px",
      marginTop: "8px",
    },
  },
  speiContainer: {
    backgroundColor: "rgba(2, 51, 101, 0.1)",
    marginBottom: "18px",
    marginTop: "18px",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "32px",
      padding: "10px 30px 10px 30px",
    },
  },
  speiTitle: {
    fontSize: "20px",
    fontWeight: 500,
  },

  speiTitleContainer: {
    marginBottom: "24px",
  },

  summarizeDescription: {
    color: "#5c5c5c",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%",
    margin: "8px 0px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "170%",
    },
  },
  summarizeInfo: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "150%",
    },
  },
}));

const ResumeAccendo: (props: ResumeProps) => JSX.Element = (
  props: ResumeProps
) => {
  const classes = useStyles();
  const {
    state: {
      resumeData: {
        product,
        name,
        totalAmount,
        currency,
        bank = "Accendo",
        referenceNumber,
      },
    },
  } = useLocation<ILocationState>();

  const { isMobile } = useStateApp();

  const detailComponent = (label: string, content: string) => (
    <Grid
      item
      container
      className={classes.detailsItem}
      alignItems="center"
      justify="space-between"
    >
      <Grid item xs={12} md={12}>
        <Typography className={classes.summarizeDescription}>
          <T id={label} />
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography className={classes.summarizeInfo}>{content}</Typography>
      </Grid>
    </Grid>
  );

  const personalInfoComponent = () => (
    <Grid
      item
      container
      className={classes.detailsItem}
      alignItems="flex-start"
      justify="space-between"
    >
      <Grid item xs={12}>
        <Typography className={classes.summarizeDescription}>
          <T id="summarizeLabels.summarizePersonalInfoLabel" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.dynamicFormValues.map((info: string) => (
          <Typography className={classes.summarizeInfo} key={info}>
            {info}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );

  const desktopComponent = () => (
    <>
      <Grid item md={7}>
        {props.dynamicFormValues.length > 0 && personalInfoComponent()}
      </Grid>
    </>
  );

  const mobileComponent = () => (
    <>
      <Grid item xs={12}>
        {props.dynamicFormValues.length > 0 && personalInfoComponent()}
      </Grid>
    </>
  );

  return (
    <Grid container justify="center">
      <Card className={classes.detailCard}>
        <Grid container direction="column" xs={12} md={12} lg={12}>
          <CardContent className={classes.contentCardHeader}>
            <Grid item justify="center" className={classes.logo}>
              <img
                src={logoKushki}
                className={classes.productImage}
                alt={product}
              />
            </Grid>
            <Grid
              className={classes.headerContainer}
              item
              xs={12}
              md={12}
              lg={12}
            >
              <Typography
                className={classes.checkText}
                color={"initial"}
                align="center"
              >
                <T id="summarizeAccendo.summarizeTitle" />
              </Typography>
              <Typography
                className={classes.promotionalText}
                color={"initial"}
                align="center"
              >
                <T id="summarizeAccendo.successSubTitlePart1" />
                <b>
                  <T id="summarizeAccendo.successSubTitleBold" />
                </b>
                <T id="summarizeAccendo.successSubTitlePart2" />
              </Typography>
            </Grid>
          </CardContent>
          <CardContent className={classes.contentCard}>
            <Grid
              className={classes.speiContainer}
              item
              container
              xs
              direction="column"
            >
              <Grid className={classes.speiTitleContainer} item xs>
                <Typography className={classes.speiTitle} align="center">
                  <T id="summarizeAccendo.speiInfo" />
                </Typography>
              </Grid>
              <Grid item container justify="space-between" xs>
                <Grid item xs={12} sm={4} md={3}>
                  {detailComponent(
                    "summarizeAccendo.amount",
                    totalAmount + " " + currency
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  {detailComponent(
                    "summarizeAccendo.referenceNumber",
                    referenceNumber as string
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent className={classes.contentCard}>
            <Grid
              item
              className={classes.detailsContainer}
              container
              xs={12}
              sm={12}
              md={12}
              spacing={2}
            >
              <Grid item xs={12} sm={7} md={7}>
                {detailComponent("summarizeAccendo.product", product as string)}
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                {detailComponent(
                  "summarizeAccendo.beneficiaryName",
                  name as string
                )}
              </Grid>
              {isMobile ? mobileComponent() : desktopComponent()}
              <Grid item xs={12} sm={5} md={5}>
                {detailComponent("summarizeAccendo.bank", bank as string)}
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ResumeAccendo;
