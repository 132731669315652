import { useHistory, useParams } from "react-router-dom";
import { IIndexPathParameter } from "../../../shared/infrastructure/IIndexPathParameter";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getMerchantCustomization,
  getSmartlink,
  setSocialReason,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { RouteEnum } from "../../../shared/infrastructure/RouteEnum";
import { StructureEnum } from "../../../shared/infrastructure/StructureEnum";
import { defaultTo, isEmpty } from "lodash";

const useIndex = () => {
  const params = useParams<IIndexPathParameter>();

  const history = useHistory();
  const dispatch = useDispatch();
  const structure: string = useSelector((state: IAppState) => state.structure);
  const loading: boolean = useSelector((state: IAppState) => state.loading);
  const merchantId: string = useSelector(
    (state: IAppState) => state.smartlink?.publicMerchantId!
  );
  const merchantNameMerchant: string = useSelector(
    (state: IAppState) => state.smartlink?.merchantName!
  );

  useEffect(() => {
    const { merchantName, smartlinkId } = params;

    if (!smartlinkId) history.push(RouteEnum.NOT_FOUND);

    dispatch(setSocialReason(merchantName));
    dispatch(getSmartlink(smartlinkId, history));
  }, []);

  useEffect(() => {
    if (!isEmpty(merchantId)) {
      dispatch(getMerchantCustomization(merchantId));
    }
  }, [merchantId]);

  useEffect(() => {
    const { merchantName, smartlinkId } = params;

    if (loading) return;

    let merchantNameFromStorage: string = encodeURI(
      defaultTo(merchantNameMerchant, "smartlink")
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-")
    );

    let merchantNameFromPath: string = encodeURI(
      merchantName.trim().toLowerCase().replace(/\s+/g, "-")
    );

    if (merchantNameFromPath !== merchantNameFromStorage) {
      history.push(RouteEnum.NOT_FOUND);

      return;
    }

    if (structure === StructureEnum.COVER) {
      history.push(
        `/${merchantName || "smartlink"}/${smartlinkId}/${StructureEnum.COVER}`
      );

      return;
    } else {
      history.push(
        `/${merchantName || "smartlink"}/${smartlinkId}/${
          StructureEnum.CHECKOUT
        }`
      );

      return;
    }
  }, [structure, loading]);
};

export { useIndex };
