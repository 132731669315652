import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { FormProvider } from "react-hook-form";
import { UseFormMethods } from "react-hook-form/dist/types";
import { useSelector } from "react-redux";
import { CustomForm } from "../../../../types/smart_link_V2";
import {
  DynamicComponents,
  DynamicEnum,
} from "../../../shared/infrastructure/DynamicEnum";
import { IAppState } from "../../../store/reducer";
import { isCheckListEmpty } from "./CheckboxList/CheckboxList";
import { SELECT_EMPTY_VALUE } from "./Select/Select";
import { Translate as T } from "react-localize-redux";
import { defaultTo } from "lodash";

const getSizeProps = (split: boolean): object =>
  split ? { md: 6, sm: 12, xs: 12 } : { xs: 12 };

const getLabel = (label: string, required: boolean): JSX.Element => {
  return (
    <>
      {label + " "}
      {!required && <T id={"form.optionalField"} />}
    </>
  );
};

const getDate = (date: Date): object => {
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
};

const getCheckListValues = (
  { name, options }: CustomForm,
  values: DynamicFormBody
): object => {
  const response: DynamicFormBody = {};

  options!.forEach(
    (option: string) => (response[`${name}${option}`] = values[name][option])
  );

  return response;
};

const transformDynamicFormValues = (data: any, formConfig: CustomForm[]) => {
  let values = { ...data };
  let isValid: boolean = true;

  formConfig.forEach((config) => {
    if (config.type === DynamicEnum.DATE)
      values[config.name] = getDate(values[config.name]);
    if (config.type === DynamicEnum.CHECKBOX) {
      const checkValues = getCheckListValues(config, values);

      if (
        config.required &&
        isCheckListEmpty(
          config.required,
          config.name,
          config.options!,
          values[config.name]
        )
      )
        isValid = false;
      delete values[config.name];
      values = { ...values, ...checkValues };
    }
    if (config.type === DynamicEnum.SELECT) {
      if (values[config.name] === SELECT_EMPTY_VALUE) values[config.name] = "";
    }

    if (
      (config.type === DynamicEnum.INPUT ||
        config.type === DynamicEnum.TEXT_AREA) &&
      config.disabled &&
      config.value
    ) {
      values[config.name] = config.value;
    }
  });

  return [values, isValid];
};

interface DynamicFormBody {
  [key: string]: any;
}

const useStyles = makeStyles({
  section: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%",
    paddingBottom: "10px",
  },
  subTitle: {
    color: "#293036",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "120%",
  },
  title: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    paddingBottom: 8,
  },
});

interface IDynamicForm {
  form: UseFormMethods<DynamicFormBody>;
}

const DynamicForm: React.FC<IDynamicForm> = (props: IDynamicForm) => {
  const classes = useStyles();
  const formConfig = useSelector(
    (state: IAppState) => state.smartlink!.formConfig
  );
  const { form } = props;

  return (
    <FormProvider {...form}>
      <br />
      <form id="dynamicForm">
        <Typography className={classes.section}>
          <T id={"form.additionalData"} />
        </Typography>
        <Grid container spacing={3}>
          {defaultTo(formConfig, []).map(
            (formItem: CustomForm, index: number) => {
              const size = getSizeProps(formItem.split);
              const DynamicComponent =
                DynamicComponents[formItem.type as DynamicEnum];

              return (
                <Grid item {...size} key={index}>
                  <DynamicComponent {...formItem} />
                </Grid>
              );
            }
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

export { DynamicForm, getLabel, transformDynamicFormValues };
export type { DynamicFormBody };
