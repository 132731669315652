/* istanbul ignore file */
/* eslint-disable */
export const environment: {
envName: string;
kushkiUrl: string;
devTools: boolean;
firebaseConfig: {
apiKey: string;
authDomain: string;
databaseURL: string;
projectId: string;
storageBucket: string;
messagingSenderId: string;
};
rollbarId: string;
gtmId: string;
} = {
envName: "primary",
kushkiUrl: "https://api.kushkipagos.com",
devTools: false,
firebaseConfig: {
apiKey:'AIzaSyAXtQlq6sXC3-cVMCUvwp6DUC1RteuT4VA',
authDomain:'kushki-97eba.firebaseapp.com',
databaseURL: 'https://kushki-97eba.firebaseio.com',
projectId: 'kushki-97eba',
storageBucket: 'kushki-97eba.appspot.com',
messagingSenderId: '821114948879'
},
rollbarId: '55c90f43758841acade2da652c08f50b',
gtmId: 'GTM-NLW4QRL'
};
