import React from "react";
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Translate as T } from "react-localize-redux";
import theme from "../../../theme";
import { PaymentTypeEnum } from "../../../shared/infrastructure/PaymentTypeEnum";
import { useSmartlinkDetailTotal } from "./useSmartlinkDetailTotal/useSmartlinkDetailTotal";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  COUNTRY_ISO_CODE,
  ISOCountryEnum,
} from "../../../shared/enums/country_code_iso_enum";
import { get } from "lodash";
import { PaymentConfigTypeEnum } from "../../../shared/infrastructure/PaymentConfigTypeEnum";
import { AMOUNT_CHARACTERS } from "../../../shared/infrastructure/CharactersEnum";

const useStyles = makeStyles(() => ({
  amountContainer: {
    paddingBottom: "2px",
    paddingTop: "2px",
  },
  amountDetailText: {
    fontSize: "15px",
    fontWeight: 400,
  },
  divider: {
    marginBottom: "2px",
    marginTop: "2px",
  },
  openAmountSection: {
    marginTop: "17px",
  },
  singlePaymentText: {
    fontSize: "20px",
    fontWeight: 300,
    paddingBottom: "20px",
  },
  toggleButtonGroupRoot: {
    borderRadius: "5px !important",
    paddingTop: "16px",
  },
  toggleButtonRoot: ({
    primaryColor,
  }: {
    primaryColor: string | undefined;
  }) => ({
    "& .MuiTypography-subtitle1": {
      color: primaryColor ? primaryColor : theme.palette.text.primary,
    },
    "&.Mui-selected": {
      backgroundColor: primaryColor ? primaryColor : theme.palette.primary.dark,
    },
    "&.Mui-selected .MuiTypography-subtitle1": {
      color: "#ffffff",
    },
    "&.Mui-selected:hover": {
      backgroundColor: primaryColor ? primaryColor : theme.palette.primary.dark,
    },
    background: theme.palette.background.paper,
    border: "0.6px solid",
    borderColor: primaryColor ? primaryColor : theme.palette.primary.dark,
    height: "32px",
    textTransform: "none",
  }),
  totalText: ({ primaryColor }: { primaryColor: string | undefined }) => ({
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "20px",
    fontWeight: 500,
  }),
  widthMenu: {
    "& div": {
      width: "30%",
    },
    "& ul": {
      padding: 0,
    },
  },
}));

export interface ITotalProps {
  handlerIsOpen: () => void;
  isOpen: boolean;
}

const SmartlinkDetailTotal: React.FC<ITotalProps> = (
  props: React.PropsWithChildren<ITotalProps>
) => {
  const {
    amountError,
    amountMessage,
    amountData,
    currencySymbol,
    handler: { onChange, onChangeAmount },
    paymentType,
    paymentTypeSmarlink,
    paymentConfig,
    paymentConfigType,
    smartlink,
    newTotalAmount,
  } = useSmartlinkDetailTotal();
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo!.colors || {}
  );
  const { primary: primaryColor } = colors;
  const style = useStyles({ primaryColor });

  const getMaximumFractionPerCountry = (country: string) => {
    switch (country) {
      case ISOCountryEnum.COLOMBIA:
      case ISOCountryEnum.CHILE:
        return 0;
      default:
        return 2;
    }
  };

  const formatAmount = (amount: number) => {
    if (amountData.currency === "UF") return amount.toFixed(2);

    const country = get(smartlink, "country");

    if (!country) return amount;

    const formatter = new Intl.NumberFormat(
      `${get(smartlink, "language", "es")}-${COUNTRY_ISO_CODE[country]}`,
      {
        currency: amountData.currency,
        maximumFractionDigits: getMaximumFractionPerCountry(country),
        style: "currency",
      }
    );

    return formatter.format(amount);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        {paymentTypeSmarlink === PaymentTypeEnum.MIXED && (
          <ToggleButtonGroup
            defaultValue={PaymentTypeEnum.UNIQUE}
            value={paymentType}
            exclusive
            onChange={onChange}
            aria-label="text alignment"
            classes={{ root: style.toggleButtonGroupRoot }}
          >
            <ToggleButton
              value={PaymentTypeEnum.UNIQUE}
              classes={{ root: style.toggleButtonRoot }}
            >
              <Typography variant={"subtitle1"}>
                <T id="singlePayment" />
              </Typography>
            </ToggleButton>
            <ToggleButton
              value={PaymentTypeEnum.SUBSCRIPTION}
              classes={{ root: style.toggleButtonRoot }}
            >
              <Typography variant={"subtitle1"}>
                <T id="subscription" />
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {paymentTypeSmarlink === PaymentTypeEnum.UNIQUE &&
          paymentConfigType === PaymentConfigTypeEnum.FIXED && (
            <Typography variant={"h5"}>
              <T id="singlePayment" />
            </Typography>
          )}
        {paymentTypeSmarlink === PaymentTypeEnum.UNIQUE &&
          paymentConfigType === PaymentConfigTypeEnum.OPEN && (
            <Grid
              container
              xs={12}
              md={12}
              lg={12}
              alignItems={"center"}
              className={style.openAmountSection}
            >
              <Grid item md={12} xs={12}>
                <Typography
                  variant={"subtitle1"}
                  className={style.singlePaymentText}
                >
                  <T id="singlePayment" />
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  label={<T id="amountLabel" />}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {paymentConfig.currency}
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                  type={"number"}
                  onKeyDown={(event) => {
                    if (!AMOUNT_CHARACTERS.includes(event.key))
                      event.preventDefault();
                  }}
                  defaultValue={get(paymentConfig, "defaultAmount")}
                  value={newTotalAmount}
                  fullWidth
                  onChange={(event) => {
                    const amount = event.target.value;
                    const splitAmount = amount.split(".");

                    if (splitAmount.length > 1 && splitAmount[1].length > 2) {
                      return;
                    }
                    onChangeAmount(amount);
                  }}
                  error={amountError}
                  helperText={amountMessage}
                />
              </Grid>
            </Grid>
          )}
        {paymentTypeSmarlink === PaymentTypeEnum.SUBSCRIPTION && (
          <Typography variant={"h5"}>
            <T id="subscription" />
          </Typography>
        )}
      </Grid>
      <Box mb={2} />
      <Grid container item xs={12} md={12} lg={12}>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={12}
          className={style.amountContainer}
        >
          <Grid item xs={6} md={6} lg={6}>
            <Typography className={style.amountDetailText}>
              <T id="taxes" />
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className={style.amountDetailText} align={"right"}>
              {formatAmount(amountData.taxes)} {amountData.currency}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={12}
          className={style.amountContainer}
        >
          <Grid item xs={6} md={6} lg={6}>
            <Typography className={style.amountDetailText}>
              <T id="subtotal" />
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className={style.amountDetailText} align={"right"}>
              {formatAmount(amountData.subTotal)} {amountData.currency}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={style.divider} />
      <Box
        display={"flex"}
        justifyContent={"center"}
        onClick={() => props.handlerIsOpen()}
      >
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={12}
          className={style.amountContainer}
        >
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant={"subtitle1"} className={style.totalText}>
              <T id="total" />
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography
              variant={"subtitle1"}
              align={"right"}
              className={style.totalText}
            >
              {formatAmount(amountData.total)} {amountData.currency}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default SmartlinkDetailTotal;
