import { RouteEnum } from "./RouteEnum";

export enum StructureEnum {
  COVER = "cover",
  CHECKOUT = "checkout",
}

export const StructureRoute: Record<StructureEnum | string, RouteEnum> = {
  [StructureEnum.COVER]: RouteEnum.COVER,
  [StructureEnum.CHECKOUT]: RouteEnum.CHECKOUT,
};
