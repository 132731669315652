import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { useFormContext } from "react-hook-form";
import { CustomForm } from "../../../../../types/smart_link_V2";
import { Translate as T } from "react-localize-redux";
import { getLabel } from "../DynamicForm";

const useStyles = makeStyles({
  ksh_multiline: {
    "& > label+div": {
      height: "auto",
    },
  },
});

const TextArea: React.FC<CustomForm> = (props: CustomForm) => {
  const classes = useStyles();
  const { label, name, required, disabled, placeholder, value } = props;
  const { register, errors } = useFormContext();

  return (
    <TextField
      className={classes.ksh_multiline}
      label={getLabel(label, required)}
      placeholder={placeholder}
      name={name}
      defaultValue={value}
      disabled={disabled}
      inputRef={register({ required: required })}
      fullWidth
      multiline
      variant={"outlined"}
      rows={2}
      rowsMax={10}
      error={!!errors[name]}
      helperText={!!errors[name] && <T id={"form.fieldRequired"} />}
    />
  );
};

export { TextArea };
