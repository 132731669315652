import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Translate as T } from "react-localize-redux";
import { useStateApp } from "../../../state/useStateApp";

export interface IErrorProps {
  labels: {
    title: string;
    description: string;
    icon: string;
  };
  handler: () => void;
}

const GenericError: React.FC<IErrorProps> = (
  props: React.PropsWithChildren<IErrorProps>
) => {
  const { isMobile } = useStateApp();

  return (
    <>
      <Box display="flex" justifyContent="center" pb={3} pt={isMobile ? 4 : 2}>
        <img src={props.labels.icon} alt={props.labels.icon} />
      </Box>
      <Box pb={1} pt={isMobile ? 2 : 3}>
        <Typography variant={"h3"} align={"center"}>
          <T id={props.labels.title} />
        </Typography>
      </Box>
      <Box pt={1} pb={isMobile ? 4 : 2}>
        <Typography variant={"h6"} align={"center"}>
          <T id={props.labels.description} />
        </Typography>
      </Box>
    </>
  );
};

export default GenericError;
