/* istanbul ignore file */
import { english } from "../languages/English";
import { LanguageEnum } from "../infrastructure/LanguageEnum";
import { portuguese } from "../languages/Portuguese";
import { spanish } from "../languages/Spanish";

export interface IHeaderTexts {
  paymentMethods: string[];
  changePayment: string[];
  cardOrDebit: string[];
}

export interface IItemTitles {
  fastPay: string[];
  creditCard: string[];
  transfer: string[];
  transferSubscription: string[];
  cash: string[];
  cardSubscription: string[];
  cardAsync: string[];
  cardDynamic: string[];
  cardSubscriptionAsync: string[];
  cardSubscriptionDynamic: string[];
  checkData: string[];
}

export interface IItemDescriptions {
  makePaymentsKpay: string[];
  makePaymentsCard: string[];
  makePaymentsTransfer: string[];
  makePaymentsCash: string[];
  makePaymentsCardAsync: string[];
  makePaymentsCardDynamic: string[];
  makePaymentsCardSubscription: string[];
  makePaymentsCardSubscriptionAsync: string[];
  makePaymentsCardSubscriptionDynamic: string[];
}

export interface ICardSection {
  buttonText: string[];
}

export interface IConfirmationData {
  title: string[];
  subtitle: string[];
  subtitleBold: string[];
  subscribeButton: string[];
  cancelButton: string[];
}

export interface ICashSection {
  email: string[];
  fieldRequired: string[];
  identificationNumber: string[];
  invalidEmail: string[];
  payButton: string[];
  lastName: string[];
  name: string[];
  paymentOrder: string[];
}

export interface ITransferSafetyPaySection {
  email: string[];
  fieldRequired: string[];
  documentNumber: string[];
  invalidEmail: string[];
  name: string[];
  payButton: string[];
}

export interface ILanguage {
  name: string;
  code: string;
}

export interface ITextLanguages {
  en: string[];
  es: string[];
  br: string[];
}

export interface ITextLanguagesShort {
  en: string[];
  es: string[];
  br: string[];
}

export interface IFooter {
  contacts: string[];
  help: string[];
}

export interface IForm {
  fieldRequired: string[];
  emailInvalid: string[];
  numberCardInvalid: string[];
  cvcInvalid: string[];
  expirationDateInvalid: string[];
  selectEmptyOption: string[];
  title: string[];
  fillData: string[];
  personalData: string[];
  additionalData: string[];
  optionalField: string[];
}

export interface ISpei {
  description: string[];
  message: string[];
}

export interface ILoadingOverlay {
  mainLine: string[];
  secondaryLine: string[];
}

export interface INotification {
  confirmIdentity: string[];
}

export interface ISubscriptionPeriodicity {
  daily: string[];
  weekly: string[];
  biweekly: string[];
  monthly: string[];
  bimonthly: string[];
  threefortnights: string[];
  quarterly: string[];
  fourmonths: string[];
  halfYearly: string[];
  yearly: string[];
  custom: string[];
}

export interface IAppTranslation {
  cardCheckout: {
    name: string[];
    nameLastname: string[];
    cardNumber: string[];
    expirationDate: string[];
    CVC: string[];
  };
  confrontaAuthentication: {
    confrontaLabel: string[];
    identificationLabel: string[];
    expeditionDatePlaceholder: string[];
    provinceLPlaceholder: string[];
    cityPlaceholder: string[];
    subscribeButton: string[];
    CancelButton: string[];
    validateData: string[];
    howItWorks: string[];
    requiredField: string[];
  };
  deferredOptions: {
    title: string[];
    months: string[];
    typeDeferred: string[];
    monthsOfGrace: string[];
  };
  recurrentDebit: {
    current: string[];
    savings: string[];
    typeAccount: string[];
  };
  summarizeAccendo: {
    amount: string[];
    bank: string[];
    beneficiaryName: string[];
    clabeAccount: string[];
    personalData: string[];
    product: string[];
    referenceNumber: string[];
    speiInfo: string[];
    successSubTitleBold: string[];
    successSubTitlePart1: string[];
    successSubTitlePart2: string[];
    summarizeTitle: string[];
  };
  summarizeBankBogota: {
    successTitle: string[];
    summarizeTitle: string[];
    summarizeProductLabel: string[];
    summarizeAmountLabel: string[];
    summarizeStateLabel: string[];
    summarizePersonalInfoLabel: string[];
  };
  summarizeLabels: {
    successTitle: string[];
    successSubTitle: string[];
    summarizeTitle: string[];
    summarizeProductLabel: string[];
    summarizeTicketNumberLabel: string[];
    summarizeAmountLabel: string[];
    summarizeStateLabel: string[];
    summarizePersonalInfoLabel: string[];
  };
  sumarizeLabelsForTransferETPAY: {
    amount: string[];
    date: string[];
    email: string[];
    name: string[];
    nit: string[];
    successSubTitle: string[];
    product: string[];
    successTitle: string[];
    status: string[];
    summarizeTitle: string[];
    personalInfo: string[];
    ticketNumber: string[];
  };

  sumarizeLabelsForTransferPSE: {
    amount: string[];
    bank: string[];
    cus: string[];
    date: string[];
    email: string[];
    identification: string[];
    name: string[];
    successSubTitle: string[];
    product: string[];
    successTitle: string[];
    summarizeTitle: string[];
    personalInfo: string[];
    socialReason: string[];
  };

  summarizeLabelsForTransferSPEI: {
    summarizeTitle: string[];
    successSubTitlePart1: string[];
    successSubTitlePart2: string[];
    successSubTitleBold: string[];
    speiInfo: string[];
    clabeAccount: string[];
    amount: string[];
    referenceNumber: string[];
    product: string[];
    ticketNumber: string[];
    paymentMethod: string[];
    personalData: string[];
    email: string[];
    transaction: string[];
  };
  summarizeLabelsForCash: {
    downloadButtonLabel: string[];
    paymentCash: string[];
    successSubTitle: string[];
    rememberMsm: string[];
    summarizePayMethodLabel: string[];
  };
  summarizeLabelsForSubscription: {
    paymentSubscription: string[];
    successSubTitle: string[];
    payMethodLabel: string[];
    periodicityLabel: string[];
    subscriptionIDLabel: string[];
    endDateLabel: string[];
    numberOfFeesLabel: string[];
    startDateLabel: string[];
    summarizeAmountLabel: string[];
    summarizePersonalInfoLabel: string[];
    summarizeProductLabel: string[];
    summarizeStateLabel: string[];
    summarizeTitle: string[];
    summarizeEmail: string[];
    summarizeUndefinedLabel: string[];
  };
  btnLabels: {
    payCheckoutMobile: string[];
    payCheckoutDesktop: string[];
    retry: string[];
    buttonFormLabel: string[];
  };
  emptyInfo: string[];
  emptyInfoButton: string[];
  expiredLink: {
    description: string[];
    title: string[];
  };
  previewBanner: string[];
  saveText: string[];
  notAvail: {
    description: string[];
    title: string[];
  };
  notFound: {
    description: string[];
    title: string[];
  };
  outStock: {
    description: string[];
    title: string[];
  };
  listPaymentMethods: {
    titlePayMethods: string[];
    titlePaySaved: string[];
    titleOtherPay: string[];
    titleCard: string[];
    titleCash: string[];
    titleTransfer: string[];
    titleCardAndDebit: string[];
    expiredCardMessage: string[];
  };
  pci: string[];
  pse: IPSE;
  transactionFailed: {
    description: string[];
    title: string[];
  };
  verificationFailed: {
    description: string[];
    descriptionSecondary: string[];
    title: string[];
  };
  timerCover: {
    description: string[];
    days: string[];
    hours: string[];
    minutes: string[];
    seconds: string[];
  };
  languages: ITextLanguages;
  languagesShort: ITextLanguagesShort;
  total: string[];
  stepperLabels: {
    FORM: string[];
    PAY: string[];
  };
  cardSection: ICardSection;
  headerText: IHeaderTexts;
  itemTitles: IItemTitles;
  itemDescriptions: IItemDescriptions;
  footer: IFooter;
  payButton: string[];
  subtotal: string[];
  taxes: string[];
  singlePayment: string[];
  amountLabel: string[];
  subscription: string[];
  saveKpayLabel: string[];
  agree: string[];
  terms: string[];
  form: IForm;
  loadingOverlay: ILoadingOverlay;
  notification: INotification;
  readMore: string[];
  howtoWorksButton: string[];
  validateData: string[];
  confirmationData: IConfirmationData;
  spei: ISpei;
  siftScienceForm: {
    title: string[];
    department: string[];
    personalData: string[];
    firstName: string[];
    lastName: string[];
    email: string[];
    country: string[];
    documentType: string[];
    documentNumber: string[];
    region: string[];
    city: string[];
    address: string[];
    secondaryAddress: string[];
    zipCode: string[];
    countryCode: string[];
    phone: string[];
    province: string[];
    required: string[];
    municipality: string[];
  };
  subscriptionButton: string[];
  transferSubscriptionCheckout: {
    howItWorks: string[];
    name: string[];
    identificationNumber: string[];
    bankId: string[];
    accountNumber: string[];
    accountType: string[];
    phoneNumber: string[];
    email: string[];
    subscribeButton: string[];
  };
  termsConditions: string[];
  cashSection: ICashSection;
  transferSafetyPaySection: ITransferSafetyPaySection;
  otpInvalidTries: {
    partFourText: string[];
    partOneText: string[];
    partTreeText: string[];
    partTwoText: string[];
  };
  otpTextVerification: {
    confirmCode: string[];
    invalidCode: string[];
    partOneText: string[];
    partTwoText: string[];
    verification: string[];
  };
  kpayResume: {
    info: string[];
  };
  kpayEmail: {
    confirm: string[];
    invalidEmail: string[];
    placeholder: string[];
    tittle: string[];
    subtittle: string[];
    return: string[];
    edit: string[];
    cancel: string[];
  };
  cardSubscriptionAsync: {
    email: string[];
  };
  cardAsyncSection: {
    email: string[];
  };
  informationConfronta: string[];
  kpayOtp: {
    backButtonHelper: string[];
    changePaymentMethod: string[];
    codePlaceholderHelper: string[];
    confirmButtonHelper: string[];
    emailCodeHelper: string[];
    requiredField: string[];
    safetyHelper: string[];
    wrongCodeError: string[];
  };
  transactionStatus: {
    approval: string[];
    initialized: string[];
  };
  subscriptionPeriodicity: ISubscriptionPeriodicity;
  loadingLabel: string[];
  openAmountMessages: {
    initialMsgWithDefaultAmount: string[];
    minAmountMsg: string[];
    maxAmountMsg: string[];
    defaultMsg: string[];
    initialMsg: string[];
    infoMsg: string[];
    maxAmountMsgWithDefaultAmt: string[];
    minAmountMsgWithDefaultAmt: string[];
  };
}

export interface IPSE {
  typePerson: string[];
}

export const availableLanguages: ILanguage[] = [
  { code: LanguageEnum.SPANISH, name: "Español" },
  { code: LanguageEnum.ENGLISH, name: "English" },
  { code: LanguageEnum.PORTUGUESE, name: "Português" },
];

function mergeLanguages(
  defaultLanguage: object,
  translateOptions: object[]
): object {
  const result: object = defaultLanguage;

  translateOptions.forEach((translations: object) => {
    Object.keys(translations).forEach((key: string) => {
      // @ts-ignore
      if (Array.isArray(translations[key])) {
        // @ts-ignore
        result[key] = [...result[key].concat(translations[key])];
      } else {
        // @ts-ignore
        const subKeys: string[] = Object.keys(translations[key]);

        subKeys.forEach((subKey: string) => {
          // @ts-ignore
          result[key][subKey] = [
            // @ts-ignore
            ...result[key][subKey].concat(translations[key][subKey]),
          ];
        });
      }

      return;
    });
  });

  return result;
}

export const getAppTranslations: () => IAppTranslation = (): IAppTranslation =>
  mergeLanguages(spanish, [english, portuguese]) as IAppTranslation;
