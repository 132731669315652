import DOMPurify from "dompurify";
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Translate as T } from "react-localize-redux";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";
import TimerCover from "../../../TimerCover/TimerCover";
import { useHistory, useParams } from "react-router-dom";
import { ButtonStyleEnum } from "../../../../../shared/infrastructure/ButtonStyleEnum";
import { IIndexPathParameter } from "../../../../../shared/infrastructure/IIndexPathParameter";
import { StructureEnum } from "../../../../../shared/infrastructure/StructureEnum";
import theme from "../../../../../theme";
import { PaymentConfigTypeEnum } from "../../../../../shared/enums/PaymentConfigTypeEnum";
import { CurrencyEnum } from "../../../../../../types/smart_link_V2";

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyleRound: {
    background: "#023365",
    borderRadius: 50,
    height: "48px",
    padding: theme.spacing(1),
    right: "0.08%",
    top: "3.5px",
  },
  buttonStyleSemi: {
    background: "#023365",
    height: "48px",
    padding: theme.spacing(1),
    right: "0.08%",
    top: "3.5px",
  },
  buttonStyleSquare: {
    background: "#023365",
    borderRadius: 0,
    height: "48px",
    padding: theme.spacing(1),
    right: "0.08%",
    top: "3.5px",
  },
  cardPadding: {
    paddingTop: theme.spacing(3),
  },
  cardPosition: {
    marginTop: "156px",
  },
  descriptionStyle: {
    ...theme.typography.subtitle1,
    color: "#293036",
    fontWeight: "lighter",
    lineHeight: "150%",
    margin: "32px 0px 0px 0px",
  },
  paymentResumeCard: {
    background: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    maxWidth: "643px",
    padding: theme.spacing(3),
    width: "100%",
  },
  subtitleTextStyle: ({
    primaryColor,
  }: {
    primaryColor: string | undefined;
  }) => ({
    ...theme.typography.subtitle1,
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "14px",
    lineHeight: "140%",
    paddingBottom: "4px",
  }),
  titleTextStyle: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
    fontSize: "24px",
  },
  totalTextStyle: ({ primaryColor }: { primaryColor: string | undefined }) => ({
    ...theme.typography.subtitle1,
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    height: "18px",
    margin: "4px 0px",
    top: "0px",
  }),
  totalValueStyle: ({
    primaryColor,
  }: {
    primaryColor: string | undefined;
  }) => ({
    ...theme.typography.subtitle1,
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "28px",
    height: "34px",
    margin: "0px 4px",
    right: "0px",
    top: "0px",
  }),
}));

export const LeftRightCoverCard: React.FC = () => {
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo!.colors || {}
  );
  const { primary: primaryColor } = colors;
  const classes = useStyles({ primaryColor });
  const smarlink = useSelector((state: IAppState) => state.smartlink!);
  const { generalConfig, paymentConfig, styleAndStructure } = smarlink;
  const totalAmount = useSelector((state: IAppState) => state.amount!.total);
  const { description, productName, promotionalText, buyButtonText } =
    generalConfig;
  const { buttonStyle } = styleAndStructure;

  let currency: CurrencyEnum = "USD";
  const params = useParams<IIndexPathParameter>();
  const { merchantName, smartlinkId } = params;
  const history = useHistory();
  const routeChange = () => {
    history.push(
      `/${merchantName || "smartlink"}/${smartlinkId}/${StructureEnum.CHECKOUT}`
    );
  };

  const sanitizedHtml = DOMPurify.sanitize(description);

  if (smarlink.paymentConfigType !== PaymentConfigTypeEnum.OPEN)
    currency = paymentConfig.amount.currency;

  return (
    <Grid
      container
      spacing={2}
      className={classes.cardPosition}
      justify="center"
    >
      <Card className={classes.paymentResumeCard} id="cardContainer">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.subtitleTextStyle}>
                {promotionalText}
              </Typography>
              <Typography variant="h4" className={classes.titleTextStyle}>
                {productName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.cardPadding}>
            {smarlink.paymentConfigType !== PaymentConfigTypeEnum.OPEN && (
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.totalTextStyle}>
                  <T id="total" />
                </Typography>
                <Typography variant="h3" className={classes.totalValueStyle}>
                  {Intl.NumberFormat("de-DE", {
                    minimumFractionDigits: 0,
                  }).format(totalAmount)}{" "}
                  {currency}
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                className={
                  buttonStyle === ButtonStyleEnum.SEMI
                    ? classes.buttonStyleSemi
                    : buttonStyle === ButtonStyleEnum.ROUND
                    ? classes.buttonStyleRound
                    : classes.buttonStyleSquare
                }
                style={{
                  background: primaryColor
                    ? primaryColor
                    : theme.palette.primary.dark,
                  color: "#ffffff",
                }}
                onClick={routeChange}
              >
                {buyButtonText}
              </Button>
            </Grid>
          </Grid>
          {generalConfig.showTimer && generalConfig.expirationDate && (
            <Grid item lg={8} sm={12} id="TimerComponent">
              <Box mt="32px">
                <TimerCover />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography className={classes.descriptionStyle}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizedHtml,
                }}
              />
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
