import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Translate as T } from "react-localize-redux";
import { useLocation } from "react-router-dom";
import logoKushki from "../../../assets/logos/kushki_logo.svg";
import { ILocationState } from "../../../containers/Payment/Payment";
import { StatusLabels } from "../../../shared/infrastructure/StatusLabels";
import { useStateApp } from "../../../state/useStateApp";
import { ResumeProps } from "../../../containers/Resume/Resume";

const useStyles = makeStyles((theme) => ({
  contentCard: {
    marginBottom: "9px",
    marginTop: "0px",
    paddingTop: "0px",
  },
  detailCard: {
    borderRadius: "10px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    margin: "auto",
    marginLeft: "16px",
    marginRight: "16px",
    padding: "0px 16px",
    paddingTop: "32px",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
  },
  logoText: {
    fontSize: "32px",
  },
  productText: {
    borderRadius: "10px",
    height: "80px",
    maxHeight: "80px",
    maxWidth: "80px",
    objectFit: "cover",
    width: "100%",
  },
  subtitleText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 100,
    lineHeight: "140%",
  },
  summarizeDescription: {
    color: "#5c5c5c",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "initial",
    lineHeight: "136%",
    margin: "8px 0px",
    textAlign: "left",
  },
  summarizeInfo: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
    margin: "8px 0px",
    textAlign: "left",
  },
  summarizePersonalInfo: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
    margin: "4px 0px",
    textAlign: "left",
    width: "100%",
  },
  summarizeTitle: {
    fontSize: "20px",
  },
  summarizeTitleInfo: {
    [theme.breakpoints.up("sm")]: {
      overflow: "auto",
    },
  },
}));

const buildMobileStyle = (isMobile: boolean): { padding: string } => {
  if (isMobile) {
    return {
      padding: "0px 0px 24px 0px",
    };
  } else {
    return {
      padding: "0px 16px 24px 16px",
    };
  }
};

export const GridInfo: React.FC<{ information: string[]; titleInfo: string }> =
  (props: { information: string[]; titleInfo: string }) => {
    const classes = useStyles();

    return (
      <Grid container xs={12} md={5} lg={5}>
        <Grid item xs={4} md={12} lg={12}>
          <Typography className={classes.summarizeDescription}>
            <T id={props.titleInfo} />
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          md={12}
          lg={12}
          className={classes.summarizeTitleInfo}
        >
          {props.information.map((info: string) => (
            <Typography className={classes.summarizeInfo} key={info}>
              {info}
            </Typography>
          ))}
        </Grid>
      </Grid>
    );
  };

const ResumeCardAsyncSubscription: (props: ResumeProps) => JSX.Element = (
  props: ResumeProps
) => {
  const location = useLocation<ILocationState>();
  const classes = useStyles();
  const { isMobile } = useStateApp();
  const { totalAmount, currency, product, subscriptionId } =
    location.state?.resumeData;

  const status = StatusLabels.APPROVAL;

  return (
    <Grid
      container
      justify="center"
      style={{ paddingTop: isMobile ? "16px" : "112px" }}
    >
      <Grid item xs={12} md={7} lg={7}>
        <Card
          className={classes.detailCard}
          style={{ height: isMobile ? "550px" : "530px" }}
        >
          <CardContent
            className={classes.contentCard}
            style={buildMobileStyle(isMobile)}
          >
            <Grid>
              <Grid item className={classes.logo}>
                <img
                  src={logoKushki}
                  className={classes.productText}
                  alt={product}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Box>
                  <Typography
                    className={classes.logoText}
                    color={"initial"}
                    align="center"
                  >
                    <T id="summarizeLabels.successTitle" />
                  </Typography>
                  <Box mt="24px">
                    <Typography
                      className={classes.subtitleText}
                      color={"initial"}
                      align="center"
                    >
                      <T id="summarizeLabels.successSubTitle" />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Box alignContent="center" mb="24px" mt="24px">
                  <Typography className={classes.summarizeTitle} align="center">
                    <T id="summarizeLabels.summarizeTitle" />
                  </Typography>
                </Box>
                <Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    flexWrap="wrap"
                    flexDirection="row"
                  >
                    <GridInfo
                      information={[totalAmount + " " + currency]}
                      titleInfo={"summarizeLabels.summarizeAmountLabel"}
                    />
                    <GridInfo
                      information={[(<T id={status} />) as any]}
                      titleInfo="summarizeLabels.summarizeStateLabel"
                    />
                    <GridInfo
                      information={[product!]}
                      titleInfo="summarizeLabels.summarizeProductLabel"
                    />
                    <GridInfo
                      information={[subscriptionId!]}
                      titleInfo="summarizeLabelsForSubscription.subscriptionIDLabel"
                    />
                    {props.dynamicFormValues.length > 0 && (
                      <GridInfo
                        information={props.dynamicFormValues}
                        titleInfo="summarizeLabels.summarizePersonalInfoLabel"
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResumeCardAsyncSubscription;
