import { IAppTranslation } from "../service/MultiLanguage";

export const spanish: IAppTranslation = {
  agree: ["Acepto los "],
  amountLabel: ["Monto"],
  btnLabels: {
    buttonFormLabel: ["Ir a pagar"],
    payCheckoutDesktop: ["Iniciar pago"],
    payCheckoutMobile: ["Ir al pago"],
    retry: ["Intentar nuevamente"],
  },
  cardAsyncSection: {
    email: ["Correo electrónico"],
  },
  cardCheckout: {
    cardNumber: ["Número de tarjeta"],
    CVC: ["CVC"],
    expirationDate: ["MM/AA"],
    name: ["Nombre"],
    nameLastname: ["Nombres y apellidos"],
  },
  cardSection: {
    buttonText: ["Cambiar forma de pago"],
  },
  cardSubscriptionAsync: {
    email: ["Correo electrónico"],
  },
  cashSection: {
    email: ["E-mail"],
    fieldRequired: ["* Campo obligatorio"],
    identificationNumber: ["Número de Identificación"],
    invalidEmail: ["E-mail inválido"],
    lastName: ["Apellidos"],
    name: ["Nombres"],
    payButton: ["Generar orden de pago"],
    paymentOrder: ["Su orden de pago será generada por"],
  },
  confirmationData: {
    cancelButton: ["Cancelar"],
    subscribeButton: ["Suscribirme"],
    subtitle: [
      "Por razones de seguridad necesitamos validar algunos datos adicionales pero no te preocupes esto ",
    ],
    subtitleBold: ["lo haremos una sola vez."],
    title: ["¡Un momento!"],
  },
  confrontaAuthentication: {
    CancelButton: ["Cancelar"],
    cityPlaceholder: ["Ciudad"],
    confrontaLabel: [
      "Completa la siguiente información, relacionada con el documento de identidad:",
    ],
    expeditionDatePlaceholder: ["Fecha de expedición"],
    howItWorks: ["¿Como funciona?"],
    identificationLabel: [],
    provinceLPlaceholder: ["Departamento"],
    requiredField: ["Campo requerido"],
    subscribeButton: ["Suscribirme "],
    validateData: ["Verificación de datos"],
  },
  deferredOptions: {
    months: ["Meses"],
    monthsOfGrace: ["Meses de gracia"],
    title: ["Quiero pagar en cuotas"],
    typeDeferred: ["Tipo de diferido"],
  },
  emptyInfo: ["No tienes datos de pago guardados"],
  emptyInfoButton: ["Probar con otro método de pago"],
  expiredLink: {
    description: [
      "Si necesitas comprar este servicio o producto comúnicate con nosotros.",
    ],
    title: ["Lo sentimos, este smartlink ha expirado"],
  },
  footer: {
    contacts: ["Contáctanos."],
    help: ["¿Necesitas ayuda?,"],
  },
  form: {
    additionalData: ["Datos adicionales"],
    cvcInvalid: ["* CVC inválido"],
    emailInvalid: ["* Email inválido"],
    expirationDateInvalid: ["* Fecha inválida"],
    fieldRequired: ["* Campo obligatorio"],
    fillData: ["Rellena tus datos"],
    numberCardInvalid: ["* Número de tarjeta inválido"],
    optionalField: ["(Opcional)"],
    personalData: ["Datos personales"],
    selectEmptyOption: ["Seleccionar..."],
    title: ["Formulario"],
  },
  headerText: {
    cardOrDebit: ["Tarjeta de Crédito o débito"],
    changePayment: ["Tarjeta de Crédito o Débito"],
    paymentMethods: ["Medios de pago disponibles"],
  },
  howtoWorksButton: ["¿Cómo funciona?"],
  informationConfronta: [
    "Después de validar tus datos de identidad, te mostraremos varias preguntas sobre tus datos personales y/o bancarios que debes contestar correctamente para realizar la transacción.",
  ],
  itemDescriptions: {
    makePaymentsCard: ["Realiza pagos con tu tarjeta Visa, Master o Amex"],
    makePaymentsCardAsync: ["Realiza pagos con tu tarjeta"],
    makePaymentsCardDynamic: [
      "Realiza pagos con tu Tarjeta de Crédito o Débito",
    ],
    makePaymentsCardSubscription: ["Suscribete a un plan de pagos"],
    makePaymentsCardSubscriptionAsync: ["Subscríbete con webpay"],
    makePaymentsCardSubscriptionDynamic: ["Suscribete a un plan de pagos"],
    makePaymentsCash: ["Paga en puntos de atención más cercanos a ti"],
    makePaymentsKpay: [
      "Realiza pagos rápido con los datos almacenados por K Pay",
    ],
    makePaymentsTransfer: ["Realiza pagos desde el banco de tu preferencia"],
  },
  itemTitles: {
    cardAsync: ["Tarjeta de Débito"],
    cardDynamic: ["Tarjeta de Crédito o Débito"],
    cardSubscription: ["Suscripción con Tarjeta de Crédito"],
    cardSubscriptionAsync: ["Subscripción de Tarjeta"],
    cardSubscriptionDynamic: ["Suscripción con Tarjeta de Crédito o Débito"],
    cash: ["Efectivo"],
    checkData: ["Verificación de datos"],
    creditCard: ["Tarjeta de crédito"],
    fastPay: ["Pago rápido"],
    transfer: ["Transferencia"],
    transferSubscription: ["Débito recurrente"],
  },
  kpayEmail: {
    cancel: ["Cancelar"],
    confirm: ["Confirmar"],
    edit: ["Editar"],
    invalidEmail: ["E-mail inválido"],
    placeholder: ["Email"],
    return: ["Regresar"],
    subtittle: [
      "Ingresa tu correo electrónico para una mejor experiencia de pago.",
    ],
    tittle: ["¿Tienes datos guardados?"],
  },
  kpayOtp: {
    backButtonHelper: ["Regresar"],
    changePaymentMethod: ["Usar otra forma de pago"],
    codePlaceholderHelper: ["Código de verificación"],
    confirmButtonHelper: ["Confirmar código"],
    emailCodeHelper: [
      "Hemos enviado un código de verificación a tu correo electrónico para confirmar la compra.",
    ],
    requiredField: ["Campo requerido"],
    safetyHelper: ["Nos preocupamos por la seguridad de tus datos."],
    wrongCodeError: ["El código ingresado es incorrecto, inténtalo de nuevo."],
  },
  kpayResume: {
    info: ["Datos guardados para próximas compras con"],
  },
  languages: {
    br: ["Portugués"],
    en: ["Inglés"],
    es: ["Español"],
  },
  languagesShort: {
    br: ["PR"],
    en: ["EN"],
    es: ["ES"],
  },
  listPaymentMethods: {
    expiredCardMessage: ["La tarjeta se encuentra vencida"],
    titleCard: ["Tarjeta de Crédito"],
    titleCardAndDebit: ["Tarjeta de Crédito o Débito"],
    titleCash: ["Efectivo"],
    titleOtherPay: ["Otros medios de pago"],
    titlePayMethods: ["Medios de pago"],
    titlePaySaved: ["Datos de pago guardados"],
    titleTransfer: ["Transferencia"],
  },
  loadingLabel: ["Cargando"],
  loadingOverlay: {
    mainLine: ["Un momento"],
    secondaryLine: ["Estamos procesando su pago"],
  },
  notAvail: {
    description: [
      "Si necesitas comprar este producto o servicio comunícate con nosotros.",
    ],
    title: ["Lo sentimos, este producto no esta disponible"],
  },
  notFound: {
    description: [
      "Comunícate con nosotros para compartir el enlace que necesitas para pagar",
    ],
    title: ["Lo sentimos, has usado un enlace incorrecto"],
  },
  notification: {
    confirmIdentity: [
      "No hemos podido confirmar tu identidad, inténtalo de nuevo",
    ],
  },
  openAmountMessages: {
    defaultMsg: [
      "El monto sugerido es de ${ defaultAmount } pero puedes modificarlo.",
    ],
    infoMsg: ["Puedes modificar el monto."],
    initialMsg: ["Ingrese el monto."],
    initialMsgWithDefaultAmount: ["Puedes modificar el monto sugerido."],
    maxAmountMsg: ["El monto no debe ser superior a ${ maxAmount }."],
    maxAmountMsgWithDefaultAmt: [
      "El monto no debe ser superior a ${ maxAmount }. Sugerido: ${ defaultAmount }",
    ],
    minAmountMsg: ["El monto no debe ser inferior a ${ minAmount }."],
    minAmountMsgWithDefaultAmt: [
      "El monto no debe ser inferior a ${ minAmount }. Sugerido: ${ defaultAmount }",
    ],
  },
  otpInvalidTries: {
    partFourText: ["Intentar con otra tarjeta"],
    partOneText: ["Se han producido demasiados intentos fallidos"],
    partTreeText: [
      "*Recuerda que el monto que se cargó o debitó de tu tarjeta será reversado inmediatamente",
    ],
    partTwoText: [
      "No se ha podido completar la verificación porque has fallado demasiados intentos. Vuelve a intentarlo",
    ],
  },
  otpTextVerification: {
    confirmCode: ["Confirmar código"],
    invalidCode: ["El código ingresado es inválido"],
    partOneText: ["Hemos realizado un pequeño cargo a tu tarjeta como "],
    partTwoText: [
      ", que será reversado inmediatamente. El cargo realizado cuenta con tres (3) cifras, las cuales deberás ingresar a continuación:",
    ],
    verification: ["Verificación"],
  },
  outStock: {
    description: [
      "Si necesitas comprar este servicio o producto comúnicate con nosotros.",
    ],
    title: ["Lo sentimos, nos hemos quedado sin stock"],
  },
  payButton: ["Pagar "],
  pci: [
    "Este pago es procesado de forma segura por Kushki, un proveedor de pagos certificado PCI Nivel 1.",
  ],
  previewBanner: [
    "Esta es una previsualización del smartlink que has creado, si necesitas publicarlo vuelve a la pestaña de creación y haz clic en  ",
  ],
  pse: {
    typePerson: ["Tipo de persona"],
  },
  readMore: ["Leer más"],
  recurrentDebit: {
    current: ["Corriente"],
    savings: ["Ahorros"],
    typeAccount: ["Tipo de cuenta"],
  },
  saveKpayLabel: ["Guardar datos para compras futuras con"],
  saveText: ["Guardar"],
  siftScienceForm: {
    address: ["Dirección principal"],
    city: ["Ciudad"],
    country: ["País"],
    countryCode: ["Código del País"],
    department: ["Departamento"],
    documentNumber: ["Nº de documento"],
    documentType: ["Tipo de documento"],
    email: ["Email"],
    firstName: ["Nombres"],
    lastName: ["Apellidos"],
    municipality: ["Municipio"],
    personalData: ["Datos personales"],
    phone: ["Teléfono/Móvil"],
    province: ["Provincia"],
    region: ["Estado/Provincia/Region"],
    required: ["Requerido"],
    secondaryAddress: ["Dirección secundaria (Opcional)"],
    title: ["Formulario"],
    zipCode: ["Código Postal"],
  },
  singlePayment: ["Pago único"],
  spei: {
    description: [
      "Ingresa tu e-mail y haz clic en “Generar orden de pago” para recibir los datos necesarios para realizar tu transferencia.",
    ],
    message: ["Su orden de pago será generada por "],
  },
  stepperLabels: {
    FORM: ["Datos"],
    PAY: ["Pago"],
  },
  subscription: ["Suscripción"],
  subscriptionButton: ["Subscribirme"],
  subscriptionPeriodicity: {
    bimonthly: ["Cada dos meses"],
    biweekly: ["Quincenal"],
    custom: ["Personalizada"],
    daily: ["Diaria"],
    fourmonths: ["Cuatrimestral"],
    halfYearly: ["Semestral"],
    monthly: ["Mensual"],
    quarterly: ["Trimestral"],
    threefortnights: ["Mes y Medio"],
    weekly: ["Semanal"],
    yearly: ["Anual"],
  },
  subtotal: ["Subtotal"],
  sumarizeLabelsForTransferETPAY: {
    amount: ["MONTO"],
    date: ["FECHA"],
    email: ["E-MAIL"],
    name: ["NOMBRE"],
    nit: ["NIT"],
    personalInfo: ["DATOS PERSONALES"],
    product: ["PRODUCTO"],
    status: ["ESTADO"],
    successSubTitle: ["Tu compra se ha realizado con éxito"],
    successTitle: ["Excelente"],
    summarizeTitle: ["Resumen de la compra"],
    ticketNumber: ["NÚMERO DE TICKET"],
  },
  sumarizeLabelsForTransferPSE: {
    amount: ["MONTO"],
    bank: ["BANCO"],
    cus: ["CUS"],
    date: ["FECHA"],
    email: ["E-MAIL"],
    identification: ["IDENTIFICACIÓN"],
    name: ["NOMBRE"],
    personalInfo: ["DATOS PERSONALES"],
    product: ["PRODUCTO"],
    socialReason: ["RAZÓN SOCIAL"],
    successSubTitle: ["Tu compra se ha realizado con éxito"],
    successTitle: ["Excelente"],
    summarizeTitle: ["Resumen de la compra"],
  },
  summarizeAccendo: {
    amount: ["MONTO"],
    bank: ["BANCO DESTINO"],
    beneficiaryName: ["NOMBRE DE BENEFICIARIO"],
    clabeAccount: ["Cuenta clabe"],
    personalData: ["DATOS PERSONALES"],
    product: ["PRODUCTO"],
    referenceNumber: ["No. de Referencia"],
    speiInfo: ["Información para SPEI"],
    successSubTitleBold: ["Cuenta CLABE, No. de Referencia y Monto"],
    successSubTitlePart1: [
      "Para realizar tu pago ingresa a tu banca electrónica y haz un SPEI con el número de ",
    ],
    successSubTitlePart2: [" que te mostramos a continuación:"],
    summarizeTitle: ["Compra en espera de pago"],
  },
  summarizeBankBogota: {
    successTitle: ["Excelente"],
    summarizeAmountLabel: ["MONTO"],
    summarizePersonalInfoLabel: ["DATOS PERSONALES"],
    summarizeProductLabel: ["PRODUCTO"],
    summarizeStateLabel: ["ESTADO"],
    summarizeTitle: ["Resumen de la compra"],
  },
  summarizeLabels: {
    successSubTitle: ["Tu compra se ha realizado con éxito"],
    successTitle: ["Excelente"],
    summarizeAmountLabel: ["MONTO"],
    summarizePersonalInfoLabel: ["DATOS PERSONALES"],
    summarizeProductLabel: ["PRODUCTO"],
    summarizeStateLabel: ["ESTADO"],
    summarizeTicketNumberLabel: ["NÚMERO DE TICKET"],
    summarizeTitle: ["Resumen de la compra"],
  },
  summarizeLabelsForCash: {
    downloadButtonLabel: ["Descargar orden de pago"],
    paymentCash: ["Pago en efectivo"],
    rememberMsm: [
      "Recuerda: tu compra solo será confirmada una vez que realices tu pago usando el PIN que hemos enviado a tu e-mail",
    ],
    successSubTitle: ["Hemos generado tu PIN de pago"],
    summarizePayMethodLabel: ["MÉTODO DE PAGO"],
  },
  summarizeLabelsForSubscription: {
    endDateLabel: ["Fecha de expiración"],
    numberOfFeesLabel: ["No. de Cobros"],
    paymentSubscription: ["Suscripción"],
    payMethodLabel: ["Método de pago"],
    periodicityLabel: ["Periodicidad"],
    startDateLabel: ["Fecha de inicio"],
    subscriptionIDLabel: ["Id de la suscripción"],
    successSubTitle: ["Tu suscripción se ha creado con éxito"],
    summarizeAmountLabel: ["Monto"],
    summarizeEmail: ["Correo electrónico"],
    summarizePersonalInfoLabel: ["Datos personales"],
    summarizeProductLabel: ["Producto"],
    summarizeStateLabel: ["Estado"],
    summarizeTitle: ["Detalle de la suscripción"],
    summarizeUndefinedLabel: ["Indefinido"],
  },
  summarizeLabelsForTransferSPEI: {
    amount: ["MONTO"],
    clabeAccount: ["Cuenta clabe"],
    email: ["E-MAIL"],
    paymentMethod: ["METODO DE PAGO"],
    personalData: ["DATOS PERSONALES"],
    product: ["PRODUCTO"],
    referenceNumber: ["No. de Referencia"],
    speiInfo: ["Información para SPEI"],
    successSubTitleBold: ["Cuenta CLABE, No. de Referencia y Monto"],
    successSubTitlePart1: [
      "Para realizar tu pago ingresa a tu banca electrónica y haz un SPEI con el número de ",
    ],
    successSubTitlePart2: [" que te mostramos a continuación:"],
    summarizeTitle: ["Compra en espera de pago"],
    ticketNumber: ["NÚMERO DE TICKET"],
    transaction: ["SPEI - Tranferencia Bancaria"],
  },
  taxes: ["Impuestos"],
  terms: ["Términos y Condiciones"],
  termsConditions: ["Debe aceptar los terminos y condiciones"],
  timerCover: {
    days: ["días"],
    description: ["Esta oferta termina en"],
    hours: ["horas"],
    minutes: ["minutos"],
    seconds: ["segundos"],
  },
  total: ["Total"],
  transactionFailed: {
    description: ["La transacción no se pudo completar. Intenta nuevamente."],
    title: ["Algo salió mal"],
  },
  transactionStatus: {
    approval: ["Aprobado"],
    initialized: ["Inicializado"],
  },
  transferSafetyPaySection: {
    documentNumber: ["Número de Identificación"],
    email: ["E-mail"],
    fieldRequired: ["* Campo obligatorio"],
    invalidEmail: ["E-mail inválido"],
    name: ["Nombre y apellido"],
    payButton: ["Pagar"],
  },
  transferSubscriptionCheckout: {
    accountNumber: ["Número de cuenta"],
    accountType: ["Tipo de cuenta"],
    bankId: ["Elige tu banco"],
    email: ["Correo electrónico"],
    howItWorks: ["Como funciona?"],
    identificationNumber: ["Número de identificación"],
    name: ["Nombres y apellidos"],
    phoneNumber: ["Número de teléfono"],
    subscribeButton: ["Subscribirme "],
  },
  validateData: ["Verificación de datos"],
  verificationFailed: {
    description: [
      "No se ha podido realizar la verificación de tu información.",
    ],
    descriptionSecondary: ["El pago ha sido cancelado."],
    title: ["Verificación de datos fallida"],
  },
};
