import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import TopHeader from "../../components/commons/TopHeader/TopHeader";
import ResumeCard from "../../components/Resume/ResumeCard/ResumeCard";
import ResumeCardAsync from "../../components/Resume/ResumeCardAsync/ResumeCardAsync";
import ResumeCardAsyncSubscription from "../../components/Resume/ResumeCardAsyncSubscription/ResumeCardAsyncSubscription";
import ResumeCash from "../../components/Resume/ResumeCash/ResumeCash";
import { useLocation } from "react-router-dom";
import ResumeSubscription from "../../components/Resume/ResumeSubscription/ResumeSubscription";
import { ILocationState } from "../Payment/Payment";
import { PaymentMethodsEnum } from "../../shared/infrastructure/PaymentMethodEnum";
import ResumeTransferPse from "../../components/Resume/ResumeTransfer/ResumeTransferPse/ResumeTransferPse";
import ResumeTransferSPEI from "../../components/Resume/ResumeTransfer/ResumeTransferSPEI/ResumeTransferSPEI";
import ResumeTransferETPAY from "../../components/Resume/ResumeTransfer/ResumeTransferETPAY/ResumeTransferETPAY";
import ResumeTransferSafetyPay from "../../components/Resume/ResumeTransfer/ResumeTransferSafetyPay/ResumeTransferSafetyPay";
import ResumeAccendo from "../../components/Resume/ResumeAccendo/ResumeAccendo";
import ResumeBankBogota from "../../components/Resume/ResumeBankBogota/ResumeBankBogota";
import { CustomForm } from "../../../types/smart_link_V2";
import { DynamicEnum } from "../../shared/infrastructure/DynamicEnum";
import { getSessionStorageInfo } from "../../shared/utils/utils";

export interface ResumeProps {
  dynamicFormValues: string[];
}

const Resume: React.FC = () => {
  let location = useLocation<ILocationState>();
  const methodType = location.state.paymentMethod;
  const {
    dynamicForm = JSON.stringify({}),
    formConfigString = JSON.stringify({}),
  } = getSessionStorageInfo();
  const getDynamicFormValues = (
    dataString: string,
    formConfigStrig: string
  ) => {
    let dynamicFormValues: string[] = [];

    if (formConfigStrig !== "") {
      let data = JSON.parse(dataString);
      const formConfigArray = formConfigStrig!.split(";");

      formConfigArray.forEach((configString: string) => {
        let config: CustomForm = JSON.parse(configString);

        if (config.type === DynamicEnum.DATE)
          dynamicFormValues.push(
            `${data[config.name].day}/${data[config.name].month}/${
              data[config.name].year
            }`
          );
        else if (
          config.options !== undefined &&
          config.type === DynamicEnum.CHECKBOX
        ) {
          config.options.forEach((option: string) => {
            if (data[`${config.name}${option}`])
              dynamicFormValues.push(`${option}`);
          });
        } else {
          dynamicFormValues.push(`${data[config.name]}`);
        }
      });
    }

    return dynamicFormValues;
  };

  const resumeProps: ResumeProps = {
    dynamicFormValues: getDynamicFormValues(dynamicForm, formConfigString),
  };

  const renderResume = (paymentMethod: PaymentMethodsEnum) => {
    switch (paymentMethod) {
      case PaymentMethodsEnum.CASH:
        return <ResumeCash {...resumeProps} />;
      case PaymentMethodsEnum.CARD:
        return <ResumeCard {...resumeProps} />;
      case PaymentMethodsEnum.TRANSFER_ETPAY:
        return <ResumeTransferETPAY {...resumeProps} />;
      case PaymentMethodsEnum.TRANSFER_SAFETYPAY:
        return <ResumeTransferSafetyPay {...resumeProps} />;
      case PaymentMethodsEnum.TRANSFER_SPEI:
        return <ResumeTransferSPEI {...resumeProps} />;
      case PaymentMethodsEnum.TRANSFER_PSE:
        return <ResumeTransferPse {...resumeProps} />;
      case PaymentMethodsEnum.SUBSCRIPTION:
        return <ResumeSubscription {...resumeProps} />;
      case PaymentMethodsEnum.CARD_ASYNC:
        return <ResumeCardAsync {...resumeProps} />;
      case PaymentMethodsEnum.CARD_BANK_ACCENDO:
        return <ResumeAccendo {...resumeProps} />;
      case PaymentMethodsEnum.TRANSFER_SUBSCRIPTION:
        return <ResumeBankBogota {...resumeProps} />;
      case PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC:
        return <ResumeCardAsyncSubscription {...resumeProps} />;
      default:
        return <div />;
    }
  };

  return (
    <Container maxWidth={"lg"}>
      <Box pb={11}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TopHeader />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} lg={12}>
              {renderResume(methodType)}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Resume;
