export enum CurrencyEnum {
  USD = "USD",
  COP = "COP",
  PEN = "PEN",
  CLP = "CLP",
  UF = "UF",
  MXN = "MXN",
  CRC = "CRC",
  GTQ = "GTQ",
  HNL = "HNL",
  NIO = "NIO",
  PAB = "PAB",
}
