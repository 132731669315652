import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useIndex } from "./useIndex/useIndex";

const Index: React.FC = () => {
  useIndex();

  return (
    <Box
      display={"flex"}
      width={"100%"}
      height={"100%"}
      position={"absolute"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CircularProgress />
    </Box>
  );
};

export default Index;
