import { Kushki } from "@kushki/js";
import { environment } from "../../environments/environment";

export class KushkiJs {
  private static instance: Kushki;

  constructor(merchantId: string) {
    KushkiJs.instance = new Kushki({
      inTestEnvironment: environment.envName !== "primary",
      merchantId: merchantId,
    });
  }

  public static getKushkiJs() {
    return KushkiJs.instance!;
  }
}

export default KushkiJs.getKushkiJs;
