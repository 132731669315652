import { IAppTranslation } from "../service/MultiLanguage";

export const english: IAppTranslation = {
  agree: ["Agree with "],
  amountLabel: ["Amount"],
  btnLabels: {
    buttonFormLabel: ["Go pay"],
    payCheckoutDesktop: ["Start payment"],
    payCheckoutMobile: ["Go to payment"],
    retry: ["Try again"],
  },
  cardAsyncSection: {
    email: ["Email"],
  },
  cardCheckout: {
    cardNumber: ["Card number"],
    CVC: ["CVC"],
    expirationDate: ["MM/YY"],
    name: ["Name"],
    nameLastname: ["Names and surnames"],
  },
  cardSection: {
    buttonText: ["Change payment method"],
  },
  cardSubscriptionAsync: {
    email: ["Email"],
  },
  cashSection: {
    email: ["E-mail"],
    fieldRequired: ["* This field is required"],
    identificationNumber: ["ID Number"],
    invalidEmail: ["Invalid email"],
    lastName: ["Last name"],
    name: ["Name"],
    payButton: ["Generate payment order"],
    paymentOrder: ["Your payment order will be generated by"],
  },
  confirmationData: {
    cancelButton: ["Cancel"],
    subscribeButton: ["Subscribe"],
    subtitle: [
      "For security reasons we need to validate some additional data but don't worry ",
    ],
    subtitleBold: ["we will do it only once."],
    title: ["¡One moment!"],
  },
  confrontaAuthentication: {
    CancelButton: ["Cancel"],
    cityPlaceholder: ["City"],
    confrontaLabel: [
      "Complete the following information, related to the identity document:",
    ],
    expeditionDatePlaceholder: ["Expedition date"],
    howItWorks: ["How does it work?"],
    identificationLabel: [],
    provinceLPlaceholder: ["Department"],
    requiredField: ["Required field"],
    subscribeButton: ["Subscribe "],
    validateData: ["Data verification"],
  },
  deferredOptions: {
    months: ["Months"],
    monthsOfGrace: ["Months of grace"],
    title: ["I want to pay in installments"],
    typeDeferred: ["Deferred type"],
  },
  emptyInfo: ["You do not have payment data saved"],
  emptyInfoButton: ["Try another payment method"],
  expiredLink: {
    description: ["If you need to buy this service or product, contact us."],
    title: ["Sorry, this smartlink has expired"],
  },
  footer: {
    contacts: ["Contact us."],
    help: ["¿Need help?,"],
  },
  form: {
    additionalData: ["Additional data"],
    cvcInvalid: ["* Invalid CVC"],
    emailInvalid: ["* Invalid email"],
    expirationDateInvalid: ["* Invalid date"],
    fieldRequired: ["* This field is required"],
    fillData: ["Fill your data"],
    numberCardInvalid: ["* Invalid card number"],
    optionalField: ["(Optional)"],
    personalData: ["Personal data"],
    selectEmptyOption: ["Select..."],
    title: ["Form"],
  },
  headerText: {
    cardOrDebit: ["Credit or debit card"],
    changePayment: ["Credit or debit card"],
    paymentMethods: ["Available payment methods"],
  },
  howtoWorksButton: ["How to works?"],
  informationConfronta: [
    "After validating your identity data, we will show you several questions about your personal and / or banking data that you must answer correctly to carry out the transaction.",
  ],
  itemDescriptions: {
    makePaymentsCard: ["Make payments with your Visa, Master or Amex card"],
    makePaymentsCardAsync: ["Make payments with your card"],
    makePaymentsCardDynamic: ["Make payments with your credit or debit card"],
    makePaymentsCardSubscription: ["Subscribe to a payment plan"],
    makePaymentsCardSubscriptionAsync: ["Subscribe with webpay"],
    makePaymentsCardSubscriptionDynamic: ["Subscribe to a payment plan"],
    makePaymentsCash: ["Pay at service points closest to you"],
    makePaymentsKpay: ["Make fast payments with the data stored by K Pay"],
    makePaymentsTransfer: ["Make payments from the bank of your choice"],
  },
  itemTitles: {
    cardAsync: ["Debit Card"],
    cardDynamic: ["Credit or debit card"],
    cardSubscription: ["Credit Card Subscription"],
    cardSubscriptionAsync: ["Card Subscription"],
    cardSubscriptionDynamic: ["Credit Card Subscription"],
    cash: ["Cash"],
    checkData: ["Data verification"],
    creditCard: ["Credit card"],
    fastPay: ["Quick pay"],
    transfer: ["Transfer"],
    transferSubscription: ["Recurring debit"],
  },
  kpayEmail: {
    cancel: ["Cancelar"],
    confirm: ["Confirm"],
    edit: ["Edit"],
    invalidEmail: ["Invalid email"],
    placeholder: ["Email"],
    return: ["Return"],
    subtittle: ["Enter your email for a better payment experience"],
    tittle: ["Do you have saved data?"],
  },
  kpayOtp: {
    backButtonHelper: ["Go back"],
    changePaymentMethod: ["Change payment method"],
    codePlaceholderHelper: ["Verification code"],
    confirmButtonHelper: ["Confirm code"],
    emailCodeHelper: [
      "We have sent a verification code to your email to confirm the purchase.",
    ],
    requiredField: ["Required field"],
    safetyHelper: ["We care about the security of your data."],
    wrongCodeError: ["The code entered is incorrect, please try again."],
  },
  kpayResume: {
    info: ["Data saved for future purchases with"],
  },
  languages: {
    br: ["Portuguese"],
    en: ["English"],
    es: ["Spanish"],
  },
  languagesShort: {
    br: ["PR"],
    en: ["EN"],
    es: ["ES"],
  },
  listPaymentMethods: {
    expiredCardMessage: ["The card is expired"],
    titleCard: ["Credit Card"],
    titleCardAndDebit: ["Debit or credit card"],
    titleCash: ["Cash"],
    titleOtherPay: ["Other payment methods "],
    titlePayMethods: ["Payment methods"],
    titlePaySaved: ["These are your previously saved payment methods"],
    titleTransfer: ["Transfer"],
  },
  loadingLabel: ["Loading"],
  loadingOverlay: {
    mainLine: ["Please wait "],
    secondaryLine: ["We are processing your payment request"],
  },
  notAvail: {
    description: ["If you need to buy this product or service, contact us."],
    title: ["Sorry, this product is not available"],
  },
  notFound: {
    description: ["Contact us to share the link you need to pay"],
    title: ["Sorry, you used the wrong link"],
  },
  notification: {
    confirmIdentity: ["We could not confirm your identity, please try again"],
  },
  openAmountMessages: {
    defaultMsg: [
      "The suggested amount is ${ defaultAmount } but you can modify it.",
    ],
    infoMsg: ["You can modify the amount"],
    initialMsg: ["Enter the amount."],
    initialMsgWithDefaultAmount: ["You can modify the suggested amount."],
    maxAmountMsg: [
      "The amount should not be superior to the maximum defined amount.",
    ],
    maxAmountMsgWithDefaultAmt: [
      "The amount should not be superior to the maximum defined amount. Suggested: ${ defaultAmount }",
    ],
    minAmountMsg: [
      "The amount should not be lower than the minimum defined amount.",
    ],
    minAmountMsgWithDefaultAmt: [
      "The amount should not be lower than the minimum defined amount. Suggested: ${ defaultAmount }",
    ],
  },
  otpInvalidTries: {
    partFourText: ["Try another card"],
    partOneText: ["There have been too many unsuccessful attempts"],
    partTreeText: [
      "*Remember that the amount that was loaded or debited from your card will be reversed immediately",
    ],
    partTwoText: [
      "Verification could not be completed because you have failed too many attempts. Retry",
    ],
  },
  otpTextVerification: {
    confirmCode: ["Confirm code"],
    invalidCode: ["Invalid code"],
    partOneText: ["We have made a charge to your card as "],
    partTwoText: [
      ", which will be reimbursed inmediately. The amount has 3 digits, please enter them below:",
    ],
    verification: ["Verification"],
  },
  outStock: {
    description: ["If you need to buy this service or product, contact us."],
    title: ["Sorry, we are out of stock"],
  },
  payButton: ["Pay "],
  pci: [
    "This payment is processed securely by Kushki, a PCI Level 1 certified payment provider.",
  ],
  previewBanner: [
    "This is a preview of the smartlink you have created, if you need to publish it, go back to the creation tab and click  ",
  ],
  pse: {
    typePerson: ["Kind of person"],
  },
  readMore: ["Read more"],
  recurrentDebit: {
    current: ["Current"],
    savings: ["Savings"],
    typeAccount: ["Account type"],
  },
  saveKpayLabel: ["Save data for future purchases with"],
  saveText: ["Save"],
  siftScienceForm: {
    address: ["Main address"],
    city: ["City"],
    country: ["Country"],
    countryCode: ["Country code"],
    department: ["Department"],
    documentNumber: ["Document number"],
    documentType: ["Document type"],
    email: ["Email"],
    firstName: ["First name"],
    lastName: ["Last name"],
    municipality: ["Municipality"],
    personalData: ["Personal data"],
    phone: ["Phone number"],
    province: ["Province"],
    region: ["State/Province/Region"],
    required: ["Required"],
    secondaryAddress: ["Secondary  address (Optional)"],
    title: ["Form"],
    zipCode: ["Zip code"],
  },
  singlePayment: ["Single Payment"],
  spei: {
    description: [
      "Enter your email and click on “Generate payment order“ to receive the necessary data to make your transfer.",
    ],
    message: ["Su orden de pago será generada por "],
  },
  stepperLabels: {
    FORM: ["Data"],
    PAY: ["Pay"],
  },
  subscription: ["Subscription"],
  subscriptionButton: ["Subscribe"],
  subscriptionPeriodicity: {
    bimonthly: ["Bimonthly"],
    biweekly: ["Biweekly"],
    custom: ["Custom"],
    daily: ["Daily"],
    fourmonths: ["Fourmonths"],
    halfYearly: ["Half-yearly"],
    monthly: ["Monthly"],
    quarterly: ["Quarterly"],
    threefortnights: ["Month and a half"],
    weekly: ["Weekly"],
    yearly: ["Yearly"],
  },
  subtotal: ["Subtotal"],
  sumarizeLabelsForTransferETPAY: {
    amount: ["AMOUNT"],
    date: ["DATE"],
    email: ["E-MAIL"],
    name: ["NAME"],
    nit: ["NIT"],
    personalInfo: ["PERSONAL INFO"],
    product: ["PRODUCT"],
    status: ["STATUS"],
    successSubTitle: ["Your purchase has been successful"],
    successTitle: ["Excellent"],
    summarizeTitle: ["Purchase summary"],
    ticketNumber: ["TICKET NUMBER"],
  },
  sumarizeLabelsForTransferPSE: {
    amount: ["AMOUNT"],
    bank: ["BANK"],
    cus: ["CUS"],
    date: ["DATE"],
    email: ["E-MAIL"],
    identification: ["IDENTIFICATION"],
    name: ["NAME"],
    personalInfo: ["PERSONAL INFO"],
    product: ["PRODUCT"],
    socialReason: ["SOCIAL REASON"],
    successSubTitle: ["Your purchase has been successful"],
    successTitle: ["Excellent"],
    summarizeTitle: ["Purchase summary"],
  },
  summarizeAccendo: {
    amount: ["AMOUNT"],
    bank: ["DESTINATION BANK"],
    beneficiaryName: ["BENEFICIARY NAME"],
    clabeAccount: ["Clabe Account"],
    personalData: ["PERSONAL INFO"],
    product: ["PRODUCT"],
    referenceNumber: ["REFERENCE NUMBER"],
    speiInfo: ["Information for SPEI"],
    successSubTitleBold: ["CLABE account number, reference number and amount"],
    successSubTitlePart1: [
      "To make your payment, enter to your electronic banking and make a SPEI with the ",
    ],
    successSubTitlePart2: [" that we show you below:"],
    summarizeTitle: ["Purchase awaiting payment"],
  },
  summarizeBankBogota: {
    successTitle: ["Excellent"],
    summarizeAmountLabel: ["AMOUNT"],
    summarizePersonalInfoLabel: ["PERSONAL INFO"],
    summarizeProductLabel: ["PRODUCT"],
    summarizeStateLabel: ["State"],
    summarizeTitle: ["Summary of Purchase"],
  },
  summarizeLabels: {
    successSubTitle: ["Your purchase has been successful"],
    successTitle: ["Excellent"],
    summarizeAmountLabel: ["AMOUNT"],
    summarizePersonalInfoLabel: ["PERSONAL INFO"],
    summarizeProductLabel: ["PRODUCT"],
    summarizeStateLabel: ["STATE"],
    summarizeTicketNumberLabel: ["TICKET NUMBER"],
    summarizeTitle: ["Summary of Purchase"],
  },
  summarizeLabelsForCash: {
    downloadButtonLabel: ["Download pay order"],
    paymentCash: ["Cash payment"],
    rememberMsm: [
      "Remember: your purchase will only be confirmed once you make your payment using the PIN that we have sent to your e-mail",
    ],
    successSubTitle: ["We have generated your payment PIN"],
    summarizePayMethodLabel: ["PAYMENT METHOD"],
  },
  summarizeLabelsForSubscription: {
    endDateLabel: ["Expiration date"],
    numberOfFeesLabel: ["Number of fees"],
    paymentSubscription: ["Subscription"],
    payMethodLabel: ["Payment method"],
    periodicityLabel: ["Periodicity"],
    startDateLabel: ["Start date"],
    subscriptionIDLabel: ["Subscription Id"],
    successSubTitle: ["Your subscription has been created successfully"],
    summarizeAmountLabel: ["Amount"],
    summarizeEmail: ["E-mail"],
    summarizePersonalInfoLabel: ["Personal Info"],
    summarizeProductLabel: ["Product"],
    summarizeStateLabel: ["State"],
    summarizeTitle: ["Subscription Detail"],
    summarizeUndefinedLabel: ["Undefined"],
  },
  summarizeLabelsForTransferSPEI: {
    amount: ["AMOUNT"],
    clabeAccount: ["Clabe Account"],
    email: ["E-MAIL"],
    paymentMethod: ["PAYMENT METHOD"],
    personalData: ["PERSONAL DATA"],
    product: ["PRODUCT"],
    referenceNumber: ["REFERENCE NUMBER"],
    speiInfo: ["Information for SPEI"],
    successSubTitleBold: ["CLABE account number, reference number and amount"],
    successSubTitlePart1: [
      "To make your payment, enter to your electronic banking and make a SPEI with the ",
    ],
    successSubTitlePart2: [" that we show you below:"],
    summarizeTitle: ["Purchase awaiting payment"],
    ticketNumber: ["TICKET NUMBER"],
    transaction: ["SPEI - Bank transfer"],
  },
  taxes: ["Taxes"],
  terms: ["Terms and Conditions"],
  termsConditions: ["Debe aceptar los terminos y condiciones"],
  timerCover: {
    days: ["days"],
    description: ["This offer expires in"],
    hours: ["hours"],
    minutes: ["minutes"],
    seconds: ["seconds"],
  },
  total: ["Total"],
  transactionFailed: {
    description: ["The transaction could not be completed. Try again."],
    title: ["Something went wrong"],
  },
  transactionStatus: {
    approval: ["Approved"],
    initialized: ["Initialized"],
  },
  transferSafetyPaySection: {
    documentNumber: ["ID Number"],
    email: ["E-mail"],
    fieldRequired: ["* This field is required"],
    invalidEmail: ["Invalid email"],
    name: ["Name and last name"],
    payButton: ["Pay"],
  },
  transferSubscriptionCheckout: {
    accountNumber: ["Account number"],
    accountType: ["Account type"],
    bankId: ["Choose your bank"],
    email: ["Email"],
    howItWorks: ["How it works"],
    identificationNumber: ["Identification number"],
    name: ["Names and surnames"],
    phoneNumber: ["Phone number"],
    subscribeButton: ["Subscribe  "],
  },
  validateData: ["Data verification"],
  verificationFailed: {
    description: ["Your information could not be verified."],
    descriptionSecondary: ["The payment has been canceled. "],
    title: ["Failed data verification"],
  },
};
