import { useDispatch, useSelector } from "react-redux";
import { PaymentMethodsEnum } from "../../../../shared/infrastructure/PaymentMethodEnum";
import {
  calculateValuesForOpenAmount,
  getCurrencySymbol,
  orderPaymentMethods,
} from "../../../../shared/utils/utils";
import { IAppState } from "../../../../store/reducer";
import { IAmount } from "../../../../shared/infrastructure/IAmount";
import {
  setAmount,
  setCheckoutPaymentType,
  setDisablePayButton,
  setPaymentMethods,
  setTermsAndConditions,
} from "../../../../store/actionCreators";
import * as React from "react";
import { PaymentTypeEnum } from "../../../../shared/infrastructure/PaymentTypeEnum";
import {
  Amount,
  PaymentConfigTypeFixed,
  PaymentConfigTypeOpen,
  SmartLink,
} from "../../../../../types/smart_link_V2";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { PaymentConfigTypeEnum } from "../../../../shared/infrastructure/PaymentConfigTypeEnum";
import { Translate as T } from "react-localize-redux";

export interface IUseTotal {
  paymentType: PaymentTypeEnum;
  amountData: IAmount;
  paymentTypeSmarlink: string;
  handler: {
    onChange: (
      event: React.MouseEvent<HTMLElement>,
      value: PaymentTypeEnum
    ) => void;
    onChangeAmount: (newAmount: string) => void;
  };
  paymentConfigType: "fixed" | "open";
  paymentConfig: PaymentConfigTypeFixed | PaymentConfigTypeOpen;
  amountError: boolean;
  amountMessage: JSX.Element;
  currencySymbol: string;
  smartlink: SmartLink | undefined;
  newTotalAmount: string;
}
const useSmartlinkDetailTotal = (): IUseTotal => {
  const amountData = useSelector((state: IAppState) => state.amount!);
  const paymentType = useSelector(
    (state: IAppState) => state.checkoutPaymentType!
  );
  const smartlink = useSelector((state: IAppState) => state.smartlink);
  const termsDefaultConfiguration = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo
  );
  const dispatch = useDispatch();
  const paymentTypeSmarlink = smartlink!.paymentConfig
    .paymentType as PaymentTypeEnum;
  const paymentMethod = smartlink?.paymentConfig.paymentMethod;
  const paymentMethodsSmartlink = Array.isArray(paymentMethod)
    ? paymentMethod
    : [paymentMethod];
  const paymentConfigType: "fixed" | "open" = get(
    smartlink,
    "paymentConfigType",
    "fixed"
  );
  const paymentConfig: PaymentConfigTypeFixed | PaymentConfigTypeOpen =
    smartlink!.paymentConfig;
  const [amountError, setAmountError] = useState<boolean>(false);
  const [amountMessage, setAmountMessage] = useState<JSX.Element>(
    paymentConfig.defaultAmount ? (
      <T id="openAmountMessages.initialMsgWithDefaultAmount" />
    ) : (
      <T id="openAmountMessages.initialMsg" />
    )
  );
  const [newTotalAmount, setNewTotalAmount] = useState<string>(
    paymentConfig.defaultAmount
  );

  const currencySymbol: string = getCurrencySymbol(
    get(paymentConfig, "currency", "")
  );

  const onchange = (
    event: React.MouseEvent<HTMLElement>,
    value: PaymentTypeEnum
  ) => {
    const getTotalAmount = (smartLinkAmount: Amount) => {
      const taxes: number =
        smartLinkAmount.iva +
        (smartLinkAmount.ice !== undefined ? smartLinkAmount.ice : 0);
      const sub_total: number =
        smartLinkAmount.subtotalIva + smartLinkAmount.subtotalIva0;

      return {
        currency: smartLinkAmount.currency,
        openSubtotalIva: smartLinkAmount.subtotalIva,
        openSubtotalIva0: smartLinkAmount.subtotalIva0,
        smartLinkAmount,
        subTotal: sub_total,
        taxes,
        total: taxes + sub_total,
      };
    };

    let smartLinkAmount: Amount;

    switch (value) {
      case PaymentTypeEnum.UNIQUE:
        const paymentConfig = smartlink!.paymentConfig;

        smartLinkAmount = paymentConfig.amount;

        dispatch(setAmount(getTotalAmount(smartLinkAmount)));
        dispatch(
          setTermsAndConditions(
            !!smartlink!.generalConfig.termsAndConditions!
              ? smartlink!.generalConfig.termsAndConditions!
              : get(termsDefaultConfiguration, "termsAndConditions", "")
          )
        );
        dispatch(setCheckoutPaymentType(PaymentTypeEnum.UNIQUE));
        const filteredMethods = (paymentMethodsSmartlink as string[]).filter(
          (method) =>
            !(
              method === PaymentMethodsEnum.SUBSCRIPTION ||
              method === PaymentMethodsEnum.TRANSFER_SUBSCRIPTION ||
              method === PaymentMethodsEnum.CARD_SUBSCRIPTION_DYNAMIC ||
              method === PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC
            )
        );

        dispatch(setPaymentMethods(orderPaymentMethods(filteredMethods)));
        break;
      case PaymentTypeEnum.SUBSCRIPTION:
        const subscriptionOptions =
          smartlink!.paymentConfig.subscriptionOptions!;
        const subscriptionMethods = (
          paymentMethodsSmartlink as string[]
        ).filter(
          (method) =>
            method === PaymentMethodsEnum.SUBSCRIPTION ||
            method === PaymentMethodsEnum.TRANSFER_SUBSCRIPTION ||
            method === PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC ||
            method === PaymentMethodsEnum.CARD_SUBSCRIPTION_DYNAMIC
        );

        smartLinkAmount = subscriptionOptions.amount;
        dispatch(setAmount(getTotalAmount(smartLinkAmount)));
        dispatch(
          setTermsAndConditions(
            !!subscriptionOptions.terms
              ? subscriptionOptions.terms
              : get(termsDefaultConfiguration, "termsAndConditions", "")
          )
        );
        dispatch(setCheckoutPaymentType(PaymentTypeEnum.SUBSCRIPTION));
        dispatch(setPaymentMethods(subscriptionMethods));
        break;
    }
  };

  const onChangeAmount = (newAmount: string): void => {
    const suggestedAmount: string = paymentConfig.defaultAmount
      ? ` ${currencySymbol}${paymentConfig.defaultAmount}`
      : "";
    const minAmount: string = paymentConfig.minAmount
      ? ` ${currencySymbol}${paymentConfig.minAmount}`
      : "";
    const maxAmount: string = paymentConfig.maxAmount
      ? ` ${currencySymbol}${paymentConfig.maxAmount}`
      : "";

    setNewTotalAmount(newAmount);
    if (parseInt(newAmount) <= 0 || isNaN(parseInt(newAmount))) {
      setAmountMessage(<T id="openAmountMessages.infoMsg" />);
      setAmountError(true);
      dispatch(setDisablePayButton(true));
    } else {
      if (paymentConfig.minAmount && +newAmount < paymentConfig.minAmount) {
        setAmountError(true);
        setAmountMessage(
          paymentConfig.defaultAmount ? (
            <T
              id="openAmountMessages.minAmountMsgWithDefaultAmt"
              data={{ defaultAmount: suggestedAmount, minAmount }}
            />
          ) : (
            <T id="openAmountMessages.minAmountMsg" data={{ minAmount }} />
          )
        );
        dispatch(setDisablePayButton(true));
      } else if (
        paymentConfig.maxAmount &&
        +newAmount > paymentConfig.maxAmount
      ) {
        setAmountError(true);
        setAmountMessage(
          paymentConfig.defaultAmount ? (
            <T
              id="openAmountMessages.maxAmountMsgWithDefaultAmt"
              data={{ defaultAmount: suggestedAmount, maxAmount }}
            />
          ) : (
            <T id="openAmountMessages.maxAmountMsg" data={{ maxAmount }} />
          )
        );
        dispatch(setDisablePayButton(true));
      } else {
        dispatch(
          setAmount(
            calculateValuesForOpenAmount(
              paymentConfig as PaymentConfigTypeOpen,
              +newAmount
            )
          )
        );
        setAmountError(false);
        dispatch(setDisablePayButton(false));
        if (paymentConfig.defaultAmount)
          setAmountMessage(
            <T
              id="openAmountMessages.defaultMsg"
              data={{ defaultAmount: suggestedAmount }}
            />
          );
        else setAmountMessage(<T id="openAmountMessages.infoMsg" />);
      }
    }
  };

  useEffect(() => {
    if (paymentConfigType === PaymentConfigTypeEnum.OPEN) {
      dispatch(
        setAmount(
          calculateValuesForOpenAmount(paymentConfig as PaymentConfigTypeOpen)
        )
      );
      if (amountData.total <= 0) {
        dispatch(setDisablePayButton(true));
      }
    }
  }, []);

  useEffect(() => {
    if (amountError) {
      dispatch(
        setAmount(
          calculateValuesForOpenAmount(
            paymentConfig as PaymentConfigTypeOpen,
            0
          )
        )
      );
    }
  }, [amountError]);

  return {
    amountData,
    amountError,
    amountMessage,
    currencySymbol,
    handler: { onChange: onchange, onChangeAmount },
    newTotalAmount,
    paymentConfig,
    paymentConfigType,
    paymentType,
    paymentTypeSmarlink,
    smartlink,
  };
};

export { useSmartlinkDetailTotal };
