import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { Box, Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PoweredByKushkiLogo from "../../../assets/logos/powered_by_kushki_logo.svg";
import { useStateApp } from "../../../state/useStateApp";
import SelectLanguage from "../Language/SelectLanguage/SelectLanguage";
import KushkiLogo from "../../../assets/logos/kushki_logo.svg";

const useStyles = makeStyles(() => ({
  box: {
    paddingRight: 0,
  },
  boxLogo: {
    paddingLeft: 0,
  },
  container: {
    marginBottom: 15,
    marginTop: 15,
  },
  poweredLogo: {
    marginRight: "32px",
  },
  poweredLogoMobile: {
    marginLeft: "2px",
    marginRight: "0px",
  },
  productLogoImage: {
    height: 47,
    marginLeft: 10,
    objectFit: "contain",
  },
}));

const TopHeader: React.FC = () => {
  const classes = useStyles();
  const smartlink = useSelector((state: IAppState) => state.smartlink!);
  const { isMobile } = useStateApp();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexGrow={1}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            pl={isMobile ? 0 : 16}
            ml={isMobile ? 0 : 1}
            className={classes.boxLogo}
          >
            {smartlink && (
              <img
                src={smartlink?.generalConfig.brandLogo}
                alt={"product-logo"}
                className={classes.productLogoImage}
              />
            )}
            {!smartlink && (
              <img
                src={KushkiLogo}
                alt={"product-logo"}
                className={classes.productLogoImage}
              />
            )}
          </Box>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box
              mr={isMobile ? 0 : 2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              pr={isMobile ? 0 : 16}
              className={classes.box}
            >
              <img
                src={PoweredByKushkiLogo}
                alt={"kushki-logo"}
                className={
                  isMobile ? classes.poweredLogoMobile : classes.poweredLogo
                }
              />
              {!isMobile && (
                <Divider orientation={"vertical"} color={"#CBD5E0"} flexItem />
              )}
            </Box>
            {!isMobile && (
              <Box
                ml={2}
                mr={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <SelectLanguage />
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TopHeader;
