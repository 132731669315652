enum StatusEnum {
  APPROVAL = "APPROVAL",
  CALLBACK_APPROVED = "approvedTransaction",
  INITIALIZED = "initializedTransaction",
  CREATED = "createdTransaction",
  INITIALIZED_CONFRONTA = "INITIALIZED",
}

const StatusLabels: Record<StatusEnum | string, string> = {
  [StatusEnum.APPROVAL]: "transactionStatus.approval",
  [StatusEnum.INITIALIZED]: "transactionStatus.approval",
  [StatusEnum.CREATED]: "transactionStatus.approval",
  [StatusEnum.CALLBACK_APPROVED]: "transactionStatus.approval",
  [StatusEnum.INITIALIZED_CONFRONTA]: "transactionStatus.approval",
};

export { StatusEnum, StatusLabels };
