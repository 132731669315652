import { ICountriesISOCode } from "../interfaces/CountriesISOCode.interfaces";

export enum ISOCountryEnum {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRASIL = "Brasil",
  ELSALVADOR = "ElSalvador",
  PANAMA = "Panama",
  COSTARICA = "CostaRica",
  NICARAGUA = "Nicaragua",
  HONDURAS = "Honduras",
  GUATEMALA = "Guatemala",
}

export const COUNTRY_ISO_CODE: ICountriesISOCode = {
  [ISOCountryEnum.ECUADOR]: "EC",
  [ISOCountryEnum.COLOMBIA]: "CO",
  [ISOCountryEnum.PERU]: "PE",
  [ISOCountryEnum.CHILE]: "CL",
  [ISOCountryEnum.MEXICO]: "MX",
  [ISOCountryEnum.BRASIL]: "BR",
  [ISOCountryEnum.ELSALVADOR]: "SV",
  [ISOCountryEnum.PANAMA]: "PA",
  [ISOCountryEnum.COSTARICA]: "CR",
  [ISOCountryEnum.NICARAGUA]: "NI",
  [ISOCountryEnum.HONDURAS]: "HN",
  [ISOCountryEnum.GUATEMALA]: "GT",
};
