/* istanbul ignore file */
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { StepperPaymentProvider } from "./components/Payment/StepperPayment/context/useStepperPaymentContext";
import ResumeCallback from "./containers/ResumeCallback/ResumeCallback";
import {
  availableLanguages,
  getAppTranslations,
} from "./shared/service/MultiLanguage";
import { renderToStaticMarkup } from "react-dom/server";
import { LanguageEnum } from "./shared/infrastructure/LanguageEnum";
import { RouteEnum } from "./shared/infrastructure/RouteEnum";
import Payment from "./containers/Payment/Payment";
import Index from "./containers/Index/Index";
import Cover from "./containers/Cover/Cover";
import ErrorIndex from "./containers/ErrorIndex/ErrorIndex";
import Footer from "./components/commons/Footer/Footer";
import Resume from "./containers/Resume/Resume";
import { Provider as RollbarProvider } from "@rollbar/react";
import Rollbar from "rollbar";
import packageJson from "../package.json";
import { environment } from "./environments/environment";

const useLocalize = (props: LocalizeContextProps) => {
  useEffect(() => {
    props.initialize({
      languages: availableLanguages,
      options: { renderToStaticMarkup },
      translation: getAppTranslations(),
    });
    props.setActiveLanguage(LanguageEnum.SPANISH);
  }, []);
};

export function App(props: LocalizeContextProps) {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: environment.rollbarId,
    addErrorContext: true,
    autoInstrument: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: environment.envName,
    payload: {
      client: {
        javascript: {
          code_version: packageJson.dependencies["@kushki/js"],
          source_map_enabled: true,
        },
      },
      environment: environment.envName,
    },
  };

  useLocalize(props);

  return (
    <RollbarProvider config={rollbarConfig}>
      <BrowserRouter>
        <Switch>
          <Route path={RouteEnum.CALLBACK} component={ResumeCallback} />
          <Route path={RouteEnum.ERROR_TEMPLATE} component={ErrorIndex} />
          <Route path={RouteEnum.COVER} component={Cover} />
          <Route
            path={RouteEnum.CHECKOUT}
            render={() => (
              <StepperPaymentProvider>
                <Payment />
              </StepperPaymentProvider>
            )}
          />
          <Route path={RouteEnum.TRANSACTION_RESUME} component={Resume} />
          <Route path={RouteEnum.PREVIEW} component={Index} />
          <Route path={RouteEnum.INDEX} component={Index} />
          <Route path={RouteEnum.INDEX_V1} component={Index} />
          <Route>
            <Redirect to={RouteEnum.NOT_FOUND} />
          </Route>
        </Switch>
        <Footer />
      </BrowserRouter>
    </RollbarProvider>
  );
}

export default withLocalize(App);
