import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { IAppState } from "../../../store/reducer";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Translate as T } from "react-localize-redux";
import { GeneralConfig } from "../../../../types/smart_link_V2";

const useStyles = makeStyles((theme: Theme) => ({
  cardStyle: {
    padding: "14px",
    paddingBottom: "14px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "7px",
      paddingBottom: "7px !important",
    },
  },
  timerDescriptionStyle: {
    ...theme.typography.subtitle1,
    color: "#293036",
    fontWeight: "lighter",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      lineHeight: "136%",
    },
  },
  timerDotStyle: {
    alignItems: "center",
    color: "#293036",
    display: "flex",
    fontSize: "24px",
    fontWeight: 500,
    height: "100%",
    justifyContent: "center",
    letterSpacing: "0.03em",
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "140%",
    },
  },
  timerNumberStyle: {
    color: "#293036",
    fontSize: "24px",
    fontWeight: 500,
    letterSpacing: "0.03em",
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  timerTextStyle: {
    color: "#293036",
    fontSize: "7px",
    fontWeight: 500,
    lineHeight: "150%",
    textTransform: "uppercase",
  },
}));

export const TimerDot: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item xs={1}>
      <Grid container alignItems="center" justify="center">
        <span className={classes.timerDotStyle}>:</span>
      </Grid>
    </Grid>
  );
};

interface UnitTimeProps {
  value: string;
  children?: JSX.Element;
}

interface TimeType {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export const UnitTime = (props: UnitTimeProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid xs={2} item>
      <Grid container alignItems="center" direction="column" justify="center">
        <Grid item>
          <Typography className={classes.timerNumberStyle}>
            {props.value}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.timerTextStyle}>
            {props.children}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TimerCover: React.FC = (): JSX.Element | null => {
  const classes = useStyles();
  const history = useHistory();
  const smartlink = useSelector((state: IAppState) => state.smartlink);
  const [time, setTime] = useState<TimeType | undefined>();

  let generalConfig: GeneralConfig;

  if (smartlink) generalConfig = smartlink.generalConfig;

  const checkToAddZero = (numberString: string): string => {
    return numberString.length === 1 ? `0${numberString}` : numberString;
  };

  const calculateTimeLeft = (date: number): TimeType | undefined => {
    const difference = +new Date(date) - +new Date();

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return {
        days: checkToAddZero(days.toString()),
        hours: checkToAddZero(hours.toString()),
        minutes: checkToAddZero(minutes.toString()),
        seconds: checkToAddZero(seconds.toString()),
      };
    }
    history.push("/error");
  };

  useEffect(() => {
    if (generalConfig.expirationDate) {
      const expirationDate = Number(generalConfig.expirationDate);
      const timer = setTimeout(() => {
        setTime(calculateTimeLeft(expirationDate));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [time]);

  return (
    <Card>
      <CardContent className={classes.cardStyle}>
        <Grid container alignItems="center">
          <Grid item xs={5} sm={6}>
            <Typography className={classes.timerDescriptionStyle}>
              <T id="timerCover.description" />
            </Typography>
          </Grid>
          <Grid item xs={7} sm={6}>
            {time && (
              <Grid container justify="center" id="timeContainer">
                <UnitTime value={time.days}>
                  <T id="timerCover.days" />
                </UnitTime>
                <TimerDot />
                <UnitTime value={time.hours}>
                  <T id="timerCover.hours" />
                </UnitTime>
                <TimerDot />
                <UnitTime value={time.minutes}>
                  <T id="timerCover.minutes" />
                </UnitTime>
                <TimerDot />
                <UnitTime value={time.seconds}>
                  <T id="timerCover.seconds" />
                </UnitTime>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TimerCover;
