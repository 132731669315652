/**
 * Catalogs
 */

export enum Countries {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRASIL = "Brasil",
  ELSALVADOR = "ElSalvador",
  PANAMA = "Panama",
  COSTARICA = "CostaRica",
  NICARAGUA = "Nicaragua",
  HONDURAS = "Honduras",
  GUATEMALA = "Guatemala",
}

export const CountryCatalog: Record<string, string[]> = {
  [Countries.ECUADOR]: ["Cédula", "Pasaporte", "Ruc"],
  [Countries.COLOMBIA]: ["Cédula", "Pasaporte", "NIT"],
  [Countries.PERU]: ["DNI", "Pasaporte", "RUC", "Carnet Ext"],
  [Countries.CHILE]: ["CC", "Pasaporte", "RUT"],
  [Countries.MEXICO]: ["CURP", "Pasaporte", "RFC"],
  [Countries.BRASIL]: ["CPF", "Pasaporte", "CPFJ"],
  [Countries.ELSALVADOR]: ["DUI", "Pasaporte", "NIT"],
  [Countries.COSTARICA]: ["CI", "Pasaporte", "NIT"],
  [Countries.NICARAGUA]: ["CI", "Pasaporte", "RUC"],
  [Countries.HONDURAS]: ["RNP", "Pasaporte", "RTN"],
  [Countries.GUATEMALA]: ["CIP", "Pasaporte", "NIT"],
  [Countries.PANAMA]: ["CI", "Pasaporte", "NIT"],
};

export const CountryCodeCatalog: Record<string, string> = {
  [Countries.ECUADOR]: "+593",
  [Countries.COLOMBIA]: "+57",
  [Countries.PERU]: "+51",
  [Countries.CHILE]: "+56",
  [Countries.MEXICO]: "+52",
  [Countries.BRASIL]: "+55",
  [Countries.ELSALVADOR]: "+503",
  [Countries.PANAMA]: "+507",
  [Countries.COSTARICA]: "+506",
  [Countries.NICARAGUA]: "+505",
  [Countries.HONDURAS]: "+504",
  [Countries.GUATEMALA]: "+502",
};

export interface Iregion {
  label: string;
  value: string;
}

export const CountryRegionCatalog: Record<string, Iregion[]> = {
  [Countries.BRASIL]: [
    { label: "Acre", value: "Acre" },
    { label: "Alagoas", value: "Alagoas" },
    { label: "Amapá", value: "Amapá" },
    { label: "Amazonas", value: "Amazonas" },
    { label: "Bahía", value: "Bahia" },
    { label: "Ceará", value: "Ceará" },
    { label: "Distrito Federal", value: "DistritoFederal" },
    { label: "Espirito Santo", value: "EspiritoSanto" },
    { label: "Estado de Pernambuco", value: "EstadoDePernambuco" },
    { label: "Goiás", value: "Goias" },
    { label: "Maranhão", value: "Maranhao" },
    { label: "Mato Grosso", value: "MatoGrosso" },
    { label: "Mato Grosso do Sul", value: "MatoGrossoDoSul" },
    { label: "Minas Gerais", value: "MinasGerais" },
    { label: "Pará", value: "Para" },
    { label: "Paraibá", value: "Paraiba" },
    { label: "Paraná", value: "Parana" },
    { label: "Piauí", value: "Piaui" },
    { label: "Rio de Janeiro", value: "RioDeJaneiro" },
    { label: "Rio Grande do Norte", value: "RioGrandeDoNorte" },
    { label: "Rio Grande do Sul", value: "RioGrandeDoSul" },
    { label: "Rondônia", value: "Rondonia" },
    { label: "Roraima", value: "Roraima" },
    { label: "Santa Catarina", value: "SantaCatarina" },
    { label: "São Paulo", value: "SaoPaulo" },
    { label: "Sergipe", value: "Sergipe" },
    { label: "Tocantins", value: "Tocantins" },
  ],
  [Countries.CHILE]: [
    { label: "Antofagasta", value: "Antofagasta" },
    { label: "Arica y Parinacota", value: "AricaYParinacota" },
    { label: "Atacama", value: "Atacama" },
    {
      label: "Aysén del Gral C Ibáñez del Campo",
      value: "AysenDelGralCIbañezDelCampo",
    },
    { label: "Biobío", value: "Biobio" },
    { label: "Coquimbo", value: "Coquimbo" },
    { label: "La Araucanía", value: "Laaraucanía" },
    { label: "Lib Gral Bernardo O’Higgins", value: "LibGralBernardoOHiggins" },
    { label: "Los Lagos", value: "LosLagos" },
    { label: "Los Ríos", value: "LosRios" },
    {
      label: "Magallanes y la Antártica Chilena",
      value: "MagallanesYLaAntarticaChilena",
    },
    { label: "Maule", value: "Maule" },
    { label: "Metropolitana de Santiago", value: "MetropolitanaDeSantiago" },
    { label: "Ñuble", value: "Ñuble" },
    { label: "Tarapacá", value: "Tarapaca" },
    { label: "Valparaíso", value: "Valparaiso" },
  ],
  [Countries.COLOMBIA]: [
    { label: "Amazonas", value: "Amazonas" },
    { label: "Antioquia", value: "Antioquia" },
    { label: "Arauca", value: "Arauca" },
    { label: "Atlántico", value: "Atlantico" },
    { label: "Bogotá", value: "Bogota" },
    { label: "Bolivar", value: "Bolivar" },
    { label: "Boyacá", value: "Boyaca" },
    { label: "Caldas", value: "Caldas" },
    { label: "Caquetá", value: "Caqueta" },
    { label: "Casanare", value: "Casanare" },
    { label: "Cauca", value: "Cauca" },
    { label: "Cesar", value: "Cesar" },
    { label: "Chocó", value: "Choco" },
    { label: "Córdoba", value: "Cordoba" },
    { label: "Cundinamarca", value: "Cundinamarca" },
    { label: "Guainía", value: "Guainia" },
    { label: "Guaviare", value: "Guaviare" },
    { label: "Huila", value: "Huila" },
    { label: "La Guajira", value: "LaGuajira" },
    { label: "Magdalena", value: "Magdalena" },
    { label: "Meta", value: "Meta" },
    { label: "Nariño", value: "Nariño" },
    { label: "Norte De Santander", value: "NorteDeSantander" },
    { label: "Putumayo", value: "Putumayo" },
    { label: "Quindio", value: "Quindio" },
    { label: "Risarala", value: "Risaralda" },
    { label: "Santander", value: "Santander" },
    { label: "Sucre", value: "Sucre" },
    { label: "Tolima", value: "Tolima" },
    { label: "Valle del Cauca", value: "ValleDelCauca" },
    { label: "Vaupés", value: "Vaupes" },
    { label: "Vichada", value: "Vichada" },
  ],
  [Countries.COSTARICA]: [
    { label: "San José", value: "SanJose" },
    { label: "Alajuela", value: "Alajuela" },
    { label: "Cartago", value: "Cartago" },
    { label: "Heredia", value: "Heredia" },
    { label: "Guanacaste", value: "Guanacaste" },
    { label: "Puntarenas", value: "Puntarenas" },
    { label: "Limón", value: "Limon" },
  ],
  [Countries.ECUADOR]: [
    { label: "Azuay", value: "Azuay" },
    { label: "Bolívar", value: "Bolivar" },
    { label: "Cañar", value: "Cañar" },
    { label: "Carchi", value: "Carchi" },
    { label: "Chimborazo", value: "Chimborazo" },
    { label: "Cotopaxi", value: "Cotopaxi" },
    { label: "El Oro", value: "ElOro" },
    { label: "Esmeraldas", value: "Esmeraldas" },
    { label: "Galápagos", value: "Galapagos" },
    { label: "Guayas", value: "Guayas" },
    { label: "Imbabura", value: "Imbabura" },
    { label: "Loja", value: "Loja" },
    { label: "Los Ríos", value: "LosRios" },
    { label: "Manabí", value: "Manabi" },
    { label: "Morona Santiago", value: "MoronaSantiago" },
    { label: "Napo", value: "Napo" },
    { label: "Orellana", value: "Orellana" },
    { label: "Pastaza", value: "Pastaza" },
    { label: "Pichincha", value: "Pichincha" },
    { label: "Santa Elena", value: "Santa Elena" },
    {
      label: "Santa Domingo de los Tsáchilas´",
      value: "SantoDomingoDeLosTsachilas",
    },
    { label: "Sucumbios´", value: "Sucumbios" },
    { label: "Tungurahua´", value: "Tungurahua" },
    { label: "Zamora Chinchipe´", value: "Zamora Chinchipe" },
  ],
  [Countries.ELSALVADOR]: [
    { label: "Ahuachapán", value: "Ahuachapan" },
    { label: "Santa Ana", value: "SantaAna" },
    { label: "Sonsonate", value: "Sonsonate" },
    { label: "Chalatenango", value: "Chalatenango" },
    { label: "La Libertad", value: "LaLibertad" },
    { label: "San Salvador", value: "SanSalvador" },
    { label: "Cuscatlán", value: "Cuscatlan" },
    { label: "La Paz", value: "LaPaz" },
    { label: "Cabañas", value: "Cabañas" },
    { label: "San Vicente", value: "SanVicente" },
    { label: "Usulután", value: "Usulutan" },
    { label: "San Miguel", value: "SanMiguel" },
    { label: "Morazán", value: "Morazan" },
    { label: "La Unión", value: "LaUnion" },
  ],
  [Countries.GUATEMALA]: [
    { label: "Guatemala", value: "Guatemala" },
    { label: "El Progreso", value: "Sacatepéquez" },
    { label: "Chimaltenango", value: "Chimaltenango" },
    { label: "Escuintia", value: "Escuintia" },
    { label: "Santa Rosa", value: "SantaRosa" },
    { label: "Sololá", value: "Solola" },
    { label: "Totonicapán", value: "Totonicapan" },
    { label: "Quetzaltenango", value: "Quetzaltenango" },
    { label: "Suchitepéquez", value: "Suchitepequez" },
    { label: "Retalhuleu", value: "Retalhuleu" },
    { label: "San Marcos", value: "SanMarcos" },
    { label: "Huehuetenango", value: "Huehuetenango" },
    { label: "Quiché", value: "Quiché" },
    { label: "Baja Verapaz", value: "BajaVerapaz" },
    { label: "Alta Verapaz", value: "AltaVerapaz" },
    { label: "Petén", value: "Petén" },
    { label: "Izabal", value: "Izabal" },
    { label: "Zacapa", value: "Zacapa" },
    { label: "Chiquimula", value: "Chiquimula" },
    { label: "Jalapa", value: "Jalapa" },
    { label: "Jutiapa", value: "Jutiapa" },
  ],
  [Countries.HONDURAS]: [
    { label: "Atlántida", value: "Atlantida" },
    { label: "Colón", value: "Colon" },
    { label: "Comayagua", value: "Comayagua" },
    { label: "Copán", value: "Copan" },
    { label: "Cortés", value: "Cortes" },
    { label: "Choluteca", value: "Choluteca" },
    { label: "El Paraíso", value: "ElParaiso" },
    { label: "Francisco Morazán", value: "FranciscoMorazan" },
    { label: "Gracias a Dios", value: "GraciasADios" },
    { label: "Intibucá", value: "Intibuca" },
    { label: "Islas de la Bahía", value: "IslasDeLaBahia" },
  ],
  [Countries.MEXICO]: [
    { label: "Aguascalientes", value: "Aguascalientes" },
    { label: "Baja California", value: "BajaCaliforniaSur" },
    { label: "Campeche", value: "Campeche" },
    { label: "Chiapas", value: "Chiapas" },
    { label: "Chihuahua", value: "Chihuahua" },
    { label: "Ciudad de México", value: "CiudadDeMexico" },
    { label: "Coahuila", value: "Coahuila" },
    { label: "Colima", value: "Colima" },
    { label: "Durango", value: "Durango" },
    { label: "Guanajuato", value: "Guanajuato" },
    { label: "Guerrero", value: "Guerrero" },
    { label: "De los Bravo", value: "DeLosBravo" },
    { label: "Hidalgo", value: "Hidalgo" },
    { label: "Jalisco", value: "Jalisco" },
    { label: "México", value: "Mexico" },
    { label: "Michoacán", value: "Michoacan" },
    { label: "Morelos", value: "Morelos" },
    { label: "Nayarit", value: "Nayarit" },
    { label: "Nuevo León", value: "NuevoLeón" },
    { label: "Oaxaca", value: "Oaxaca" },
    { label: "Puebla", value: "Puebla" },
    { label: "Querétaro", value: "Queretaro" },
    { label: "Quintana Roo", value: "QuintanaRoo" },
    { label: "San Luis Potosí", value: "SanLuisPotosi" },
    { label: "Sonora", value: "Sonora" },
    { label: "Tabasco", value: "Tabasco" },
    { label: "Tamaulipas", value: "Tamaulipas" },
    { label: "Tiaxcala", value: "Tiaxcala" },
    { label: "Veracruz", value: "Veracruz" },
    { label: "Yucatán", value: "Yucatán" },
    { label: "Zacatecas", value: "Zacatecas" },
  ],
  [Countries.NICARAGUA]: [
    { label: "Atlántico Norte", value: "AtlanticoNorte" },
    { label: "Atlántico Sur", value: "AtlanticoSur" },
    { label: "Boaco", value: "Boaco" },
    { label: "Carazo", value: "Carazo" },
    { label: "Chinandega", value: "Chinandega" },
    { label: "Chontales", value: "Chontales" },
    { label: "Estelí", value: "Esteli" },
    { label: "Granada", value: "Granada" },
    { label: "Jinotega", value: "Jinotega" },
    { label: "León", value: "Leon" },
    { label: "Madriz", value: "Madriz" },
    { label: "Managua", value: "Managua" },
    { label: "Masaya", value: "Masaya" },
    { label: "Matagalpa", value: "Matagalpa" },
    { label: "Nueva Segovia", value: "NuevaSegovia" },
    { label: "Rio San Juan", value: "RioSanJuan" },
    { label: "Rivas", value: "Rivas" },
  ],
  [Countries.PANAMA]: [
    { label: "Bocas del Toro", value: "Bocas Del Toro" },
    { label: "Chiriquí", value: "Chiriqui" },
    { label: "Coclé", value: "Cocle" },
    { label: "Carazo", value: "Carazo" },
    { label: "Chinandega", value: "Chinandega" },
    { label: "Chontales", value: "Chontales" },
    { label: "Estelí", value: "Esteli" },
    { label: "Granada", value: "Granada" },
    { label: "Jinotega", value: "Jinotega" },
    { label: "León", value: "Leon" },
    { label: "Madriz", value: "Madriz" },
    { label: "Managua", value: "Managua" },
    { label: "Masaya", value: "Masaya" },
    { label: "Matagalpa", value: "Matagalpa" },
    { label: "Nueva Segovia", value: "NuevaSegovia" },
    { label: "Rio San Juan", value: "RioSanJuan" },
    { label: "Rivas", value: "Rivas" },
  ],
  [Countries.PERU]: [
    { label: "Amazonas", value: "Amazonas" },
    { label: "Ancash", value: "Ancash" },
    { label: "Apurimac", value: "Apurimac" },
    { label: "Arequipa", value: "Arequipa" },
    { label: "Ayacucho", value: "Ayacucho" },
    { label: "Cajamarca", value: "Cajamarca" },
    { label: "Callao", value: "Callao" },
    { label: "Cusco", value: "Cusco" },
    { label: "Huancavelica", value: "Huancavelica" },
    { label: "Huanuco", value: "Huanuco" },
    { label: "Ica", value: "Ica" },
    { label: "Junin", value: "Junin" },
    { label: "La Libertad", value: "LaLibertad" },
    { label: "Lambayeque", value: "Lambayeque" },
    { label: "Lima", value: "Lima" },
    { label: "Loreto", value: "Loreto" },
    { label: "Madre De Dios", value: "MadreDeDios" },
    { label: "Moquegua", value: "Moquegua" },
    { label: "Pasco", value: "Pasco" },
    { label: "Piura", value: "Piura" },
    { label: "Puno", value: "Puno" },
    { label: "San Martin", value: "SanMartin" },
    { label: "Tacna", value: "Tacna" },
    { label: "Tumbes", value: "Tumbes" },
    { label: "Ucayali", value: "Ucayali" },
  ],
};

export const CountryTypeProvinceCatalog: Record<string, string> = {
  [Countries.COSTARICA]: "province",
  [Countries.ELSALVADOR]: "department",
  [Countries.HONDURAS]: "province",
  [Countries.NICARAGUA]: "department",
  [Countries.PANAMA]: "province",
};

export const CountryTypeCityCatalog: Record<string, string> = {
  [Countries.COSTARICA]: "city",
  [Countries.ELSALVADOR]: "city",
  [Countries.HONDURAS]: "city",
  [Countries.NICARAGUA]: "municipality",
  [Countries.PANAMA]: "city",
};
