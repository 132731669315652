import React from "react";
import CardCoverMobile from "./components/CardCoverMobile/CardCoverMobile";
import TopHeader from "../../commons/TopHeader/TopHeader";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  topHeader: {
    padding: "0px 10px",
    position: "absolute",
    top: "0",
    width: "100%",
  },
}));

const LeftRightCoverMobile: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.topHeader}>
        <TopHeader />
      </Grid>
      <CardCoverMobile />
    </React.Fragment>
  );
};

export default LeftRightCoverMobile;
