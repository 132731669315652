import React from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Translate as T } from "react-localize-redux";
import { DynamicForm } from "../Payment/DynamicForm/DynamicForm";
import SiftForm from "../Payment/SiftScienceForm/SiftForm";
import { useFormContainerState } from "../FormContainer/useFormContainerState/useFormContainerState";

const useStyles = makeStyles(() => ({
  button: {
    alignItems: "center",
    backgroundColor: "#023365",
    borderRadius: "4px",
    color: "white",
    fontSize: "16px",
    height: "36px",
  },
  cardRoot: {
    marginLeft: "-8px",
    marginRight: "-8px",
    padding: "16px",
    width: "343",
  },
  formTitle: {
    align: "center",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
  },
}));

interface IFormContainerMobile {
  display: string;
}

const FormContainerMobile: ({ display }: IFormContainerMobile) => JSX.Element =
  ({ display }: IFormContainerMobile) => {
    const classes = useStyles();

    const {
      siftScienceEnabled,
      siftForm,
      handleContinueSubmit,
      dynamicForm,
      dynamicFormEnabled,
    } = useFormContainerState();

    return (
      <Card className={classes.cardRoot} style={{ display: display }}>
        <Grid item xs={12}>
          <Box>
            {siftScienceEnabled && <SiftForm siftForm={siftForm} />}
            {dynamicFormEnabled && <DynamicForm form={dynamicForm} />}
          </Box>
        </Grid>
        <Box pt={4} pb={4}>
          <Divider />
          <Button
            variant="contained"
            color={"primary"}
            fullWidth
            onClick={handleContinueSubmit}
            className={classes.button}
          >
            <T id={"btnLabels.buttonFormLabel"} />
          </Button>
        </Box>
      </Card>
    );
  };

export default FormContainerMobile;
