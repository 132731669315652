import React from "react";
import { Box, Theme } from "@material-ui/core";
import { LeftRightCoverCard } from "../LeftRightCoverCard/LeftRightCoverCard";
import { makeStyles } from "@material-ui/core/styles";
import TopHeader from "../../../../commons/TopHeader/TopHeader";

const useStyles = makeStyles((theme: Theme) => ({
  example: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  logoImage: {
    height: "48px",
    mixBlendMode: "multiply",
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(1),
    width: "auto",
  },
}));

export const CardCover: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="center"
      >
        <TopHeader />
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        className={classes.example}
      >
        <LeftRightCoverCard />
      </Box>
    </>
  );
};
