import React from "react";
import {
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Controller, UseFormMethods } from "react-hook-form";
import { Translate as T } from "react-localize-redux";
import { COUNTRIES, ICountry } from "../../../shared/constants/Country";
import {
  CountryCatalog,
  CountryCodeCatalog,
  CountryRegionCatalog,
  CountryTypeCityCatalog,
  CountryTypeProvinceCatalog,
  Iregion,
} from "../../../shared/service/CountryCatalog";
import { defaultTo } from "lodash";
import InputMask from "react-input-mask";

const getEmailRule = (validateEmail: boolean) => {
  if (validateEmail)
    return {
      message: "emailInvalid",
      value: new RegExp("^[\\w-.]+@([\\w-]+\\.)+[\\w-]+$"),
    };
};

export interface ISiftForm {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  documentType: string;
  documentNumber: string;
  region: string;
  city: string;
  address: string;
  secondaryAddress: string;
  zipCode: string;
  countryCode: string;
  phone: string;
}

export interface ISiftFormProps {
  siftForm: UseFormMethods<ISiftForm>;
}

const SiftForm: React.FC<ISiftFormProps> = (props: ISiftFormProps) => {
  const useStyles = makeStyles((theme: Theme) => ({
    descriptionText: {
      fontSize: "15px",
      fontWeight: 400,
    },
    errorText: { color: theme.palette.error.main, marginLeft: "14px" },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "8px",
      },
    },
    itemContainer: {
      marginTop: "16px",
      maxWidth: "305px",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    lastNameAddressItem: {
      marginTop: "16px",
    },
    personalDataText: {
      ...theme.typography.h6,
      fontWeight: 500,
    },
    phoneNumberItem: {
      marginTop: "16px",
      maxWidth: "288px",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    postalCodeAndCountryCodeItem: {
      marginTop: "16px",
      maxWidth: "160px",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    titleText: {
      ...theme.typography.h5,
      color: theme.palette.primary.main,
    },
  }));

  const { siftForm } = props;

  const country = siftForm.watch("country");

  const classes = useStyles();

  return (
    <T>
      {({ translate }) => (
        <>
          <Typography className={classes.personalDataText}>
            {translate("siftScienceForm.personalData")}
          </Typography>
          <br />
          <form id="siftForm" className={classes.formContainer}>
            <Grid item xs>
              <TextField
                label={translate("siftScienceForm.firstName")}
                name="firstName"
                fullWidth
                inputRef={siftForm.register({ required: true })}
                error={!!siftForm.errors.firstName}
                helperText={
                  siftForm.errors.firstName &&
                  translate("siftScienceForm.required")
                }
                variant="outlined"
              />
            </Grid>
            <Grid className={classes.lastNameAddressItem} item xs>
              <TextField
                label={translate("siftScienceForm.lastName")}
                name="lastName"
                fullWidth
                inputRef={siftForm.register({ required: true })}
                error={!!siftForm.errors.lastName}
                helperText={
                  siftForm.errors.lastName &&
                  translate("siftScienceForm.required")
                }
                variant="outlined"
              />
            </Grid>
            <Grid item container justify="space-between" xs>
              <Grid
                item
                className={classes.itemContainer}
                xs={12}
                sm={12}
                md={6}
              >
                <TextField
                  label={translate("siftScienceForm.email")}
                  name="email"
                  fullWidth
                  inputRef={siftForm.register({
                    pattern: getEmailRule(true),
                    required: true,
                  })}
                  error={!!siftForm.errors.email}
                  helperText={
                    siftForm.errors.email?.type === "pattern"
                      ? translate("form.emailInvalid")
                      : siftForm.errors.email &&
                        translate("siftScienceForm.required")
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                className={classes.itemContainer}
                xs={12}
                sm={12}
                md={6}
              >
                <Controller
                  name={"country"}
                  control={siftForm.control}
                  rules={{ required: true }}
                  render={({ ...props }) => (
                    <>
                      <TextField
                        {...props}
                        fullWidth
                        select
                        variant="outlined"
                        error={!!siftForm.errors.country}
                        label={translate("siftScienceForm.country")}
                      >
                        {COUNTRIES.map((country: ICountry, index: number) => (
                          <MenuItem key={index} value={country.value}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      {siftForm.errors.country && (
                        <FormHelperText className={classes.errorText}>
                          {translate("siftScienceForm.required")}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container justify="space-between" xs>
              <Grid
                item
                className={classes.itemContainer}
                xs={12}
                sm={12}
                md={6}
              >
                <Controller
                  name={"documentType"}
                  control={siftForm.control}
                  rules={{
                    required: true,
                    validate: (value: string) =>
                      !!CountryCatalog[country].find(
                        (documentType) => documentType === value
                      ),
                  }}
                  render={({ ...props }) => (
                    <>
                      <TextField
                        {...props}
                        fullWidth
                        select
                        defaultValue={""}
                        variant="outlined"
                        error={!!siftForm.errors.documentType}
                        label={translate("siftScienceForm.documentType")}
                      >
                        {defaultTo(CountryCatalog[country], []).map(
                          (category: string, index: number) => (
                            <MenuItem key={index} value={category}>
                              {category}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                      {siftForm.errors.documentType && (
                        <FormHelperText className={classes.errorText}>
                          {translate("siftScienceForm.required")}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid
                item
                className={classes.itemContainer}
                xs={12}
                sm={12}
                md={6}
              >
                <InputMask mask="99999999999999999999" maskChar={null}>
                  {(inputProps: any) => (
                    <TextField
                      {...inputProps}
                      label={translate("siftScienceForm.documentNumber")}
                      name="documentNumber"
                      fullWidth
                      inputRef={siftForm.register({ required: true })}
                      error={!!siftForm.errors.documentNumber}
                      helperText={
                        siftForm.errors.documentNumber &&
                        translate("siftScienceForm.required")
                      }
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
            <Grid item container justify="space-between">
              <Grid
                className={classes.itemContainer}
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Controller
                  name={"region"}
                  control={siftForm.control}
                  rules={{
                    required: true,
                    validate: (region: string) =>
                      !!CountryRegionCatalog[country].find(
                        ({ value }) => region === value
                      ),
                  }}
                  render={({ ...props }) => (
                    <>
                      <TextField
                        {...props}
                        label={translate(
                          `siftScienceForm.${defaultTo(
                            CountryTypeProvinceCatalog[country],
                            "province"
                          )}`
                        )}
                        fullWidth
                        select
                        error={!!siftForm.errors.region}
                        variant="outlined"
                      >
                        {defaultTo(CountryRegionCatalog[country], []).map(
                          (region: Iregion, index: number) => (
                            <MenuItem key={index} value={region.value}>
                              {region.label}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                      {siftForm.errors.region && (
                        <FormHelperText className={classes.errorText}>
                          {translate("siftScienceForm.required")}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid
                className={classes.itemContainer}
                item
                xs={12}
                sm={12}
                md={6}
              >
                <TextField
                  name="city"
                  fullWidth
                  inputRef={siftForm.register({ required: true })}
                  error={!!siftForm.errors.city}
                  label={translate(
                    `siftScienceForm.${defaultTo(
                      CountryTypeCityCatalog[country],
                      "city"
                    )}`
                  )}
                  helperText={
                    siftForm.errors.city &&
                    translate("siftScienceForm.required")
                  }
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid className={classes.lastNameAddressItem} item xs>
              <TextField
                label={translate("siftScienceForm.address")}
                name="address"
                fullWidth
                inputRef={siftForm.register({ required: true })}
                error={!!siftForm.errors.address}
                helperText={
                  siftForm.errors.address &&
                  translate("siftScienceForm.required")
                }
                variant="outlined"
              />
            </Grid>
            <Grid className={classes.lastNameAddressItem} item xs>
              <TextField
                label={<T id={"siftScienceForm.secondaryAddress"} />}
                name="secondaryAddress"
                inputRef={siftForm.register}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item container justify="space-between" xs>
              <Grid
                item
                className={classes.postalCodeAndCountryCodeItem}
                xs={12}
                sm={12}
                md={3}
              >
                <InputMask mask="99999" maskChar={null}>
                  {(inputProps: any) => (
                    <TextField
                      {...inputProps}
                      label={translate("siftScienceForm.zipCode")}
                      name="zipCode"
                      fullWidth
                      inputRef={siftForm.register({ required: true })}
                      error={!!siftForm.errors.zipCode}
                      helperText={
                        siftForm.errors.zipCode &&
                        translate("siftScienceForm.required")
                      }
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid
                item
                className={classes.postalCodeAndCountryCodeItem}
                xs={12}
                sm={12}
                md={3}
              >
                <TextField
                  label={<T id={"siftScienceForm.countryCode"} />}
                  name="countryCode"
                  inputRef={siftForm.register}
                  inputProps={{ readOnly: true }}
                  value={defaultTo(CountryCodeCatalog[country], "")}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                className={classes.phoneNumberItem}
                xs={12}
                sm={12}
                md={6}
              >
                <InputMask mask="999 999 999 999 999" maskChar={null}>
                  {(inputProps: never) => (
                    <TextField
                      {...inputProps}
                      label={translate("siftScienceForm.phone")}
                      name="phone"
                      fullWidth
                      inputRef={siftForm.register({ required: true })}
                      error={!!siftForm.errors.phone}
                      helperText={
                        siftForm.errors.phone &&
                        translate("siftScienceForm.required")
                      }
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </T>
  );
};

export default SiftForm;
