export const AMOUNT_CHARACTERS: string[] = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  ".",
  "Backspace",
  "ArrowRight",
  "ArrowLeft",
  "ArrowUp",
  "ArrowDown",
];
