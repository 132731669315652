import React from "react";
import { Translate as T } from "react-localize-redux";
import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ILocationState } from "../../../../containers/Payment/Payment";
import { useLocation } from "react-router-dom";
import { StatusLabels } from "../../../../shared/infrastructure/StatusLabels";
import ResumeKpay from "../../../commons/ResumeKpay/ResumeKpay";
import logoKushki from "../../../../assets/logos/kushki_logo.svg";
import { useStateApp } from "../../../../state/useStateApp";
import { ResumeProps } from "../../../../containers/Resume/Resume";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    minHeight: "40px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "30px",
    },
  },
  button: {
    height: "48px",
    [theme.breakpoints.down("xs")]: {
      height: "32px",
    },
  },
  buttonContainer: {
    marginTop: "48px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
    },
  },
  checkSubText: {
    fontSize: "16px",
  },
  checkText: {
    fontSize: "32px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      paddingTop: "8px",
    },
  },
  contentCard: {
    [theme.breakpoints.down("xs")]: {
      padding: 1,
    },
  },
  descriptionText: {
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "140%",
  },
  detailCard: {
    borderRadius: "10px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    margin: "auto",
    marginLeft: "16px",
    marginRight: "16px",
    minHeight: "715px",
    padding: "32px 26px 32px 26px",
    width: "606px",
    [theme.breakpoints.down("xs")]: {
      boxShadow:
        "0px 10px 10px rgba(60, 66, 87, 0.12), 0px 0px 15px rgba(0, 0, 0, 0.12)",
      height: "811px",
      margin: 0,
      padding: "32px 12px 32px 12px",
      width: "330px",
    },
  },
  detailsContainer: {
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  detailsItem: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
    },
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
  },
  productImage: {
    borderRadius: "10px",
    height: "80px",
    maxHeight: "80px",
    maxWidth: "80px",
    objectFit: "cover",
    width: "100%",
  },
  promotionalText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    marginBottom: "15px",
    marginTop: "24px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "8px",
      marginTop: "13px",
    },
  },
  summarizeDescription: {
    color: "#5c5c5c",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    margin: "8px 0px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "150%",
    },
  },
  summarizeInfo: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "150%",
      marginLeft: "10px",
    },
  },
  summarizeTitle: {
    fontSize: "20px",
    marginBottom: "12px",
    marginTop: "17px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      fontWeight: 500,
      margin: "25px 0px 25px 0px",
    },
  },
}));

const ResumeTransferSafetyPay: (props: ResumeProps) => JSX.Element = (
  props: ResumeProps
) => {
  const classes = useStyles();
  const {
    state: {
      resumeData: {
        name,
        documentNumber,
        email,
        product,
        date,
        ticketNumber,
        kpay: isKpay,
        status = "",
        totalAmount,
        currency,
      },
    },
  } = useLocation<ILocationState>();

  const { isMobile } = useStateApp();

  const transactionStatus: string = StatusLabels[status];

  const detailComponent = (label: string, content: string) => (
    <Grid
      item
      container
      className={classes.detailsItem}
      alignItems="center"
      justify="space-between"
    >
      <Grid item xs={4} sm={12} md={12}>
        <Typography className={classes.summarizeDescription}>
          <T id={label} />
        </Typography>
      </Grid>
      <Grid item xs={8} sm={12} md={12}>
        <Typography className={classes.summarizeInfo}>{content}</Typography>
      </Grid>
    </Grid>
  );

  const personalInfoComponent = (label: string, content: string[]) => (
    <Grid
      item
      container
      className={classes.detailsItem}
      alignItems="center"
      justify="space-between"
    >
      <Grid item xs={4} sm={12} md={12}>
        <Typography className={classes.summarizeDescription}>
          <T id={label} />
        </Typography>
      </Grid>
      <Grid item xs={8} sm={12} md={12}>
        {content.map((info: string) => (
          <Typography className={classes.summarizeInfo} key={info}>
            {info}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );

  const desktopComponent = () => (
    <>
      <Grid item sm={7} md={7}>
        {detailComponent("sumarizeLabelsForTransferETPAY.name", name as string)}
      </Grid>
      <Grid item sm={5} md={5}>
        {detailComponent(
          "sumarizeLabelsForTransferETPAY.nit",
          documentNumber as string
        )}
      </Grid>
      <Grid item sm={7} md={7}>
        {detailComponent(
          "sumarizeLabelsForTransferETPAY.email",
          email as string
        )}
      </Grid>
      <Grid item sm={5} md={5}>
        {detailComponent("sumarizeLabelsForTransferETPAY.date", date as string)}
      </Grid>
      <Grid item sm={5} md={5}>
        {props.dynamicFormValues &&
          personalInfoComponent(
            "sumarizeLabelsForTransferETPAY.personalInfo",
            props.dynamicFormValues
          )}
      </Grid>
    </>
  );

  const mobileComponent = () => (
    <>
      <Grid item xs={12}>
        {detailComponent("sumarizeLabelsForTransferETPAY.name", name as string)}
      </Grid>
      <Grid item xs={12}>
        {detailComponent(
          "sumarizeLabelsForTransferETPAY.nit",
          documentNumber as string
        )}
      </Grid>
      <Grid item xs={12}>
        {detailComponent(
          "sumarizeLabelsForTransferETPAY.email",
          email as string
        )}
      </Grid>
      <Grid item xs={12}>
        {detailComponent("sumarizeLabelsForTransferETPAY.date", date as string)}
      </Grid>
      <Grid item xs={12}>
        {props.dynamicFormValues &&
          personalInfoComponent(
            "sumarizeLabelsForTransferETPAY.personalInfo",
            props.dynamicFormValues
          )}
      </Grid>
    </>
  );

  return (
    <Grid container justify="center">
      <Card className={classes.detailCard}>
        <CardContent className={classes.contentCard}>
          <Grid container direction="column" xs={12} md={12} lg={12}>
            <Grid item justify="center" className={classes.logo}>
              <img
                src={logoKushki}
                className={classes.productImage}
                alt={product}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                className={classes.checkText}
                color={"initial"}
                align="center"
              >
                <T id="sumarizeLabelsForTransferETPAY.successTitle" />
              </Typography>
              <Typography
                className={classes.promotionalText}
                color={"initial"}
                align="center"
              >
                <T id="sumarizeLabelsForTransferETPAY.successSubTitle" />
              </Typography>
            </Grid>
            {isKpay ? (
              <Grid container item justify="center" xs md={12}>
                <Grid item md={10}>
                  <ResumeKpay />
                </Grid>
              </Grid>
            ) : (
              <Box className={classes.box} />
            )}
            <Grid item justify="center" xs={12} md={12} lg={12}>
              <Typography className={classes.summarizeTitle} align="center">
                <T id="sumarizeLabelsForTransferETPAY.summarizeTitle" />
              </Typography>
            </Grid>

            <Grid item container xs={12} sm={12} md={12} spacing={2}>
              <Grid item xs={12} sm={7} md={7}>
                {detailComponent(
                  "sumarizeLabelsForTransferETPAY.product",
                  product as string
                )}
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                {detailComponent(
                  "sumarizeLabelsForTransferETPAY.ticketNumber",
                  ticketNumber as string
                )}
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                {detailComponent(
                  "sumarizeLabelsForTransferETPAY.amount",
                  totalAmount + " " + currency
                )}
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                {detailComponent(
                  "sumarizeLabelsForTransferETPAY.status",
                  (<T id={transactionStatus} />) as any
                )}
              </Grid>
              {isMobile ? mobileComponent() : desktopComponent()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ResumeTransferSafetyPay;
