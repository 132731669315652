import React from "react";
import { Box, Card } from "@material-ui/core";
import TopHeader from "../../components/commons/TopHeader/TopHeader";
import { useErrorState } from "./useErrorState/useErrorState";
import GenericError from "../../components/ErrorIndex/GenericError/GenericError";
import TransactionError from "../../components/ErrorIndex/TransactionError/TransactionError";
import VerificationError from "../../components/ErrorIndex/VerificationError/VerificationError";
import { RouteError } from "../../shared/infrastructure/RouteEnum";
import { makeStyles } from "@material-ui/core/styles";
import { useStateApp } from "../../state/useStateApp";

const useStyle = makeStyles(() => ({
  cardStyle: {
    borderRadius: "10px",
    justifyContent: "center",
    maxWidth: "640px",
    padding: "16px",
    width: "640px",
  },
}));

const ErrorIndex: React.FC = () => {
  const { labels, handler, errorKey, descriptionSecondary } = useErrorState();
  const classes = useStyle();
  const { isMobile } = useStateApp();

  const render = () => {
    switch (errorKey) {
      case RouteError.transaction:
        return <TransactionError labels={labels} handler={handler} />;
      case RouteError.verification:
        return (
          <VerificationError
            labels={labels}
            handler={handler}
            descriptionSecondary={descriptionSecondary}
          />
        );
      default:
        return <GenericError labels={labels} handler={handler} />;
    }
  };

  return (
    <>
      <TopHeader />
      <Box display="flex" justifyContent="center" p={2} pt={isMobile ? 6 : 2}>
        <Card className={classes.cardStyle}>{render()}</Card>
      </Box>
    </>
  );
};

export default ErrorIndex;
