import React from "react";
import { CustomForm } from "../../../types/smart_link_V2";
import { CheckboxList } from "../../components/Payment/DynamicForm/CheckboxList/CheckboxList";
import { DatePicker } from "../../components/Payment/DynamicForm/DatePicker/DatePicker";
import { Input } from "../../components/Payment/DynamicForm/Input/Input";
import { DynamicRadioGroup } from "../../components/Payment/DynamicForm/RadioGroup/RadioGroup";
import { DynamicSelect } from "../../components/Payment/DynamicForm/Select/Select";
import { TextArea } from "../../components/Payment/DynamicForm/TextArea/TextArea";

enum DynamicEnum {
  INPUT = "input",
  TEXT_AREA = "area",
  SELECT = "select",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  DATE = "date",
}

const DynamicComponents: Record<DynamicEnum, React.FC<CustomForm>> = {
  [DynamicEnum.INPUT]: Input,
  [DynamicEnum.TEXT_AREA]: TextArea,
  [DynamicEnum.SELECT]: DynamicSelect,
  [DynamicEnum.CHECKBOX]: CheckboxList,
  [DynamicEnum.RADIO]: DynamicRadioGroup,
  [DynamicEnum.DATE]: DatePicker,
};

export { DynamicEnum, DynamicComponents };
