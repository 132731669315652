import { TextField } from "@material-ui/core";
import React from "react";
import { useFormContext } from "react-hook-form";
import { CustomForm } from "../../../../../types/smart_link_V2";
import { Translate as T } from "react-localize-redux";
import { getLabel } from "../DynamicForm";

const getEmailRule = (validateEmail: boolean) => {
  if (validateEmail)
    return {
      message: "emailInvalid",
      value: new RegExp("^[\\w-.]+@([\\w-]+\\.)+[\\w-]+$"),
    };
};

const Input: React.FC<CustomForm> = (props: CustomForm) => {
  const { register, errors } = useFormContext();
  const {
    label,
    name,
    required,
    disabled,
    placeholder,
    value,
    validateEmail = false,
  } = props;

  return (
    <TextField
      label={getLabel(label, required)}
      placeholder={placeholder}
      name={name}
      disabled={disabled}
      defaultValue={value}
      inputRef={register({
        pattern: getEmailRule(validateEmail),
        required: {
          message: "fieldRequired",
          value: required,
        },
      })}
      fullWidth
      variant={"outlined"}
      error={!!errors[name]}
      helperText={!!errors[name] && <T id={`form.${errors[name].message}`} />}
    />
  );
};

export { Input };
