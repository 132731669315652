import DOMPurify from "dompurify";
import React, { useState } from "react";
import { Box, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import SmartlinkDetailTotal from "../SmartlinkDetailTotal/SmartlinkDetailTotal";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  cardRoot: {
    marginBottom: "16px",
    padding: "16px",
  },
  h5: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "140%",
    textAlign: "justify",
  },
  h6: {
    color: "#023365",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "120%",
  },
  image: {
    borderRadius: "5%",
    boxShadow:
      "0px 2px 1px rgba(14, 30, 46, 0.2),0px 2px 1px rgba(14, 30, 46, 0.14), 0px 2px 1px  rgba(2, 51, 101, 0.12)",
    height: "260px",
    maxHeight: "260px",
    maxWidth: "100%",
    objectFit: "contain",
    width: "100%",
  },
  label: ({ primaryColor }: { primaryColor: string | undefined }) => ({
    color: primaryColor
      ? theme.palette.text.primary
      : theme.palette.primary.dark,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "140%",
    textAlign: "justify",
  }),
  paragraph: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "150%",
    textAlign: "justify",
  },
}));

const SmartlinkDetailMobile: React.FC = () => {
  const smartlink = useSelector((state: IAppState) => state.smartlink!);
  const colors = useSelector(
    (state: IAppState) => state.merchantCustomizationInfo!.colors || {}
  );
  const { primary: primaryColor } = colors;
  const classes = useStyles({ primaryColor });
  const [open, setOpen] = useState<boolean>(true);
  const handlerIsOpen = () => {
    setOpen(!open);
  };

  const sanitizedHtml = DOMPurify.sanitize(smartlink.generalConfig.description);

  return (
    <>
      <Card className={classes.cardRoot}>
        <Grid item xs={12}>
          <Box pt={1}>
            <img
              src={smartlink.generalConfig.productImage}
              className={classes.image}
              alt={""}
            />
          </Box>
          <Box pt={1}>
            <Typography className={classes.label}>
              {smartlink.generalConfig.promotionalText}
            </Typography>
          </Box>
          <Grid item md={12}>
            <Box>
              <Typography variant={"h5"} className={classes.h5}>
                {smartlink.generalConfig.productName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <SmartlinkDetailTotal handlerIsOpen={handlerIsOpen} isOpen={open} />
          </Grid>
          {open && (
            <Box>
              <Box>
                <Typography className={classes.paragraph}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizedHtml,
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default SmartlinkDetailMobile;
