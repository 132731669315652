import { ICheckoutToken } from "@kushki/react-checkout/checkout/context/useCheckoutContext";
import { PaymentMethodsEnum } from "@kushki/react-checkout/checkout/shared/constants/PaymentMethodEnum";
import axios from "axios";
import * as H from "history";
import { defaultTo, get } from "lodash";
import {
  ActivationMethodEnum,
  ChargesRequest,
} from "../../../../../types/charge_request";
import { ChargeResponse } from "../../../../../types/charge_response";
import { Amount, SmartLink } from "../../../../../types/smart_link_V2";
import { environment } from "../../../../environments/environment";
import { CardType } from "../../../../shared/infrastructure/CardsEnum";
import { IAmount } from "../../../../shared/infrastructure/IAmount";
import { ACTIVATION_METHODS } from "../../../../shared/infrastructure/PaymentMethodEnum";
import { RouteEnum } from "../../../../shared/infrastructure/RouteEnum";
import { isEmpty } from "lodash";
import {
  initBrandMasterCardAnimation,
  initBrandVisaAnimation,
} from "../../../../store/actionCreators";
import { Dispatch } from "redux";
import { CardBrandsEnum } from "../../../../shared/infrastructure/CardBrandsEnum";
import { PaymentConfigTypeEnum } from "../../../../shared/enums/PaymentConfigTypeEnum";

const mapContactDetails = (
  chargeRequest: ChargesRequest,
  {
    paymentMethod = "" as PaymentMethodsEnum,
    contactDetails = {},
  }: ICheckoutToken
) => {
  if (
    [
      PaymentMethodsEnum.CASH,
      PaymentMethodsEnum.SUBSCRIPTION,
      PaymentMethodsEnum.TRANSFER_SUBSCRIPTION,
      PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC,
      PaymentMethodsEnum.CARD,
      PaymentMethodsEnum.TRANSFER,
    ].indexOf(paymentMethod) > -1
  ) {
    chargeRequest.contactDetails = { ...contactDetails };
  }
};

const mapDeferredConfig = (
  chargeRequest: ChargesRequest,
  { deferredConfig }: ICheckoutToken
) => {
  if (deferredConfig && deferredConfig.deferredType !== "all") {
    chargeRequest.deferred = {
      creditType: deferredConfig.deferredType,
      graceMonths: isEmpty(deferredConfig.graceMonths)
        ? "0"
        : deferredConfig.graceMonths,
      months: deferredConfig.months,
    };
  }
};

const mapMonths = (
  chargeRequest: ChargesRequest,
  { deferredConfig }: ICheckoutToken
) => {
  if (deferredConfig && !!deferredConfig.months) {
    chargeRequest.months = deferredConfig.months.toString();
  }
};

const mapPaymentMethod = (
  chargeRequest: ChargesRequest,
  { paymentMethod, cardType }: ICheckoutToken
) => {
  if (
    paymentMethod === PaymentMethodsEnum.CARD_DYNAMIC &&
    cardType === CardType.DEBIT
  )
    chargeRequest.paymentMethod = CardType.DEBIT_CARD;
};

const mapNotifyStatus = (
  notifyTransactionStatus: boolean,
  chargeRequest: ChargesRequest,
  checkoutToken: ICheckoutToken
) => {
  const email = get(checkoutToken, "contactDetails.email", false);

  if (
    email &&
    notifyTransactionStatus &&
    [PaymentMethodsEnum.CARD, PaymentMethodsEnum.TRANSFER].indexOf(
      checkoutToken.paymentMethod!
    ) > -1
  )
    chargeRequest.contactDetails = {
      email,
    };
};

const isAccendoResponse = (response: ChargeResponse): boolean => {
  const hasSubscriptionId = !!response.subscriptionId;
  const hasReferenceNumber = !!response.referenceNumber;

  return hasSubscriptionId && hasReferenceNumber;
};

const isStpResponse = (response: ChargeResponse): boolean => {
  const hasReferenceProcessor = !!response.referenceProcessor;
  const hasReferenceNumber = !!response.referenceNumber;

  return hasReferenceProcessor && hasReferenceNumber;
};

const isConfrontaResponse = (
  { status = false, subscriptionId = false }: ChargeResponse,
  paymentMethod: string
): boolean => {
  return (
    paymentMethod === PaymentMethodsEnum.TRANSFER_SUBSCRIPTION &&
    status === "INITIALIZED" &&
    subscriptionId
  );
};

const handleSmartlinkCharge = async (
  smartlink: SmartLink,
  dynamicDetails: object,
  siftDetails: object,
  checkoutToken: ICheckoutToken,
  amount: IAmount,
  history: H.History,
  setTokenRequestLoading: (value: boolean) => void,
  dispatch: Dispatch<any>,
  cardBrandContext?: string
) => {
  const calculateOpenAmount = (
    amount: IAmount,
    smartlink: SmartLink
  ): Amount | undefined => {
    const payment_config_type = get(smartlink, "paymentConfigType", undefined);

    if (payment_config_type !== PaymentConfigTypeEnum.OPEN) return undefined;

    return {
      currency: amount.currency,
      iva: amount.taxes,
      subtotalIva: amount.openSubtotalIva,
      subtotalIva0: amount.openSubtotalIva0,
    };
  };

  const buildChargeRequest = (): ChargesRequest => {
    const { notifyTransactionStatus, siftScienceEnabled } = smartlink;
    const { paymentMethod = "", token = "" } = checkoutToken;

    const chargeRequest: ChargesRequest = {
      activationMethod: ACTIVATION_METHODS[
        paymentMethod as PaymentMethodsEnum
      ] as ActivationMethodEnum,
      amount: calculateOpenAmount(amount, smartlink),
      metadata: defaultTo(dynamicDetails, {}),
      token: token,
    };

    if (siftScienceEnabled)
      chargeRequest.siftScienceDetails = { ...siftDetails };
    mapDeferredConfig(chargeRequest, checkoutToken);
    mapContactDetails(chargeRequest, checkoutToken);
    mapPaymentMethod(chargeRequest, checkoutToken);
    mapNotifyStatus(!!notifyTransactionStatus, chargeRequest, checkoutToken);
    mapMonths(chargeRequest, checkoutToken);

    return chargeRequest;
  };

  const redirectToResume = (chargeResponse: ChargeResponse): void => {
    history.push({
      pathname: RouteEnum.TRANSACTION_RESUME,
      state: {
        paymentMethod: checkoutToken!.paymentMethod,
        resumeData: {
          currency: get(amount, "currency"),
          documentType: get(chargeResponse, "documentType", ""),
          email: get(checkoutToken, "contactDetails.email", ""),
          firstName: get(checkoutToken, "contactDetails.firstName"),
          identification: get(chargeResponse, "documentNumber", ""),
          kpay: get(checkoutToken, "kpayEnable", false),
          lastName: get(checkoutToken, "contactDetails.lastName"),
          name: get(checkoutToken, "additionalConfig.name", ""),
          product: get(smartlink, "generalConfig.productName"),
          totalAmount: get(amount, "total"),
          ...chargeResponse,
        },
        smartlink: { ...smartlink },
      },
    });
  };

  const requestCharge = async ({ id }: SmartLink, request: ChargesRequest) => {
    try {
      const url: string = `${environment.kushkiUrl}/smartlink/charges/${id}`;
      let { data: chargeResponse } = await axios.post<ChargeResponse>(
        url,
        request
      );

      const paymentMethod: string = get(checkoutToken, "paymentMethod", "");
      const cardType: string = get(checkoutToken, "cardType", "");
      const isDebitCard: boolean =
        paymentMethod === PaymentMethodsEnum.CARD_DYNAMIC &&
        cardType === CardType.DEBIT;
      const isAsyncTransaction: boolean = !![
        PaymentMethodsEnum.TRANSFER,
        PaymentMethodsEnum.CARD_ASYNC,
        PaymentMethodsEnum.TRANSFER_SUBSCRIPTION,
        PaymentMethodsEnum.CARD_SUBSCRIPTION_ASYNC,
        PaymentMethodsEnum.CARD_BANK_ACCENDO,
      ].find((value) => value === paymentMethod);

      chargeResponse = {
        ...chargeResponse,
        documentNumber: get(request, "siftScienceDetails.documentNumber", ""),
        documentType: get(request, "siftScienceDetails.documentType", ""),
      };

      if (isAsyncTransaction || isDebitCard) {
        if (chargeResponse.redirectUrl) {
          window.location.href = chargeResponse.redirectUrl;
        }

        if (
          isAccendoResponse(chargeResponse) ||
          isStpResponse(chargeResponse) ||
          isConfrontaResponse(chargeResponse, paymentMethod)
        )
          redirectToResume(chargeResponse);
      } else if (
        chargeResponse.ticketNumber ||
        chargeResponse.startDate ||
        chargeResponse.activationMethod ===
          ACTIVATION_METHODS[PaymentMethodsEnum.TRANSFER_SUBSCRIPTION]
      ) {
        setTokenRequestLoading(false);

        if (cardBrandContext === CardBrandsEnum.VISA) {
          dispatch(initBrandVisaAnimation(chargeResponse, redirectToResume));
        } else if (cardBrandContext === CardBrandsEnum.MASTERCARD) {
          dispatch(
            initBrandMasterCardAnimation(chargeResponse, redirectToResume)
          );
        } else {
          redirectToResume(chargeResponse);
        }
      }
    } catch (e) {
      history.push(RouteEnum.TRANSACTION_FAILED);
    }
  };

  const body = buildChargeRequest();

  return await requestCharge(smartlink, body);
};

export { handleSmartlinkCharge };
