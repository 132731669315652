import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomForm } from "../../../../../types/smart_link_V2";

const useStyles = makeStyles({
  label: {
    color: "#000000",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "120%",
  },
});

const DynamicRadioGroup: React.FC<CustomForm> = (props: CustomForm) => {
  const { label, required, disabled, options, name } = props;
  const { control } = useFormContext();
  const classes = useStyles();
  const defaultValue = required ? options![0] : undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue}
      render={(renderProps) => (
        <FormControl>
          <FormLabel className={classes.label}>{label}</FormLabel>
          <RadioGroup row defaultValue={defaultValue}>
            {options!.map((option: string, index: number) => (
              <FormControlLabel
                {...renderProps}
                disabled={disabled}
                key={index}
                value={option}
                control={<Radio color={"primary"} />}
                label={option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export { DynamicRadioGroup };
