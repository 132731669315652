import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Translate as T } from "react-localize-redux";
import { CustomForm } from "../../../../../types/smart_link_V2";
import { DynamicFormBody } from "../DynamicForm";

const isCheckListEmpty = (
  required: boolean,
  name: string,
  options: string[],
  target: { [key: string]: any } = {}
): boolean => {
  return Object.values(target).indexOf(true) < 0;
};

const useStyles = makeStyles({
  label: {
    color: "#000000",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "120%",
  },
});

const CheckboxList: React.FC<CustomForm> = (props: CustomForm) => {
  const classes = useStyles();
  const { name, label, disabled, required, options } = props;
  const { register, formState } = useFormContext();
  const currentValues = useWatch({ name }) as DynamicFormBody;
  const error: boolean =
    formState.submitCount > 0 &&
    required &&
    isCheckListEmpty(required, name, options!, currentValues);

  return (
    <FormControl error={error}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <FormGroup row>
        {options!.map((option: string, index: number) => (
          <FormControlLabel
            key={index}
            label={option}
            control={
              <Checkbox
                name={`${name}.${option}`}
                inputRef={register()}
                color={"primary"}
                disabled={disabled}
              />
            }
          />
        ))}
      </FormGroup>
      {error && (
        <FormHelperText>
          <T id={"form.fieldRequired"} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { CheckboxList, isCheckListEmpty };
