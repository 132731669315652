import React from "react";
import { Translate as T } from "react-localize-redux";
import { Box, Button, Theme, Typography } from "@material-ui/core";
import transactionFailedLogo from "../../../assets/transactionVerificationFailed/transactionVerificationFailed.svg";
import { IErrorProps } from "../GenericError/GenericError";
import { makeStyles } from "@material-ui/core/styles";
import { useStateApp } from "../../../state/useStateApp";

const useStyle = makeStyles((theme: Theme) => ({
  buttonStyle: {
    border: "0.5px solid #023365",
    borderRadius: "4px",
    height: "36px",
    marginTop: theme.spacing(2),
  },
}));

const TransactionError: React.FC<IErrorProps> = (
  props: React.PropsWithChildren<IErrorProps>
) => {
  const styles = useStyle();
  const { isMobile } = useStateApp();

  return (
    <>
      <Box display="flex" justifyContent="center" pb={1} pt={isMobile ? 4 : 2}>
        <img src={transactionFailedLogo} alt="TransactionError Failed Logo" />
      </Box>
      <Box pb={1} pt={2}>
        <Typography variant={"h2"} align={"center"}>
          <T id={props.labels.title} />
        </Typography>
      </Box>
      <Box pt={2} pb={3}>
        <Typography variant={"h6"} align={"center"}>
          <T id={props.labels.description} />
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" pb={isMobile ? 3 : 2} pt={3}>
        <Button onClick={props.handler} className={styles.buttonStyle}>
          <Typography variant={"subtitle1"} align={"center"}>
            <T id={"btnLabels.retry"} />
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default TransactionError;
