import { defaultTo, get } from "lodash";
import React from "react";
import { StatusLabels } from "../../../shared/infrastructure/StatusLabels";
import { useStateApp } from "../../../state/useStateApp";
import { Translate as T } from "react-localize-redux";
import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ILocationState } from "../../../containers/Payment/Payment";
import { useLocation } from "react-router-dom";
import logoKushki from "../../../assets/logos/kushki_logo.svg";
import ResumeKpay from "../../commons/ResumeKpay/ResumeKpay";
import { ResumeProps } from "../../../containers/Resume/Resume";

const useStyles = makeStyles((theme) => ({
  boxStyle: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  checkSubText: {
    fontSize: "16px",
  },
  checkText: {
    fontSize: "32px",
  },
  contentCard: {
    marginBottom: "9px",
    marginTop: "5px",
    paddingTop: "10px",
  },
  detailCard: {
    borderRadius: "10px",
    boxShadow:
      "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)",
    margin: "auto",
    marginLeft: "16px",
    marginRight: "16px",
    padding: "0px 16px",
  },
  hr: {
    opacity: 0.3,
  },
  kpay: {
    margin: "0px auto",
    marginTop: "25px",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
    paddingTop: "30px",
  },
  mobileBoxStyle: {
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: "space-between",
  },
  productImage: {
    borderRadius: "10px",
    height: "80px",
    maxHeight: "80px",
    maxWidth: "80px",
    objectFit: "cover",
    width: "100%",
  },
  productNameText: {
    textTransform: "uppercase",
  },
  scrollDownSm: {
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  successSubTitleStyle: {
    color: "initial",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%",
    paddingTop: "12px",
    textAlign: "center",
  },
  summarizeDescription: {
    color: "#5c5c5c",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "initial",
    lineHeight: "136%",
    margin: "2px 0px",
    textAlign: "left",
  },
  summarizeInfo: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
    margin: "2px 0px",
    textAlign: "left",
  },
  summarizePersonalInfo: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%",
    margin: "4px 0px",
    textAlign: "left",
    width: "100%",
  },
  summarizeSubTitleStyle: {
    color: "#5c5c5c",
    lineHeight: "120%",
    marginBottom: "20px",
    marginTop: "15px",
    textAlign: "left",
  },
  summarizeTitleStyle: {
    color: "#5c5c5c",
    lineHeight: "120%",
    marginBottom: "20px",
    marginTop: "26px",
    textAlign: "left",
  },
  titleStyle: {
    color: "initial",
    textAlign: "center",
  },
}));

const ResumeSubscription: (props: ResumeProps) => JSX.Element = (
  props: ResumeProps
) => {
  const classes = useStyles();
  const location = useLocation<ILocationState>();
  const { resumeData, smartlink: smartlinkData } = location.state;

  const status: string = defaultTo(
    StatusLabels[get(resumeData, "status", "")],
    "transactionStatus.approval"
  );
  //Manual format for browsers different to Chrome
  const startDateResume = get(resumeData, "startDate", "").split("-");
  const startDate: string =
    startDateResume.length === 3
      ? `${startDateResume[2]} / ${startDateResume[1]} / ${startDateResume[0]}`
      : "";
  const endDateResume = get(resumeData, "endDate", "").split("-");
  const endDate: string =
    endDateResume.length === 3
      ? `${endDateResume[2]} / ${endDateResume[1]} / ${endDateResume[0]}`
      : "";
  const numberOfFees = get(
    smartlinkData,
    "paymentConfig.mixedOptions.numberOfFees",
    "-"
  );
  const numFees = numberOfFees === 0 ? "-" : numberOfFees;
  const { isMobile } = useStateApp();
  const isKpay = location.state.resumeData.kpay;

  const personalData: string[] =
    props.dynamicFormValues.length > 0
      ? props.dynamicFormValues
      : [`${resumeData.email}`];

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={7} lg={7}>
        <Card className={classes.detailCard}>
          <CardContent className={classes.contentCard}>
            <Grid container xs={12} md={12} lg={12}>
              <Grid item className={classes.logo}>
                <img
                  src={logoKushki}
                  className={classes.productImage}
                  alt={smartlinkData?.generalConfig.productName}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Box mt={2}>
                  <Typography className={classes.titleStyle} variant={"h2"}>
                    <T id="summarizeLabelsForSubscription.summarizeTitle" />
                  </Typography>
                  <Typography className={classes.successSubTitleStyle}>
                    <T id="summarizeLabelsForSubscription.successSubTitle" />
                  </Typography>
                </Box>
              </Grid>
              {isKpay && (
                <Grid item xs={12} md={9} lg={9} className={classes.kpay}>
                  <ResumeKpay />
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={12}>
                <Box>
                  <Typography
                    variant={"h6"}
                    className={classes.summarizeTitleStyle}
                  >
                    <T id="summarizeLabelsForSubscription.summarizeTitle" />
                  </Typography>
                </Box>
                <Box m={isMobile ? -2 : ""}>
                  <Box className={classes.boxStyle}>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabelsForSubscription.summarizeProductLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          {smartlinkData.generalConfig.productName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabelsForSubscription.subscriptionIDLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          {resumeData.subscriptionId}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.boxStyle}>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabelsForSubscription.summarizeAmountLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          {resumeData.totalAmount + " " + resumeData.currency}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabelsForSubscription.summarizeStateLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          <T id={status} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.boxStyle}>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabelsForSubscription.numberOfFeesLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          {numFees}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabelsForSubscription.periodicityLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          <T
                            id={`subscriptionPeriodicity.${smartlinkData.paymentConfig?.subscriptionOptions?.periodicity}`}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.boxStyle}>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabelsForSubscription.startDateLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          {startDate}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabelsForSubscription.endDateLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          {endDate !== "" ? (
                            endDate
                          ) : (
                            <T id="summarizeLabelsForSubscription.summarizeUndefinedLabel" />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <hr className={classes.hr} />
                <Box>
                  <Typography
                    variant={"h6"}
                    className={classes.summarizeSubTitleStyle}
                  >
                    <T id="summarizeLabelsForSubscription.summarizePersonalInfoLabel" />
                  </Typography>
                </Box>
                <Box m={isMobile ? -2 : ""}>
                  <Box
                    className={
                      isMobile ? classes.mobileBoxStyle : classes.boxStyle
                    }
                  >
                    {personalData.length > 0 && (
                      <Grid container xs={12} md={5} lg={5}>
                        <Grid item xs={4} md={12} lg={12}>
                          <Typography className={classes.summarizeDescription}>
                            <T id="summarizeLabelsForSubscription.summarizeEmail" />
                          </Typography>
                          {personalData.map((info: string) => (
                            <Typography
                              className={classes.summarizePersonalInfo}
                              key={info}
                            >
                              {info}
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    <Grid container xs={12} md={5} lg={5}>
                      <Grid item xs={4} md={12} lg={12}>
                        <Typography className={classes.summarizeDescription}>
                          <T id="summarizeLabelsForSubscription.payMethodLabel" />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        md={12}
                        lg={12}
                        className={classes.scrollDownSm}
                      >
                        <Typography className={classes.summarizeInfo}>
                          <T id="summarizeLabelsForSubscription.paymentSubscription" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResumeSubscription;
